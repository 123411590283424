import React from "react";
import { Navigate } from "react-router-dom";
   
//login
import Login from "../pages/Authentication/Login";
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
 
import PasswordCategory from "../pages/Passwords/PasswordCategory";
import Passwords from "../pages/Passwords/Passwords";
import Dashboard from "../pages/Dashboard";

import Settings from "../pages/Settings/Settings";
import MailSettings from "../pages/Settings/MailSettings";
import FireBaseSettings from "../pages/Settings/FireBaseSettings";

import SMSSettings from "../pages/Settings/SMSSettings";
import Documents from "../pages/Documents/Documents";
import DocumentCategory from "../pages/Documents/Category";

import GoogleAuthenticator from "../pages/Settings/GoogleAuthenticator";

import User from "../pages/User/User";
 
const authProtectedRoutes = [
 
  { path: "/passwords", component: <Passwords /> },
  { path: "/password-categories", component: <PasswordCategory /> },
  { path: "/documents", component: <Documents /> },
  { path: "/document-categories", component: <DocumentCategory /> },
  { path: "/settings", component: <Settings /> },
 
  { path: "/mail-settings", component: <MailSettings />  },
  { path: "/fireBase-settings", component: <FireBaseSettings />  },
  { path: "/sms-settings", component: <SMSSettings />  },
  { path: "/google-authenticator", component: <GoogleAuthenticator /> },
  { path: "/dashboard", component: <Dashboard/> },    
  { path: "/users", component: <User/> },      
  { path: "/",  exact: true,  component: <Navigate to="/passwords" />},
  { path: "*", component: <Navigate to="/passwords" /> },
];

 
const publicRoutes = [   
  { path: "/logout", component: <Logout /> },
  { path: "/login", component:    <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> }, 
];

export { authProtectedRoutes, publicRoutes };