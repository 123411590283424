import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import {
    Button, Row, Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle,
    Form, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import { ToastMessage } from "../../Components/Hooks/Function";

const ProfileDropdown = () => {
    const [selectedProfile, setSelectedProfile] = useState("");
    const [modalChange, setModalChange] = useState(false);
    const [modalProfile, setModalProfile] = useState(false);
    const [submit, setSubmit] = useState(false); 
    const [userDetails, setUserDetails] = useState(getLoggedinUser()?.user);

    const history = useNavigate();

    const getSettings = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('settingsDetails', {});

            if (response.data.status === true) {
                setSelectedProfile(response.data?.data?.filter(type => type?.name === "profile")[0]?.value);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const changeLogo = () => {
            getSettings();
        };

        setSelectedProfile(getSettingsDetails("settings")?.filter(type => type?.name === "profile")[0]?.value);
        window.addEventListener("changeLogo", changeLogo);
        return () => {
            window.removeEventListener("changeLogo", changeLogo);
        };
    }, []);


    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const [passwordShow, setPasswordShow] = useState(false);

    const confirmPassword = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'application/json';

        try {
            const response = await api.post('changePassword', {
                email: userDetails?.email,
                password: values.password,
            });

            if (response.data.status === true) {
                validation.resetForm();
                setModalChange(false);
                ToastMessage("success", response.data?.message);
                localStorage.removeItem("token");
                localStorage.removeItem("authUser");
                history('/logout');

            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }
    };

    const editUsers = async (value, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('user/' + id, value);

            if (response.data.status === true) {

                verifyUser(localStorage.getItem("token"));
                setModalProfile(false);

                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const verifyUser = async (token) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Content-Type'] = 'application/json'
        const response = await api.get('userDetails', {});
        if (response.data.status === true) {
            localStorage.setItem("authUser", JSON.stringify({
                email: response.data?.data.email,
                user: response.data?.data,
            }));

            setUserDetails(response.data?.data);
        } else {
            ToastMessage("error", response.data?.message);
        }
    }



    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            password: '',
            confirm: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password"),
            confirm: Yup.string()
                .required("Please Enter Your Confirm Password")
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: (values) => {
            confirmPassword(values);
        },
    });

    const profileValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            email: '',
            mobile: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            name: Yup.string().required("Please Enter Your Username"),
            mobile: Yup.string().notRequired(),
        }),
        onSubmit: (values) => {
            editUsers(values, userDetails?.id);
        },
    });





    return (
        <React.Fragment>
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn">
                    <span className="d-flex align-items-center">

                        <img className="rounded-circle header-profile-user" src={userDetails?.employee?.profile ? url + userDetails?.employee?.profile : url + selectedProfile}
                            alt="Header Avatar" />

                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userDetails?.name}</span>
                            <span className="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text">{userDetails?.roles?.[0]?.name}</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">

                    <DropdownItem className='p-0'>
                        <Link to={"#"} className="dropdown-item" onClick={() => {
                            profileValidation.setValues(userDetails);
                            setSubmit(false); setModalProfile(!modalProfile); setPasswordShow(false);
                        }}>
                            <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-2"></i>
                            <span className="align-middle">Profile</span>
                        </Link>
                    </DropdownItem>


                    <DropdownItem className='p-0'>
                        <Link to={"#"} className="dropdown-item" onClick={() => {
                            setModalChange(!modalChange); setPasswordShow(false); validation.resetForm();
                        }}>
                            <i className="mdi mdi-account-key text-muted fs-16 align-middle me-1"></i> <span
                                className="align-middle" data-key="t-change">Change Password</span>
                        </Link>
                    </DropdownItem>


                    <DropdownItem className='p-0'>
                        <Link to={process.env.PUBLIC_URL + "/logout"} className="dropdown-item">
                            <i className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i> <span className="align-middle" data-key="t-logout">Logout</span>
                        </Link>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>


            <div className="modal fade" tabIndex="-1" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered"    >
                    <Modal size="md" backdrop={"static"} isOpen={modalChange} toggle={() => { setModalChange(!modalChange) }} centered>
                        <ModalHeader className='bg-info-subtle p-3 pb-2' toggle={() => { setModalChange(!modalChange) }} > {"Change Password"}  </ModalHeader>
                        <Form onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }} action="#">
                            <ModalBody>
                                <Row>
                                    <Col xs={12} >
                                        <div className="mb-3">
                                            <Label htmlFor="password" className="form-label">Password
                                                <span className='secondary'>*</span> </Label>
                                            <Input type={passwordShow ? "text" : "password"} className="form-control px-2 py-1" id="password" placeholder="Enter password" name='password'
                                                validate={{ required: { value: true } }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.password || ""}
                                                invalid={validation.touched.password && validation.errors.password ? true : false}
                                            />

                                            <button
                                                className="btn btn-link position-absolute top-50 end-0 pt-1 px-4 mt-2 translate-middle-y text-decoration-none text-muted"
                                                onClick={() => setPasswordShow(!passwordShow)} type="button"  >
                                                <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                            </button>
                                        </div>
                                    </Col>

                                    <Col xs={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="confirm" className="form-label">Confirm Password <span className='secondary'>*</span> </Label>
                                            <Input type={passwordShow ? "text" : "password"} className="form-control px-2 py-1" id="confirm" placeholder="Enter confirm password" name='confirm'
                                                validate={{ required: { value: true } }}
                                                onChange={validation.handleChange}
                                                onBlur={validation.handleBlur}
                                                value={validation.values.confirm || ""}
                                                invalid={validation.touched.confirm && validation.errors.confirm ? true : false}
                                            />

                                            <button
                                                className="btn btn-link position-absolute top-50 end-0 pt-1 px-4 mt-2 translate-middle-y text-decoration-none text-muted"
                                                onClick={() => setPasswordShow(!passwordShow)}
                                                type="button" >
                                                <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody >

                            <ModalFooter>
                                <div className="hstack gap-2 justify-content-end">
                                    <Button color="light" onClick={() => { setModalChange(false); }} > Cancel </Button>
                                    <Button type="submit" color="success" > Save  </Button>
                                </div>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </div>
            </div>



            <div className="modal fade" tabIndex="-1" aria-hidden="true" >
                <div className="modal-dialog modal-dialog-centered"    >
                    <Modal size="md" backdrop={"static"} isOpen={modalProfile} toggle={() => { setModalProfile(!modalProfile) }} centered>
                        <ModalHeader className='bg-info-subtle p-3 pb-2' toggle={() => { setModalProfile(!modalProfile) }} > {"Edit Profile"}  </ModalHeader>
                        <Form onSubmit={(e) => { e.preventDefault(); profileValidation.handleSubmit(); return false; }} action="#">
                            <ModalBody>
                                <Row>
                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="teammembersName" className="form-label">Name <span className='secondary'>*</span></Label>
                                            <Input type="text" className="form-control px-2 py-1" id="teammembersName" placeholder="Enter name"
                                                name='name'
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onChange={profileValidation.handleChange}
                                                onBlur={profileValidation.handleBlur}
                                                value={profileValidation.values.name || ""}
                                                invalid={
                                                    submit && profileValidation.errors.name ? true : false
                                                }
                                            />

                                        </div>
                                    </Col>

                                    <Col lg={12}>
                                        <div className="mb-3">
                                            <Label htmlFor="mobile" className="form-label">Mobile</Label>
                                            <Input type="number" className="form-control px-2 py-1" id="mobile" placeholder="Enter mobile" name='mobile'
                                                validate={{
                                                    required: { value: true },
                                                }}
                                                onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                                onChange={(e) => {
                                                    let inputValue = e.target.value;
                                                    if (inputValue.length > 10) {
                                                        inputValue = inputValue.slice(0, 10);
                                                    }

                                                    profileValidation.handleChange({
                                                        target: {
                                                            name: 'mobile',
                                                            value: inputValue,
                                                        },
                                                    });

                                                }
                                                }
                                                onBlur={profileValidation.handleBlur}
                                                value={profileValidation.values.mobile || ""}
                                                invalid={
                                                    submit && profileValidation.errors.mobile ? true : false
                                                }
                                            />

                                        </div>
                                    </Col>
                                </Row>
                            </ModalBody >

                            <ModalFooter>
                                <div className="hstack gap-2 justify-content-end">
                                    <Button color="light" onClick={() => { setModalChange(false); }} > Cancel </Button>
                                    <Button type="submit" color="success" onClick={() => { setSubmit(true); }} > Save  </Button>
                                </div>
                            </ModalFooter>
                        </Form>
                    </Modal>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ProfileDropdown;