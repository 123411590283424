import React, { useState, useEffect } from 'react';
import { Button, Modal, ModalBody, ModalHeader, ModalFooter, Col, Dropdown, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { Link } from 'react-router-dom';
import { api, url } from "../../helpers/api";

//import images
// import avatar2 from "../../assets/images/users/avatar-2.jpg";
// import avatar8 from "../../assets/images/users/avatar-8.jpg";
// import avatar3 from "../../assets/images/users/avatar-3.jpg";
// import avatar6 from "../../assets/images/users/avatar-6.jpg";
// import bell from "../../assets/images/svg/bell.svg";

//SimpleBar
import SimpleBar from "simplebar-react";

const NotificationDropdown = (props) => {

    var moment = require('moment-timezone');

    const [notificationList, setNotificationList] = useState([]);
    const [notificationMessage, setNotificationMessage] = useState(false);

    const [isNotificationDropdown, setIsNotificationDropdown] = useState(false);
    const toggleNotificationDropdown = () => {
        setIsNotificationDropdown(!isNotificationDropdown);
    };


    const getNotification = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('showNotification', {});

            if (response.data.status === true) {
                setNotificationList(response.data?.data);

                if (response.data?.data?.length > 0) {
                    setNotificationMessage(true);
                } else {
                    setNotificationMessage(false);
                }

            } else {
                setNotificationList([]);
            }
        } catch (err) {
            console.error(err.message);
        }
    };


    const clearNotification = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('clearNotification/' + id, {});
            if (response.data.status === true) {
                setNotificationMessage(false);
                getNotification();
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getNotification();
    }, []);

    const [activeTab, setActiveTab] = useState('1');
    const toggleTab = (tab) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };
    return (
        <React.Fragment>
            <Dropdown isOpen={isNotificationDropdown} toggle={toggleNotificationDropdown} className="topbar-head-dropdown ms-1 header-item">
                <DropdownToggle type="button" tag="button" className="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle">
                    <i className='bx bx-bell fs-22'></i>
                    <span
                        className="position-absolute topbar-badge translate-middle badge rounded-pill bg-danger">{notificationList?.length}<span
                            className="visually-hidden">unread messages</span></span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
                    <div className="dropdown-head bg-primary bg-pattern rounded-top">
                        <div className="p-3">
                            <Row className="align-items-center">
                                <Col>
                                    <h6 className="m-0 fs-16 fw-semibold text-white"> Notifications </h6>
                                </Col>
                                <div className="col-auto dropdown-tabs">
                                    <span className="badge bg-light-subtle text-body fs-13"> {notificationList?.length} New</span>
                                </div>
                            </Row>
                        </div>

                    </div>

                    <SimpleBar style={{ maxHeight: "300px" }}  >
                        {notificationList?.map((item, index) => {
                            const createdAtMoment = moment(item?.created_at).tz(moment.tz.guess());

                            // Calculate the time difference
                            const timeDifference = moment().diff(createdAtMoment, 'seconds');

                            // Generate the human-readable format
                            const formattedTimeDifference = moment.duration(timeDifference, 'seconds').humanize();



                            return (

                                <div key={index} className="text-reset notification-item d-block dropdown-item position-relative">
                                    <div className="d-flex">
                                        <div className="avatar-xs me-3 flex-shrink-0">
                                            <span className="avatar-title bg-info-subtle text-info rounded-circle fs-16">
                                                <i className="bx bx-badge-check"></i>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1">
                                            <Link to="#" className="stretched-link">
                                                <h6 className="mt-0 mb-2 lh-base">{item?.message}</h6>
                                            </Link>
                                            <p className="mb-0 fs-11 fw-medium text-uppercase text-muted">
                                                <span><i className="mdi mdi-clock-outline"></i> Just {formattedTimeDifference} ago </span>
                                            </p>
                                        </div>
                                        {/* <div className="px-2 fs-15">
        <div className="form-check notification-check">
            <input className="form-check-input" type="checkbox" value="" id="all-notification-check01" />
            <label className="form-check-label" htmlFor="all-notification-check01"></label>
        </div>
    </div> */}
                                    </div>
                                </div>

                            )
                        })
                        }




                    </SimpleBar>
                </DropdownMenu>
            </Dropdown>


            <div className="modal fade" tabIndex="-1" aria-hidden="true">
                <div className="modal-dialog modal-dialog">
                    <Modal size="lg" isOpen={notificationMessage} toggle={() => { setNotificationMessage(false); }} centered>
                        <ModalHeader className='bg-info-subtle p-3 pb-2' toggle={() => { setNotificationMessage(false); }}  >   {"Notification"}  </ModalHeader>
                        <ModalBody > <p> {notificationList?.[0]?.message} </p>  </ModalBody>
                        <ModalFooter>
                            <div className="hstack gap-2 justify-content-end">
                                 
                                <Button color="success" onClick={() => { clearNotification(notificationList?.[0]?.id); }} > Accept </Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                </div>
            </div>

        </React.Fragment>
    );
};

export default NotificationDropdown;