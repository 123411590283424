import React from 'react';
import CountUp from "react-countup";
import { Link } from 'react-router-dom';
import { Card, CardBody, Col } from 'reactstrap';
 
const Widgets = () => {
    return (
        <React.Fragment>
            
        </React.Fragment>
    );
};

export default Widgets;