import { useEffect, useState } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { getLoggedinUser } from "../../helpers/api_helper";

var moment = require('moment-timezone');

const useProfile = () => {
  const userProfileSession = getLoggedinUser();
  var token =
  userProfileSession &&
  userProfileSession["token"];
  const [loading, setLoading] = useState(userProfileSession ? false : true);
  const [userProfile, setUserProfile] = useState(
    userProfileSession ? userProfileSession : null
  );

 

  useEffect(() => {
    const userProfileSession = getLoggedinUser();
    var token =
      userProfileSession &&
      userProfileSession["token"];
    setUserProfile(userProfileSession ? userProfileSession : null);
    setLoading(token ? false : true);
  }, []);


  return { userProfile, loading,token };
};

const ToastMessage = (type, message) => {

  return toast[type](message, {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });
};


function snakeToCamel(str) {
  if (str) {
      const camelCase = str.replace(/_([a-z])/g, function (match, letter) {
          return letter.toUpperCase();
      });
      const pascalWithSpaces = camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
      return pascalWithSpaces.replace(/([A-Z])/g, ' $1').trim();
  }
}

const SmallDateFormat = (selectedCountry) => {
  return selectedCountry === 'US' ? 'm-d-Y' : 'd-m-Y'
};

const DefaultDate = (date, selectedCountry) => {
  let formattedDate;

  if (date === "" || date === null) {
    formattedDate = moment().tz(moment.tz.guess()).format(selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY');
  } else {    
    formattedDate = moment(date).tz(moment.tz.guess()).format(selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY');
  } 

  if (formattedDate !== "Invalid date") {
    return formattedDate;
  } else {
    return "";
  }
};


const FormatDate = (date, selectedCountry) => {

  const formattedDate = (date?.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/) || date?.match(/^\d{4}-\d{2}-\d{2}$/)) ?
    moment(date).tz(moment.tz.guess()).format('YYYY-MM-DD') :
    moment(date, selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY').tz(moment.tz.guess()).format('YYYY-MM-DD');

  if (formattedDate !== "undefined") {
    return formattedDate;
  } else {
    return "";
  }

};

export { useProfile, ToastMessage, snakeToCamel, SmallDateFormat, DefaultDate, FormatDate };