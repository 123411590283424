import React, { useState, useEffect, useCallback } from 'react';
import {
    Card, CardBody, Spinner, Col, Form, Input, Label, Row,
} from 'reactstrap';



const FormDocumentCategory = ({ validation, submit, setSubmit,   }) => {

    return (
        <Form onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}>
            <Row>
                <Col lg={12}>
                    <div className="mb-3">
                        <Label htmlFor="name" className="form-label">Name  <span className='secondary'>*</span></Label>
                        <Input type="text" className="form-control px-2 py-1" id="name" name='name'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.name || ""}
                            invalid={
                                submit && validation.errors.name ? true : false
                            }
                        />
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="mb-2">
                        <Label htmlFor="description" className="form-label">Description</Label>
                        <Input type="textarea" className="form-control px-2 py-1" id="description" placeholder="Enter description"
                            name='description'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.description || ""}
                            invalid={submit && validation.errors.description ? true : false}
                            maxLength={250}
                            style={{ height: "100px", minHeight: "100px", }}
                            multiline="true"
                        />
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                        <button type="submit" className="btn btn-primary" onClick={() => { setSubmit(true); }}>
                            {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Save"}</button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default FormDocumentCategory;

