import React, { useState, useEffect, useCallback } from 'react';
import { Button, Spinner, Col, Form, Input, Label, Row, InputGroup, InputGroupText } from 'reactstrap';
import { api, url } from "../../helpers/api";
import Select from "react-select";
import { GoPlusCircle } from "react-icons/go";
import { CiCircleMinus } from "react-icons/ci";
import Flatpickr from "react-flatpickr";
import { Link, useNavigate } from 'react-router-dom';
import { ToastMessage, DefaultDate, SmallDateFormat, FormatDate, snakeToCamel } from "../../Components/Hooks/Function";
import { IoCloseOutline } from "react-icons/io5";


const FormPassword = ({ validation, categoryValidation, showPasswordValidation, codeValidation, submit, setSubmit, isEdit, categoryList, qrCodeData, setQRCodeData, selectedCountry, categoryCustomFields, customFields, setCustomFields,
    handleRemoveCustomFields, setCategoryCustomFields, setView, password, passwordShow, passwordVerified, setPasswordShow, setPasswordVerified, otpValidation, setCodeVerified,
    setOTPVerfied, OTPVerfied, codeVerified, setOpen,  
}) => {

    var moment = require('moment-timezone');

    const remainderOptions = [
        { value: 0, label: "Same Day" },
        { value: 5, label: "5 Days" },
        { value: 15, label: "15 Days" },
        { value: 20, label: "20 Days" },
    ];

    const selectStyle = (fieldName) => {
        return { 
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px', 
                minHeight: '30px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da',
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: '0px',
                boxShadow: "none,"
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            groupHeading: (provided) => ({
                fontWeight: 'bold',
                color: '#019aff',
                paddingLeft: '10px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px',                
            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        }
    }

    const passwordOption = (customFields?.filter(item => item.type === "Password").length === 0
        && categoryCustomFields?.filter(item => item.type === "Password").length === 0)
        ? [{ value: "Password", label: "Password" }] : [];

    const handleCustomFields = () => {
        setCustomFields([...customFields, { name: '', type: 'Text', mandatory: false }]);
    };

    const handleValueChange = (index, value) => {
        const newCategoryCustomFields = [...categoryCustomFields];
        newCategoryCustomFields[index].value = value;
        setCategoryCustomFields(newCategoryCustomFields);
    };

    const handleCustomFieldsChange = (index, name, value) => {
        const newCustomFields = [...customFields];
        newCustomFields[index][name] = value;
        setCustomFields(newCustomFields);
    };

    const handleCustomFileChange = (index, name, e) => {
        const fileData = Array.from(e.target.files);
        const totalSize = fileData.reduce((acc, file) => acc + file.size, 0);
        if (totalSize <= 10 * 1024 * 1024) {
            const newCustomFields = [...customFields];
            newCustomFields[index][name] = e.target.files[0];
            setCustomFields(newCustomFields);
        } else {
            setCustomFields(customFields);
        }
    }

    const handleFileChange = (name, e) => {
        const fileData = Array.from(e.target.files);
        const totalSize = fileData.reduce((acc, file) => acc + file.size, 0);
        if (totalSize <= 10 * 1024 * 1024) {
            validation.setValues({
                ...validation?.values,
                [name]: e.target.files[0]
            });
        }
    }

    const viewPasswordCategory = async (id) => {
        if (id) {
            setCategoryCustomFields([]);
            api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
            api.defaults.headers.common['Content-Type'] = 'application/json'
            try {
                const response = await api.get('passwordCategory/' + id, {});
                if (response.data.status === true) {
                    setCategoryCustomFields(response.data.data?.password_category_lists);
                }
                setOpen(true);
            } catch (err) {
                console.error(err.message);
            }
        }
    };

    return (

        <Form onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}>
            <Row>
                {!isEdit &&
                    <Col lg={12}>
                        <div className="mb-3">
                            <Row>
                                <Col lg={4}>
                                    <Label htmlFor="password_category_id" className="form-label">Category  <span className='secondary'>*</span></Label>
                                </Col>
                                <Col xs={10} lg={7}>
                                    <Select
                                        name='password_category_id'
                                        onChange={(e) => {
                                            validation.setValues({
                                                ...validation.values,
                                                password_category_id: e?.value
                                            });
                                            viewPasswordCategory(e?.value);
                                        }}
                                        defaultValue={validation.values.password_category_id ? categoryList?.filter(
                                            item => item?.value === validation.values.password_category_id)?.map((item, index) => {
                                                return (
                                                    { value: item.value, label: item.label }
                                                );
                                            }) : ""}
                                        options={[
                                            // { value: "", label: "Select" },
                                            ...categoryList?.map((item, index) => {
                                                return ({ value: item.id, label: item.category_name });
                                            })]}
                                        styles={selectStyle('password_category_id')}
                                    />
                                </Col>
                                <Col xs={2} lg={1}>
                                    <GoPlusCircle className="cursor primary" size={20} style={{ minWidth: "20px" }} onClick={(e) => {
                                        categoryValidation.resetForm();
                                        setCategoryCustomFields([{ name: 'Email', type: "Text", mandatory: true }, { name: 'Password', type: "Password", mandatory: true }]);
                                        setView("Category");
                                    }} />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                }

                <Col lg={12}>
                    <div className="mb-3">
                        <Row>
                            <Col lg={4}>
                                <Label htmlFor="text" className="form-label">Website Name <span className='secondary'>*</span></Label>
                            </Col>
                            <Col lg={8}>
                                <Input type="text" className="form-control px-2 py-1 rounded-0 border-top-0 border-end-0 border-start-0 border-bottom-1" id="website_name" name='website_name'
                                    placeholder="Enter website name"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    defaultValue={validation.values.website_name || ""}
                                    invalid={
                                        submit && validation.errors.website_name ? true : false
                                    }
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="mb-3">
                        <Row>
                            <Col lg={4}>
                                <Label htmlFor="authentication_type" className="form-label">Authentication Type  <span className='secondary'>*</span> </Label>
                            </Col>
                            <Col lg={8}>
                                <Select  
                                    // isDisabled={  isEdit? true: false }
                                    name='authentication_type'
                                    onChange={(e) => {
                                        validation.setValues({
                                            ...validation.values,
                                            authentication_type: e?.value
                                        });
                                    }}
                                    defaultValue={validation.values.authentication_type ? {
                                        value: validation.values.authentication_type,
                                        label: validation.values.authentication_type + " Authentication"
                                    } : ""}
                                    options={[
                                        { value: "Single-factor", label: "Single-factor Authentication" },
                                        { value: "Two-factor", label: "Two-factor Authentication" },
                                        { value: "Google", label: "Google Authentication" }
                                    ]}
                                    styles={selectStyle('authentication_type')}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>
               
                <div>
                    {/*validation?.values?.authentication_type === "Google" && qrCodeData != "" && !codeVerified &&
                        <Row>
                            <Col lg={4}> <Label htmlFor="totp" className="form-label">Code</Label> </Col>
                            <Col lg={6}>
                                <div className="mb-3">
                                    <Input type="text" className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" id="totp" name='totp'
                                        onChange={codeValidation.handleChange}
                                        onBlur={codeValidation.handleBlur}
                                        value={codeValidation.values.totp || ""}
                                        placeholder="Enter totp"
                                        invalid={codeValidation.touched.totp && codeValidation.errors.totp ? true : false}
                                    />
                                </div>
                            </Col>

                            <Col lg={2}>
                                <div className="hstack gap-2 justify-content-end d-flex gap-2">
                                    <Button color="success" type="submit" disabled={codeValidation.isSubmitting ? true : false}
                                        onClick={(e) => {
                                            e.preventDefault(e);
                                            codeValidation.handleSubmit();
                                            setSubmit(true);
                                        }}
                                        className="btn btn-success d-flex gap-1 py-1 align-items-center"  >
                                        {codeValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                        {"Verify"}</Button>                                      
                                        
                                        <span className="fs-20 cursor text-danger" onClick={(e) => { e.preventDefault(e); setQRCodeData("");}}>   <IoCloseOutline /> </span>
                                </div>
                            </Col>
                                    </Row> */}
                </div>

                <Col lg={12}>
                    <div className="mb-3">
                        <Row>
                            <Col lg={4}>
                                <Label htmlFor="note" className="form-label">Note </Label>
                            </Col>
                            <Col lg={8}>
                                <Input type="textarea" className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" id="note" name='note'
                                    placeholder="Enter note"
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                    defaultValue={validation.values.note || ""}
                                    invalid={submit && validation.errors.note ? true : false}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="mb-3">
                        <Row>
                            <Col lg={4}>
                                <Label htmlFor="expire_at" className="form-label">Expire at  </Label>
                            </Col>
                            <Col lg={8}>
                                <Flatpickr
                                    name='expire_at'
                                    className={`form-control py-1 px-2 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0 ${submit && validation.errors.expire_at ? 'is-invalid' : ''}`}
                                    options={{
                                        allowInput: true,
                                        dateFormat: SmallDateFormat(selectedCountry),
                                    }}
                                    onChange={(selectedDates, dateStr, instance) => {
                                        validation.setValues({
                                            ...validation.values,
                                            expire_at: dateStr
                                        });
                                        const remainder_in = parseInt(validation.values.remainder_in);
                                        if (!isNaN(remainder_in)) {
                                            const expire_at = new Date(FormatDate(dateStr, selectedCountry));
                                            expire_at.setDate(expire_at.getDate() - remainder_in);
                                            validation.setValues({
                                                ...validation.values,
                                                expire_at: dateStr,
                                                remainder_date: moment(expire_at, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format(selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY')
                                            });
                                        }
                                    }}
                                    value={validation.values?.expire_at || ""}
                                />
                            </Col>
                        </Row>
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="mb-3">
                        <Row>
                            <Col lg={4}>
                                <Label htmlFor="note" className="form-label">Remainder  </Label>
                            </Col>
                            <Col lg={8} className='d-flex gap-2 align-items-end'>
                                <div className="form-check form-switch form-switch-left form-switch-md">
                                    <Input
                                        type="switch"
                                        id="remainder"
                                        name="remainder"
                                        defaultValue={validation.values.remainder || ""}
                                        checked={validation.values.remainder}
                                        onChange={(e) => {
                                            try {
                                                validation.setValues({
                                                    ...validation.values,
                                                    remainder_in: 0,
                                                    remainder: e.target.checked,
                                                    remainder_date: validation.values.expire_at
                                                });
                                            } catch (err) {
                                                console.error(err.message);
                                            }
                                        }}
                                        invalid={submit && validation.errors.remainder ? true : false}
                                    />
                                </div>

                                {validation.values.remainder && <>
                                    <Select
                                        name='remainder_in'
                                        onChange={(e) => {
                                            validation.setValues({
                                                ...validation.values,
                                                remainder_in: parseInt(e?.value)
                                            });
                                            if (validation.values.expire_at !== undefined) {
                                                const remainder_in = parseInt(e?.value);
                                                if (!isNaN(remainder_in)) {
                                                    const expire_at = new Date(FormatDate(validation.values.expire_at, selectedCountry));
                                                    expire_at.setDate(expire_at.getDate() - remainder_in);
                                                    validation.setValues({
                                                        ...validation.values,
                                                        remainder_in: parseInt(e?.value),
                                                        remainder_date: moment(expire_at, "ddd MMM DD YYYY HH:mm:ss [GMT]Z").format(selectedCountry === 'US' ? 'MM-DD-YYYY' : 'DD-MM-YYYY')
                                                    });
                                                }
                                            }
                                        }}
                                        defaultValue={validation.values.remainder_in !== "" ?
                                            remainderOptions?.filter(
                                                item => item?.value === parseInt(validation.values.remainder_in))?.map((item, index) => {
                                                    return ({ value: item.value, label: item.label });
                                                })
                                            : { value: 0, label: "Same Day" }}
                                        options={remainderOptions}
                                        styles={selectStyle('remainder_in')}
                                    />
                                    <Flatpickr
                                        name='remainder_date'
                                        className={`form-control py-1 px-2  border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0 ${submit && validation.errors.remainder_date ? 'is-invalid' : ''}`}
                                        readOnly
                                        options={{
                                            allowInput: true,
                                            dateFormat: SmallDateFormat(selectedCountry),
                                        }}
                                        disabled={true}
                                        value={validation.values.remainder_date || ""}
                                        style={{ background: "#8080802b", width: "50%" }}
                                    />
                                </>}
                            </Col>
                        </Row>
                    </div>
                </Col>


                {categoryCustomFields?.map((item, index) => {
                    return (
                        <Col lg={12} key={index}>
                            <div className="mb-3">
                                <Row>
                                    <Col lg={4}>
                                        <Label className="form-label">{snakeToCamel(item.name)}
                                            {item.mandatory ? <span className='secondary'>*</span> : ""} </Label>
                                    </Col>
                                    <Col lg={8}>
                                        {item.type === "Password" ?
                                            <>
                                                {item.type === "Password" &&
                                                    <button
                                                        className="btn btn-link position-absolute top-0 end-0 pb-0 px-3  py-4 translate-middle-y text-decoration-none text-muted"
                                                        onClick={() => { setPasswordShow(!passwordShow); setOTPVerfied(false); setPasswordVerified(false); setCodeVerified(false); setQRCodeData(""); }}
                                                        type="button"  >
                                                        <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                                    </button>}
                                                {!isEdit && <Input type={item.type === "Password" ? passwordShow ? "text" : "password" : item.type} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                    placeholder={`Enter ${snakeToCamel(item.name) || "value"}`} name={item.name}
                                                    defaultValue={validation?.values[item.name] || ""}
                                                    onChange={(e) => {
                                                        validation.handleChange(e);
                                                        handleValueChange(index, e.target.value);
                                                    }}
                                                    invalid={submit && validation.errors[item.name] ? true : false}
                                                />}

                                                {validation?.values?.authentication_type === "Single-factor" && item.type === "Password" && isEdit && !passwordVerified &&
                                                    <Input type={"password"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        readOnly={true}
                                                        defaultValue={("passwordVerified"?.substring(0, 10) + 'x'.repeat(Math.max(item?.name?.length - 5, 0)))?.substring(0, 20)}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {validation?.values?.authentication_type === "Single-factor" && item.type === "Password" && isEdit && passwordVerified &&
                                                    <Input type={"text"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        name={item.name}
                                                        defaultValue={password}
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            handleValueChange(index, e.target.value);
                                                        }}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {validation?.values?.authentication_type === "Two-factor" && item.type === "Password" && isEdit && !OTPVerfied &&
                                                    <Input type={"password"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        readOnly={true}
                                                        defaultValue={("passwordVerified"?.substring(0, 10) + 'x'.repeat(Math.max(item?.name?.length - 5, 0)))?.substring(0, 20)}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {validation?.values?.authentication_type === "Two-factor" && item.type === "Password" && isEdit && passwordVerified && OTPVerfied &&
                                                    <Input type={"text"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`} name={item.name}
                                                        defaultValue={password}
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            handleValueChange(index, e.target.value);
                                                        }}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {validation?.values?.authentication_type === "Two-factor" && item.type === "Password" && isEdit && passwordVerified && !OTPVerfied &&
                                                    <>
                                                        <div className="mb-2 mt-2 d-flex">
                                                            <Input type="text" className="form-control px-2 py-1 rounded-0 border-top-0 border-end-0 border-start-0 border-bottom-1" id="otp" name='otp'
                                                                onChange={otpValidation.handleChange}
                                                                onBlur={otpValidation.handleBlur}
                                                                placeholder="Enter otp"
                                                                invalid={submit && otpValidation.errors.otp ? true : false}
                                                            />

                                                            <div className="hstack gap-2 justify-content-end">
                                                                <button className="btn btn-primary d-flex gap-1 py-1 align-items-center" onClick={(e) => {
                                                                    e.preventDefault(e);
                                                                    otpValidation.handleSubmit();
                                                                    setSubmit(true);
                                                                }}>
                                                                    {otpValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }


                                                {validation?.values?.authentication_type === "Google" && item.type === "Password" && isEdit && !codeVerified &&
                                                    <Input type={"password"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        readOnly={true}
                                                        defaultValue={("passwordVerified"?.substring(0, 10) + 'x'.repeat(Math.max(item?.name?.length - 5, 0)))?.substring(0, 20)}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }


                                                {validation?.values?.authentication_type === "Google" && item.type === "Password" && isEdit && passwordVerified && codeVerified &&
                                                    <Input type={"text"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        name={item.name}
                                                        defaultValue={password}
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            handleValueChange(index, e.target.value);
                                                        }}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }


                                                {item.type === "Password" && isEdit && passwordShow && !passwordVerified &&
                                                    <>
                                                        <div className="mb-2 mt-2 d-flex ">
                                                            <Input type="password" className="form-control px-2 py-1 rounded-0 border-top-0 border-end-0 border-start-0 border-bottom-1"   name='password'
                                                                onChange={showPasswordValidation.handleChange}
                                                                onBlur={showPasswordValidation.handleBlur}
                                                                placeholder="Enter password"
                                                                invalid={submit && showPasswordValidation.errors.password ? true : false}
                                                            />

                                                            <div className="hstack gap-2 justify-content-end">
                                                                <button className="btn btn-primary d-flex gap-1 py-1 align-items-center" onClick={(e) => {
                                                                    e.preventDefault(e);
                                                                    showPasswordValidation.handleSubmit();
                                                                    setSubmit(true);
                                                                }}>
                                                                    {showPasswordValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {validation?.values?.authentication_type === "Google" && item.type === "Password" && qrCodeData == "" && isEdit && passwordVerified && !codeVerified && <Row>
                                                    <Col lg={12}>
                                                        <div className="mb-3 mt-2 d-flex">
                                                            <Input type="number" className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" id="totp" name='totp'
                                                                onChange={(e) => {
                                                                    let inputValue = e.target.value;
                                                                    if (inputValue.length > 6) {
                                                                        inputValue = inputValue.slice(0, 6);
                                                                    }
                                                                    codeValidation.handleChange({
                                                                        target: {
                                                                            name: 'totp',
                                                                            value: inputValue,
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={codeValidation.handleBlur}
                                                                value={codeValidation.values.totp || ""}
                                                                placeholder="Enter totp"
                                                                invalid={codeValidation.touched.code && codeValidation.errors.totp ? true : false}
                                                            />

                                                            <Button color="success" type="submit" disabled={codeValidation.isSubmitting ? true : false}
                                                                onClick={(e) => {
                                                                    e.preventDefault(e);
                                                                    codeValidation.handleSubmit();
                                                                    setSubmit(true);
                                                                }}
                                                                className="btn btn-success d-flex gap-1 py-1 align-items-center"  >
                                                                {codeValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                {"Verify"}
                                                            </Button>                                                                 
                                                        </div> 
                                                    </Col> 
                                                </Row>
                                            }
                                            </>
                                            : item.type === "File" ?
                                                <InputGroup>
                                                    <Input type={isEdit && (validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name || false) ? "Text" : item.type} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`} name={item.name}
                                                        defaultValue={(isEdit && validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name) ? validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name || "" : ""}
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            handleFileChange(item.name, e)
                                                        }}
                                                        readOnly={isEdit && (validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name || false) ? true : false}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />

                                                    {isEdit && (validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name || false) && <Button className='btn-secondary px-2 py-0' onClick={(e) => {
                                                        e.preventDefault();
                                                        const newCategoryCustomFields = [...categoryCustomFields];
                                                        newCategoryCustomFields[index]["show"] = true;
                                                        setCategoryCustomFields(newCategoryCustomFields);
                                                    }}  >
                                                        <IoCloseOutline />
                                                    </Button>}
                                                </InputGroup>
                                                : <Input type={item.type} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                    placeholder={`Enter ${snakeToCamel(item.name) || "value"}`} name={item.name}
                                                    defaultValue={validation?.values[item.name] || ""}
                                                    onChange={(e) => {
                                                        validation.handleChange(e);
                                                        handleValueChange(index, e.target.value);
                                                    }}
                                                    invalid={submit && validation.errors[item.name] ? true : false}
                                                />
                                        }
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    )
                })}

                <Col lg={12} >
                    <div className={` mb-2 d-flex gap-2 align-items-center justify-content-start ${customFields.length > 0 && "form-control px-0 py-0 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"}`}  >
                        {customFields.length > 0 ?
                            <Label className="form-label">Add Custom Fields</Label>
                            :
                            <Link href="#" onClick={handleCustomFields} className='d-flex align-items-center gap-2 rounded-0 border-top-0 border-end-0 border-start-0 border-bottom-1' >
                                <GoPlusCircle className="cursor primary" size={25} style={{ minWidth: "20px" }} />
                                Additional Fields
                            </Link>}
                    </div>
                </Col>

                {customFields?.map((item, index) => {
                    return (
                        <Col lg={12} key={index}>
                            <div className="mb-2">
                                <div className="row d-flex gap-2 align-items-center px-0 py-2">
                                    <div className="col-12 col-md-3 px-2" >
                                        <Input type="text" className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                            placeholder={`Enter name`} name={item.name}
                                            defaultValue={snakeToCamel(item.name) || ""}
                                            onChange={(e) => handleCustomFieldsChange(index, "name", e.target.value)}
                                            invalid={submit && (!item.name) && item.mandatory ? true : false}
                                        />
                                    </div>

                                    <div className="col-12 col-md-3 px-0 mx-2">
                                        <Select name='type'
                                            onChange={(e) => handleCustomFieldsChange(index, "type", e?.value)}
                                            defaultValue={item.type ? { value: item.type, label: item.type } : { value: "Text", label: "Text" }}
                                            options={[
                                                { value: "Text", label: "Text" },
                                                ...passwordOption,
                                                { value: "File", label: "File" }
                                            ]}
                                            styles={selectStyle(item.name)}
                                        />
                                    </div>

                                    <div className="col-12 col-md-4 px-2">

                                        {/* {item.type === "File" ?
                                            <InputGroup>
                                                <Input type={!item.show && isEdit && item?.id ? "Text" : item.type} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                    placeholder={`Enter value`} name={item.name}

                                                    defaultValue={(isEdit && validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name) ? validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name || "" : ""}
                                                    onChange={(e) => {
                                                        validation.handleChange(e);
                                                        handleCustomFileChange(index, "value", e);
                                                    }}
                                                    readOnly={!item.show && isEdit && item?.id ? true : false}
                                                    invalid={submit && validation.errors[item.name] ? true : false}
                                                />
                                                {!item.show && isEdit && item?.id && <Button className='btn-secondary px-2 py-0' onClick={(e) => {
                                                    e.preventDefault();
                                                    const newCustomFields = [...customFields];
                                                    newCustomFields[index]["show"] = true;
                                                    setCustomFields(newCustomFields);

                                                }}  >
                                                    <IoCloseOutline />
                                                </Button>}
                                            </InputGroup>
                                            :
                                            <Input type={item.type === "Password" ? passwordShow ? "text" : "password" : item.type} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                placeholder={`Enter value`} name={item.name}
                                                defaultValue={validation?.values[item.name] || ""}
                                                onChange={(e) => {
                                                    handleCustomFieldsChange(index, "value", e.target.value)
                                                }}
                                                required={item.name ? true : false}
                                                invalid={submit && validation.errors[item.name] ? true : false}
                                            />
                                        } */}



                                        {item.type === "Password" ?
                                            <>
                                                {item.type === "Password" &&
                                                    <button
                                                        className="btn btn-link position-absolute top-0 end-0 pb-0 px-3  py-4 translate-middle-y text-decoration-none text-muted"
                                                        onClick={() => { setPasswordShow(!passwordShow); setOTPVerfied(false); setPasswordVerified(false); setCodeVerified(false); setQRCodeData(""); }}
                                                        type="button"  >
                                                        <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                                    </button>}

                                                {!isEdit && <Input type={item.type === "Password" ? passwordShow ? "text" : "password" : item.type} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                    placeholder={`Enter ${snakeToCamel(item.name) || "value"}`} name={item.name}
                                                    defaultValue={validation?.values[item.name] || ""}
                                                    onChange={(e) => {
                                                        handleCustomFieldsChange(index, "value", e.target.value);
                                                    }}
                                                    invalid={submit && validation.errors[item.name] ? true : false}
                                                />}

                                                {validation?.values?.authentication_type === "Single-factor" && item.type === "Password" && isEdit && !passwordVerified &&
                                                    <Input type={"password"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        readOnly={true}
                                                        defaultValue={("passwordVerified"?.substring(0, 10) + 'x'.repeat(Math.max(item?.name?.length - 5, 0)))?.substring(0, 20)}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {validation?.values?.authentication_type === "Single-factor" && item.type === "Password" && isEdit && passwordVerified &&
                                                    <Input type={"text"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        name={item.name}
                                                        defaultValue={password}
                                                        onChange={(e) => {
                                                            handleCustomFieldsChange(index, "value", e.target.value)
                                                        }}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {validation?.values?.authentication_type === "Two-factor" && item.type === "Password" && isEdit && !OTPVerfied &&
                                                    <Input type={"password"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        readOnly={true}
                                                        defaultValue={("passwordVerified"?.substring(0, 10) + 'x'.repeat(Math.max(item?.name?.length - 5, 0)))?.substring(0, 20)}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {validation?.values?.authentication_type === "Two-factor" && item.type === "Password" && isEdit && passwordVerified && OTPVerfied &&
                                                    <Input type={"text"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`} name={item.name}
                                                        defaultValue={password}
                                                        onChange={(e) => {
                                                            handleCustomFieldsChange(index, "value", e.target.value)
                                                        }}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {validation?.values?.authentication_type === "Two-factor" && item.type === "Password" && isEdit && passwordVerified && !OTPVerfied &&
                                                    <>
                                                        <div className="mb-2 mt-2 d-flex">
                                                            <Input type="text" className="form-control px-2 py-1 rounded-0 border-top-0 border-end-0 border-start-0 border-bottom-1" id="otp" name='otp'
                                                                onChange={otpValidation.handleChange}
                                                                onBlur={otpValidation.handleBlur}
                                                                placeholder="Enter otp"
                                                                invalid={submit && otpValidation.errors.otp ? true : false}
                                                            />
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <button className="btn btn-primary d-flex gap-1 py-1 align-items-center" onClick={(e) => {
                                                                    e.preventDefault(e);
                                                                    otpValidation.handleSubmit();
                                                                    setSubmit(true);
                                                                }}>
                                                                    {otpValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {validation?.values?.authentication_type === "Google" && item.type === "Password" && isEdit && !codeVerified &&
                                                    <Input type={"password"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        readOnly={true}
                                                        defaultValue={("passwordVerified"?.substring(0, 10) + 'x'.repeat(Math.max(item?.name?.length - 5, 0)))?.substring(0, 20)}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {validation?.values?.authentication_type === "Google" && item.type === "Password" && isEdit && passwordVerified && codeVerified &&
                                                    <Input type={"text"} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter ${snakeToCamel(item.name) || "value"}`}
                                                        name={item.name}
                                                        defaultValue={password}
                                                        onChange={(e) => {
                                                            handleCustomFieldsChange(index, "value", e.target.value)
                                                        }}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                }

                                                {item.type === "Password" && isEdit && passwordShow && !passwordVerified &&
                                                    <>
                                                        <div className="mb-2 mt-2 d-flex ">
                                                            <Input type="password" className="form-control px-2 py-1 rounded-0 border-top-0 border-end-0 border-start-0 border-bottom-1"   name='password'
                                                                onChange={showPasswordValidation.handleChange}
                                                                onBlur={showPasswordValidation.handleBlur}
                                                                placeholder="Enter password"
                                                                invalid={submit && showPasswordValidation.errors.password ? true : false}
                                                            />
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <button className="btn btn-primary d-flex gap-1 py-1 align-items-center" onClick={(e) => {
                                                                    e.preventDefault(e);
                                                                    showPasswordValidation.handleSubmit();
                                                                    setSubmit(true);
                                                                }}>
                                                                    {showPasswordValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {validation?.values?.authentication_type === "Google" && item.type === "Password" && isEdit && passwordVerified && !codeVerified && <Row>
                                                    <Col lg={12}>
                                                        <div className="mb-3 mt-2 d-flex">
                                                            <Input type="number" className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" id="totp" name='totp'
                                                               onChange={(e) => {
                                                                let inputValue = e.target.value;
                                                                if (inputValue.length > 6) {
                                                                    inputValue = inputValue.slice(0, 6);
                                                                }
                                                                codeValidation.handleChange({
                                                                    target: {
                                                                        name: 'totp',
                                                                        value: inputValue,
                                                                    },
                                                                });
                                                            }}
                                                                onBlur={codeValidation.handleBlur}
                                                                value={codeValidation.values.totp || ""}
                                                                placeholder="Enter totp"
                                                                invalid={codeValidation.touched.code && codeValidation.errors.totp ? true : false}
                                                            />

                                                            <Button color="success" type="submit" disabled={codeValidation.isSubmitting ? true : false}
                                                                onClick={(e) => {
                                                                    e.preventDefault(e);
                                                                    codeValidation.handleSubmit();
                                                                    setSubmit(true);
                                                                }}
                                                                className="btn btn-success d-flex gap-1 py-1 align-items-center"  >
                                                                {codeValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Verify"}
                                                            </Button>
                                                        </div>
                                                    </Col>
                                                </Row>}
                                            </>
                                            : item.type === "File" ?
                                                <InputGroup>
                                                    <Input type={!item.show && isEdit && item?.id ? "Text" : item.type} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                        placeholder={`Enter value`} name={item.name}
                                                        defaultValue={(isEdit && validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name) ? validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name || "" : ""}
                                                        onChange={(e) => {
                                                            validation.handleChange(e);
                                                            handleCustomFileChange(index, "value", e);
                                                        }}
                                                        readOnly={!item.show && isEdit && item?.id ? true : false}
                                                        invalid={submit && validation.errors[item.name] ? true : false}
                                                    />
                                                    {!item.show && isEdit && item?.id && <Button className='btn-secondary px-2 py-0' onClick={(e) => {
                                                        e.preventDefault();
                                                        const newCustomFields = [...customFields];
                                                        newCustomFields[index]["show"] = true;
                                                        setCustomFields(newCustomFields);
                                                    }}  >
                                                        <IoCloseOutline />
                                                    </Button>}
                                                </InputGroup>
                                                : <Input type={item.type} className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0"
                                                    placeholder={`Enter value`} name={item.name}
                                                    defaultValue={validation?.values[item.name] || ""}
                                                    onChange={(e) => {
                                                        handleCustomFieldsChange(index, "value", e.target.value)
                                                    }}
                                                    required={item.name ? true : false}
                                                    invalid={submit && validation.errors[item.name] ? true : false}
                                                />
                                        }
                                    </div>

                                    <div className='mb-1 col-12 col-md-1  px-0 d-flex gap-2 align-items-center justify-content-end'>
                                        <GoPlusCircle className="cursor primary" size={20} style={{ minWidth: "20px" }} onClick={handleCustomFields} />
                                        <CiCircleMinus className="cursor secondary" size={20} style={{ minWidth: "20px" }} onClick={() => customFields.length > 1 ? handleRemoveCustomFields(index) : ""} />
                                    </div>
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>


            <Row>
                <Col lg={12}>
                    {<div className="hstack gap-2 justify-content-end">
                        <button type="submit" className="btn btn-primary py-1 align-items-center" disabled={qrCodeData != "" && validation?.values?.authentication_type === "Google" ? !codeVerified : validation.isSubmitting} onClick={() => { setSubmit(true); }}>
                            {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Save"}</button>
                    </div>}
                </Col>
            </Row>
        </Form>
    );

}

export default FormPassword;

