import React, { useEffect, useState } from 'react';
import {
    Card, CardBody, Col, Container, Input, Label, Row, Button, Form, FormFeedback, Alert, Spinner, Offcanvas, FormGroup,
    OffcanvasHeader, OffcanvasBody,
} from 'reactstrap';
import ParticlesAuth from "../AuthenticationInner/ParticlesAuth";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import SimpleBar from "simplebar-react";
import withRouter from "../../Components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser, socialLogin, resetLoginFlag } from "../../slices/thunks";
import { createSelector } from 'reselect';
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimes, FaPlus } from "react-icons/fa";
import { loginSuccess, apiError } from '../../slices/auth/login/reducer';
import { api, url } from "../../helpers/api";
import { ToastMessage } from "../../Components/Hooks/Function";
import { ToastContainer, toast } from 'react-toastify';

const Login = (props) => {
    const dispatch = useDispatch();
    const selectLayoutState = (state) => state;
    const [settingsList, setSettingsList] = useState([]);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const history = useNavigate();
    const placement = isDesktop ? 'end' : 'start';
    const [showOtp, setShowOtp] = useState(false);
    const loginpageData = createSelector(
        selectLayoutState,
        (state) => ({
            user: state.Account.user,
            error: state.Login.error,
            // loading: state.Login.loading,
            errorMsg: state.Login.errorMsg,
        })
    );
    const {
        user, error,
        // loading, 
        errorMsg
    } = useSelector(loginpageData);

    const [email, setEmail] = useState(false);
    const [token, setToken] = useState(false);
    const [passwordShow, setPasswordShow] = useState(false);
    const [otpOption, setOtpOption] = useState(false);
    const [defaultOption, setDefaultOption] = useState(false);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const getSettings = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get('settingsDetails', {});

            if (response.data.status === true) {
                setSettingsList(response.data?.data);
                localStorage.setItem("settings", JSON.stringify(response.data?.data));
            } else {
                setSettingsList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
            send_otp: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            password: Yup.string().required("Please Enter Your Password"),
            send_otp: Yup.string(),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (values.send_otp !== "") {
                    await LoginWithOtp(values);
                    setSubmitting(false);
                } else {
                    await Login(values);
                    setSubmitting(false);
                }
            } catch (err) {
                console.error(err.message);
            }
        }
    });

    const getUserTOTPSettings = async (email) => {
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`userTOTPSettings/${email}`, {});

            if (response.data.status === true) {
                if (response.data.data) {
                   setDefaultOption(true);
                    validation.setValues({
                        ...validation.values,
                        send_otp: 'totp'
                    });
                   setOtpOption(true);
                } else {
                     setDefaultOption(false); 
                   setOtpOption(false);
                   
                }
            } else {
               // setOtpOption(otpOption);
                toast.error(response.data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                });
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getSettings(); 
    }, []);

    const Login = async (values) => {
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post('login', values);
            if (response.data.status === true) {
                verifyUser(response.data?.token);
            } else {
                dispatch(apiError(response.data.message));
            }

        } catch (err) {
            console.error(err.message);
            ToastMessage("error", err.message);
        }
    }

    const LoginWithOtp = async (values) => {
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post('loginOtp', values);
            if (response.data.status === true) {
                ToastMessage("success", response.data?.message);
                setEmail(values.email);
                setToken(response.data?.token)
                setShowOtp(true);
            } else {
                setShowOtp(false);
                dispatch(apiError(response.data.message));
            }

        } catch (err) {
            console.error(err.message);
            ToastMessage("error", err.message);
        }
    }

    const forgetValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter your email"),

        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await forgetPassword(values);
                setSubmitting(false);
            } catch (error) {

                console.error("An error occurred:", error);
                setSubmitting(false);
                ToastMessage("error", err.message);
            }
        },
    });

    const otpValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: email,
            otp: '',
        },
        validationSchema: Yup.object({
            otp: Yup.string().required("Please enter your email"),

        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await otpVerification(values);
                setSubmitting(false);
            } catch (error) {

                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const otpVerification = async (values) => {
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post('verifyOTP', values);
            if (response.data.status === true) {
                verifyUser(token);
            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const verifyUser = async (token) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + token;
        api.defaults.headers.common['Content-Type'] = 'application/json'
        const response = await api.get('userDetails', {});
        if (response.data.status === true) {
            localStorage.setItem("token", token);
            localStorage.setItem("authUser", JSON.stringify({
                email: response.data?.data.email,
                user: response.data?.data,
            }));

            dispatch(loginSuccess(response.data?.data));
            otpValidation.resetForm();

            history('/passwords');
        } else {
            ToastMessage("error", response.data?.message);
        }
    }


    const forgetPassword = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post('forgetPassword', values);
            if (response.data.status === true) {
                forgetValidation.resetForm();
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };


    const codeValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: email,
            totp: '',
        },
        validationSchema: Yup.object({
            totp: Yup.string().required("Please enter your email")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                setSubmitting(true);
                TOTPVerification(values);
                setSubmitting(false);

            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    useEffect(() => {
        if (errorMsg) {
            setTimeout(() => {
                dispatch(resetLoginFlag());
            }, 3000);
        }
    }, [dispatch, errorMsg]);


    const TOTPVerification = async (values) => {
        try {
            const response = await api.post(`LoginTOTPVerification`, values);
            if (response.data.status === true) {
                codeValidation.resetForm();
                verifyUser(token);
            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (error) {
            console.error('Error generating OTP:', error);
        }
    };

    document.title = "Login";
    return (
        <React.Fragment>
            <ParticlesAuth>
                <ToastContainer closeButton={false} />
                <div className="auth-page-content">
                    <Container>
                        <Row className="justify-content-center mt-4">
                            <Col md={8} lg={6} xl={5}>
                                <Card className="mt-4">
                                    <CardBody className="p-4">
                                        <div className="text-center mt-2">
                                            <div className='mb-4'>
                                                <Link to="/" className="d-inline-block auth-logo">
                                                    <img src={url + settingsList.filter(type => type?.name === "logo-light")[0]?.value} alt="" />
                                                </Link>
                                            </div>
                                            <h4 className="text-primary">Login</h4>
                                        </div>
                                        {error && error ? (<Alert color="danger"> {error} </Alert>) : null}
                                        <div className="p-2 mt-4">
                                            {!showOtp ?
                                                <Form
                                                    onSubmit={(e) => {
                                                        e.preventDefault();
                                                        validation.handleSubmit();
                                                        return false;
                                                    }}
                                                    action="#">
                                                    <div className="mb-3">
                                                        <Label htmlFor="email" className="form-label">Email</Label>
                                                        <Input
                                                            name="email"
                                                            className="form-control"
                                                            placeholder="Enter email"
                                                            type="email"
                                                            onChange={validation.handleChange}
                                                            onBlur={(e) => {
                                                                validation.handleBlur(e);
                                                                getUserTOTPSettings(e.target?.value)
                                                            }}
                                                            value={validation.values.email || ""}
                                                            invalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <FormFeedback type="invalid">{validation.errors.email}</FormFeedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        {/* <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">Forgot password?</Link>
                                                        </div> */}
                                                        <Label className="form-label" htmlFor="password-input">Password</Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Input
                                                                name="password"
                                                                value={validation.values.password || ""}
                                                                type={passwordShow ? "text" : "password"}
                                                                className="form-control pe-5"
                                                                placeholder="Enter Password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                invalid={
                                                                    validation.touched.password && validation.errors.password ? true : false
                                                                }
                                                            />
                                                            {validation.touched.password && validation.errors.password ? (
                                                                <FormFeedback type="invalid">{validation.errors.password}</FormFeedback>
                                                            ) : null}
                                                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" onClick={() => setPasswordShow(!passwordShow)} type="button" id="password-addon"><i className="ri-eye-fill align-middle"></i></button>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex gap-3 pt-1 mb-2">

                                                        {/*   <div className="form-check"> <Input className="form-check-input" type="checkbox" onChange={(e) => {
                                                                validation.setValues({
                                                                    ...validation.values,
                                                                    send_otp: e.target.checked ? "email" : ""
                                                                });
                                                                setOtpOption(!otpOption);
                                                            }} />
                                                            <Label className="form-check-label" htmlFor="send_otp">Send OTP</Label>  </div> */}

                                                        <FormGroup check>
                                                            <Input
                                                                id="send_otp"
                                                                name="send_otp"
                                                                type="checkbox"
                                                                checked={otpOption}
                                                                disabled={defaultOption}
                                                                onChange={(e) => {
                                                                    validation.setValues({
                                                                        ...validation.values,
                                                                        send_otp: e.target.checked ? "email" : ""
                                                                    });
                                                                    setOtpOption(!otpOption);
                                                                }}
                                                            />
                                                            <Label check for="send_otp"  >
                                                                Send OTP
                                                            </Label>
                                                        </FormGroup>
                                                    </div>
                                                    {otpOption ?
                                                        <FormGroup row tag="fieldset" >
                                                            <Col sm={12} className='d-block d-md-flex gap-2'>
                                                                <FormGroup check>
                                                                    <Input
                                                                        id="email"
                                                                        name="send_otp"
                                                                        type="radio"
                                                                        value="email"
                                                                        onChange={validation.handleChange}
                                                                        checked={validation.values.send_otp === 'email'}
                                                                    />
                                                                    <Label for="email" check> Email </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Input
                                                                        id="sms"
                                                                        name="send_otp"
                                                                        type="radio"
                                                                        value="sms"
                                                                        onChange={validation.handleChange}
                                                                        checked={validation.values.send_otp === 'sms'}
                                                                    />
                                                                    <Label for="sms" check> SMS </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Input
                                                                        id="totp"
                                                                        name="send_otp"
                                                                        type="radio"
                                                                        value="totp"
                                                                        onChange={validation.handleChange}
                                                                        checked={validation.values.send_otp === 'totp'}
                                                                    />
                                                                    <Label for="totp" check> TOTP </Label>
                                                                </FormGroup>
                                                                <FormGroup check>
                                                                    <Input
                                                                        id="both"
                                                                        name="send_otp"
                                                                        type="radio"
                                                                        value="both"
                                                                        onChange={validation.handleChange}
                                                                        checked={validation.values.send_otp === 'both'}
                                                                    />
                                                                    <Label for="both" check> SMS & Email </Label>
                                                                </FormGroup>
                                                            </Col>
                                                        </FormGroup>
                                                    : ""}

                                                    {/* <div className="form-check">
                                                        <Link style={{ color: "#177EF4" }} onClick={(e) => { toggleDrawer(); }}  > Forget Password </Link>
                                                    </div> */}
                                                    <div className="mt-4">
                                                        <Button color="success" disabled={error ? null : validation.isSubmitting ? true : false} className="btn btn-success w-100" type="submit">
                                                            {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                            Sign In
                                                        </Button>
                                                    </div>
                                                </Form> :

                                                validation.values.send_otp === "totp" ?
                                                    <Form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        codeValidation.handleSubmit();
                                                        return false;
                                                    }}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="totp" className="form-label">TOTP</Label>
                                                                    <Input type="number" className="form-control" id="totp" name='totp'
                                                                        onChange={(e) => {
                                                                            let inputValue = e.target.value;
                                                                            if (inputValue.length > 6) {
                                                                                inputValue = inputValue.slice(0, 6);
                                                                            }                                                                        
                                                                            codeValidation.handleChange({
                                                                                target: {  name: 'totp',  value: inputValue },
                                                                            });
                                                                        }}

                                                                        onBlur={codeValidation.handleBlur}
                                                                        value={codeValidation.values.totp || ""}
                                                                        placeholder="Enter TOTP"
                                                                        invalid={codeValidation.touched.totp && codeValidation.errors.totp ? true : false}
                                                                    />
                                                                </div>
                                                            </Col>

                                                            <Col lg={12}>
                                                                <div className="hstack gap-2 justify-content-end">
                                                                    <Button color="success" type="submit" disabled={codeValidation.isSubmitting ? true : false}
                                                                        className="btn btn-success"  >
                                                                        {codeValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                        {"Verify"}    
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form> :
                                                    <Form onSubmit={(e) => {
                                                        e.preventDefault();
                                                        otpValidation.handleSubmit();
                                                        return false;
                                                    }}>
                                                        <Row>
                                                            <Col lg={12}>
                                                                <div className="mb-3">
                                                                    <Label htmlFor="otp" className="form-label">OTP</Label>
                                                                    <Input type="text" className="form-control" id="otp" name='otp'
                                                                        onChange={otpValidation.handleChange}
                                                                        onBlur={otpValidation.handleBlur}
                                                                        value={otpValidation.values.otp || ""}
                                                                        placeholder="Enter OTP"
                                                                        invalid={otpValidation.touched.otp && otpValidation.errors.otp ? true : false}
                                                                    />

                                                                </div>
                                                            </Col>

                                                            <Col lg={12}>
                                                                <div className="hstack gap-2 justify-content-end">
                                                                    <Button color="success" type="submit" disabled={otpValidation.isSubmitting ? true : false}
                                                                        className="btn btn-success"  >
                                                                        {otpValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                        {"Login"}
                                                                    </Button>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Form>}

                                        </div>                                       
                                    </CardBody>
                                </Card>

                                <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                                    <OffcanvasHeader className="d-flex align-items-center bg-primary  p-3  " toggle={function noRefCheck() { }}   >
                                        <span className="d-flex justify-content-between" >
                                            <span className="m-0 me-2 text-white">{"Forgot Password"}</span>
                                                <span className="fs-18  text-color-white px-1"  >
                                                    <FaCheck className='cursor' style={{ color: "white" }} onClick={() => { forgetValidation.handleSubmit(); }} />
                                                    <span className="fs-18  text-color-white cursor" onClick={() => { toggleDrawer() }}>   <FaTimes style={{ color: "white" }} /> </span>
                                                </span>
                                        </span>
                                    </OffcanvasHeader>
                                    <OffcanvasBody className="p-0">
                                        <SimpleBar className="h-100">
                                            <div className="p-4">
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    forgetValidation.handleSubmit();
                                                    return false;
                                                }}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="email" className="form-label">Email</Label>
                                                                <Input type="text" className="form-control" id="email" name='email'
                                                                    onChange={forgetValidation.handleChange}
                                                                    onBlur={forgetValidation.handleBlur}
                                                                    value={forgetValidation.values.email || ""}
                                                                    placeholder="Enter Email ID"
                                                                    invalid={  forgetValidation.touched.email && forgetValidation.errors.email ? true : false   }
                                                                />

                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <Button color="success" type="submit" disabled={forgetValidation.isSubmitting ? true : false}
                                                                    className="btn btn-success"  >
                                                                    {forgetValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                    {"Save"}
                                                                </Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            </div>
                                        </SimpleBar>
                                    </OffcanvasBody>
                                </Offcanvas>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </ParticlesAuth>
        </React.Fragment>
    );
};

export default withRouter(Login);