import React, { useState, useEffect, useCallback } from 'react';
import {
    Button, Card, CardBody, Nav, FormGroup, NavItem,
    NavLink, Offcanvas, OffcanvasHeader, OffcanvasBody, TabContent, Spinner,
    TabPane, ModalFooter, ModalHeader, Col, Container, Form, Input, Label, Modal, ModalBody, Row,
} from 'reactstrap'; 
import BreadCrumb from '../../Components/Common/BreadCrumb';
 import { useMediaQuery } from 'react-responsive';
 import SimpleBar from "simplebar-react";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import * as Yup from "yup";
 import { ToastContainer, toast } from 'react-toastify';
import DeleteModal from "../../Components/Common/DeleteModal";
import { IoCloseOutline } from "react-icons/io5";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import { HiOutlineCheck } from "react-icons/hi2";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md"; 
import { ToastMessage, DefaultDate, SmallDateFormat, FormatDate, snakeToCamel } from "../../Components/Hooks/Function";
import FormPasswordCategory from './FormPasswordCategory';

const PasswordCategory = () => {
    document.title = "Password Categories";
    const [open, setOpen] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [passwordCategoriesList, setPasswordCategoriesList] = useState([]);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [isEdit, setIsEdit] = useState(false);
    const [customFields, setCustomFields] = useState([{ name: '', type: 'Text', mandatory: 'false' }]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [passwordCategoriesView, setPasswordCategoriesView] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [deleteFieldModal, setDeleteFieldModal] = useState(false);
    const [searchList, setSearchList] = useState([]);

    const handleAddClick = () => {
        setIsEdit(false);
        toggleDrawer();
        setCustomFields([{ name: 'Email', type: "Text", mandatory: true }, { name: 'Password', type: "Password", mandatory: true }]);
        setSubmit(false);
        validation.setValues({});
    };

    const handleEditClick = useCallback((arg) => {
        setCustomFields([{ name: '', type: "", mandatory: false }]);
        setSubmit(false);
        viewPasswordCategory(arg?.id);
        setIsEdit(true);
    }, []);

    const toggleDrawer = () => {
        setOpen(!open);
    };



    const addCategory = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            for (const key in value) {
                formData.append(key, value[key] || '');
            }
            customFields.forEach((input, index) => {
                formData.append(`category[${index}][name]`, input.name);
                formData.append(`category[${index}][type]`, input.type);
                formData.append(`category[${index}][mandatory]`, (input.mandatory === true || input.mandatory === 1) ? 1 : 0);
            });

            const response = await api.post('passwordCategory', formData);

            if (response.data.status === true) {
                setIsEdit(false);
                setOpen(false);
                validation.resetForm();
                getPasswordCategoriesList();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const editCategory = async (value, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            formData.append('_method', "PUT");
            for (const key in value) {
                formData.append(key, value[key] || '');
            }
            customFields.forEach((item, index) => {
                formData.append(`category[${index}][id]`, (item?.id || ""));
                formData.append(`category[${index}][name]`, item.name);
                formData.append(`category[${index}][type]`, item.type);
                formData.append(`category[${index}][mandatory]`, (item.mandatory === true || item.mandatory === 1) ? 1 : 0);
            });

            const response = await api.post('passwordCategory/' + id, formData);
            if (response.data.status === true) {
                getPasswordCategoriesList();
                setIsEdit(false);
                validation.resetForm();
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const viewPasswordCategory = async (id) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('passwordCategory/' + id, {});

            if (response.data.status === true) {
                validation.setValues(response.data.data);
                setCustomFields(response.data.data?.password_category_lists)
            }
            setOpen(true);
        } catch (err) {
            console.error(err.message);
        }
    };

    const deleteCategory = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('passwordCategory/' + value?.id, {});
            if (response.data.status === true) {
                getPasswordCategoriesList()
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }
    }; 

    const updateVisibility = async (value, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('updateVisibility/' + id, {
                visibility: value ? 1 : 0
            });
            if (response.data.status === true) {
                getPasswordCategoriesList()
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            category_name: '',
        },
        validationSchema: Yup.object({
            category_name: Yup.string().required("Please enter")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEdit) {
                    await editCategory(values, validation.values?.id);
                } else {
                    await addCategory(values);
                }
                setSubmitting(false);
            } catch (error) {

                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const onClickData = () => {
        setDeleteModal(true);
    };

    const getPasswordCategoriesList = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`passwordCategory?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setSearchList(response.data?.data);
                setPasswordCategoriesList(response.data?.data);
            } else {
                setSearchList([]);
                setPasswordCategoriesList([]);
                ToastMessage("error", response.data?.message);
            }
            setLoading(false);
        } catch (err) {
            console.error(err.message);
            if (err.response?.status === 401) {
                history('/logout');
            }
        }
    };

    const getPasswordCategoriesListPage = async (currentPage, rowPerPage) => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`passwordCategory?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setSearchList(response.data?.data);
                setPasswordCategoriesList(response.data?.data);
            } else {
                setSearchList([]);
                setPasswordCategoriesList([]);
            }
            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }

    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        getPasswordCategoriesListPage(currentPage - 1, rowPerPage);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        getPasswordCategoriesListPage(currentPage + 1, rowPerPage);
    };

    useEffect(() => {
        getPasswordCategoriesList();
    }, []);

    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.category_name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.user?.name?.toLowerCase().includes(inputVal.toLowerCase())
            );

            setPasswordCategoriesList({
                ...passwordCategoriesList,
                data: filteredResults
            });
        } else {
            setPasswordCategoriesList({
                ...passwordCategoriesList,
                data: searchList?.data
            });
        }
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={passwordCategoriesView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteCategory(passwordCategoriesView)}
                onCloseClick={() => setDeleteModal(false)}
            />

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Password Categories" pageTitle="Pages" />
                    <Row>
                        <Col xs={12} className='d-block d-md-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center  justify-content-between justify-content-md-end gap-2'>
                                <Button type="button" color="primary" outline className="py-1 " onClick={() => { handleAddClick(); }} >  ADD  </Button>
                                <div className="search-box">
                                    <Input type="text" className="form-control py-1" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </div>
                            <div className='d-flex gap-1  align-items-center justify-content-between justify-content-md-end mt-2' >
                                <div className='d-flex align-items-center'>
                                    {(passwordCategoriesList?.from || 1) + " - " + (passwordCategoriesList?.to || 1) + " of " + (passwordCategoriesList?.total || 1)}
                                    <div>
                                        <button className='border-0 bg-white pe-0' onClick={handlePrevPage} disabled={passwordCategoriesList?.current_page === 1}>
                                            <MdOutlineKeyboardArrowLeft size={25} />
                                        </button>
                                        <button className='border-0 bg-white ps-0' onClick={handleNextPage} disabled={passwordCategoriesList?.current_page === passwordCategoriesList?.last_page}>
                                            <MdOutlineKeyboardArrowRight size={25} />
                                        </button>
                                    </div>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    Shows
                                    <select className="pagination-prev disabled  h-100 px-1 py-1" type="select" name="perPage" id="perPage"
                                        onChange={(e) => {
                                            setRowPerPage(e.target.value);
                                            getPasswordCategoriesList(1, e.target.value);
                                        }}
                                        value={rowPerPage} >
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={passwordCategoriesList?.total}>All</option>
                                    </select>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-light border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-7 col-md-4  px-2"  >
                                            <h5 className="fs-15 mb-0"> Category</h5>
                                        </div>
                                        <div className="d-flex   col-6 col-md-5  mt-0 align-items-center  justify-content-end  justify-content-md-start">
                                            <h5 className="fs-15 mb-0">Added By</h5>
                                        </div>
                                        <div className="d-flex col-6 ml-0 col-md-2 mt-0 align-items-center  justify-content-end  justify-content-md-start"  >
                                            <h5 className="fs-15 mb-0">Visibility</h5>
                                        </div>
                                        <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end  ">
                                            <h5 className="fs-15 mb-0">  Actions</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row gy-1 mb-1" id="passwordCategories-list">
                        {loading ?
                            <div className='text-center'>
                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>
                            :
                            passwordCategoriesList?.data?.length !== 0 ?
                                (passwordCategoriesList?.data)?.map((item, index) => (
                                    <Col lg={12} key={index} className='mt-0' >
                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                            <CardBody className="px-2 py-0">
                                                <div className="d-lg-flex align-items-center row">
                                                    <div className="col-6 col-md-4 align-items-center  ">
                                                        <p className="mb-0 ">  {item.category_name}</p>
                                                    </div>
                                                    <div className="col-6 col-md-5 d-flex align-items-center  justify-content-end justify-content-md-start">
                                                        <p className="mb-0 ">  {item.user?.name}</p>
                                                    </div>
                                                    <div className="col-6 col-md-2 d-flex align-items-center">
                                                        <div className="form-check form-switch form-switch-left form-switch-sm">
                                                            <Input
                                                                type="switch"
                                                                id="visibility"
                                                                name="visibility"
                                                                defaultValue={item.visibility}
                                                                checked={item.visibility}
                                                                onChange={(e) => {
                                                                    updateVisibility(e.target.checked, item.id);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" col-6 col-md-1 d-flex  gap-0 align-items-center justify-content-end px-1">
                                                        <button type="button" className="btn  btn-sm btn-icon "
                                                            onClick={(e) => {
                                                                handleEditClick(item);
                                                            }}>
                                                            <HiOutlinePencilAlt className="fs-18 primary" />
                                                        </button>

                                                        <button type="button" className="btn btn-sm btn-icon "
                                                            onClick={(e) => {
                                                                onClickData(item); setPasswordCategoriesView(item);
                                                            }}>
                                                            <HiOutlineTrash className="fs-18 secondary" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                                :
                                <div className="noresult" >
                                    <div className="text-center">
                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                        {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                    </div>
                                </div>
                        }
                    </Row>



                    <Modal isOpen={deleteFieldModal} toggle={() => { setDeleteFieldModal(false) }} centered={true}>
                        <ModalBody className="py-3 px-5">
                            <div className="mt-2 text-center">
                                <lord-icon
                                    src="https://cdn.lordicon.com/gsqxdxog.json"
                                    trigger="loop"
                                    colors="primary:#FFBC0A,secondary:#f06548"
                                    style={{ width: "100px", height: "100px" }}
                                ></lord-icon>

                                <div className="mt-4 pt-2 fs-15">
                                    <h4>Are you sure?</h4>
                                    <p className="text-muted mx-4 mb-0"> This field used in password,
                                        so please remove value before remove this field?</p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <button
                                    type="button"
                                    className="btn w-sm btn-light"
                                    data-bs-dismiss="modal"
                                    onClick={() => setDeleteFieldModal(false)}
                                >
                                    Close
                                </button>
                            </div>
                        </ModalBody>
                    </Modal>


                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-light px-3 py-2" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2">{!isEdit ? "Add Password Category" : "Update Password Category"}</span>
                                <span>
                                    <span className="fs-18 px-1" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>
                                        <HiOutlineCheck className='cursor' /></span>
                                    <span className="fs-18 cursor" onClick={() => { toggleDrawer() }}>   <IoCloseOutline /> </span>
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="p-4">
                                   <FormPasswordCategory  validation={validation}  submit={submit} setSubmit={setSubmit}
                                   customFields={customFields} setCustomFields={setCustomFields}  />
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PasswordCategory;