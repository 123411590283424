import React, { useState, useEffect, useCallback } from 'react';
import {
    Button, Card, CardBody, Nav, FormGroup, NavItem,
    NavLink, Offcanvas, OffcanvasHeader, OffcanvasBody, TabContent, Spinner,
    TabPane, ModalFooter, ModalHeader, Col, Container, Form, Input, Label, Modal, ModalBody, Row,
} from 'reactstrap'; import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimesCircle, FaTimes, FaImage, FaFilePdf, FaFileAlt, FaFileExcel } from "react-icons/fa";
import SimpleBar from "simplebar-react";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import * as Yup from "yup";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import DeleteModal from "../../Components/Common/DeleteModal";
import { IoCloseOutline } from "react-icons/io5";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import { HiOutlineCheck } from "react-icons/hi2";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { ToastMessage } from "../../Components/Hooks/Function";
import FeatherIcon from 'feather-icons-react';
import { GoPlusCircle } from "react-icons/go";
import FormDocumentCategory from './FormDocumentCategory';
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";


const Document = () => {
    document.title = "Document";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [deleteModal, setDeleteModal] = useState(false);
    const [documentList, setDocumentList] = useState([]);
    const [attachmentList, setAttachmentList] = useState([]);
    const [submit, setSubmit] = useState(false);
    const [fileUrl, setFileUrl] = useState([]);
    const [documentView, setDocumentView] = useState(null)
    const [open, setOpen] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [isEdit, setIsEdit] = useState(false);
    // const [ID, setID] = useState(0);
    const [modalFile, setModalFile] = useState(false);
    const [modalAttachment, setModalAttachment] = useState({
        show: false,
        file: [],
    });
    const [file, setFile] = useState([]);
    const [isExpanded, setIsExpanded] = useState([]);
    const [files, setFiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [categoryList, setCategoryList] = useState([]);
    const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
    const pdfExtensions = new Set(["pdf"]);
    const excelExtensions = new Set(["xls", "xlsx"]);
    const [modalConfirm, setModalConfirm] = useState(false);
    const [passwordVerified, setPasswordVerified] = useState(false);
    const [OTPVerfied, setOTPVerfied] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);

    const userDetails = getLoggedinUser()?.user;

    const selectStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                minHeight: '30px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da',
                borderTop: 'none',
                borderLeft: 'none',
                borderRight: 'none',
                borderRadius: '0px',
                boxShadow: "none,"
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            groupHeading: (provided) => ({
                fontWeight: 'bold',
                color: '#019aff',
                paddingLeft: '10px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',

            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px',
            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        }
    }

    const [view, setView] = useState("");

    const handleAddClick = (arg) => {
        setView("");

        setModalAttachment({
            show: false,
            file: [],
        });
        setSubmit(false);
        setAttachmentList([]);
        setFile([]);
        setFiles([]);
        validation.resetForm();
        validation.setValues({});
        setIsEdit(false);
        toggleDrawer();
    };

    const handleEditClick = useCallback((arg) => {
        setModalAttachment({
            show: false,
            file: [],
        });
        setView("");
        setSubmit(false);
        setFile([]);
        setFiles([]);
        setAttachmentList([]);
        viewDocument(arg?.id);
        validation.resetForm();
        setIsEdit(true);
    }, []);

   /* const handleViewClick = (arg) => {
        setView("");
        viewDocument(arg?.id);
        validation.resetForm();
        setIsEdit(false);
    }; */

    const onClickData = () => {
        setDeleteModal(true);
    };

   /* const handleClick = (e) => {
        setCurrentPage(e);
        getDocumentPage(e, rowPerPage);
    }; */

    const getCategory = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('documentCategoryList', {});
            if (response.data.status === true) {
                setCategoryList(response.data?.data);
            } else {
                setCategoryList([]);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleDelete = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('documentAttachment/' + id, {});
            if (response.data.status === true) {
                setAttachmentList(attachmentList?.filter(item => item.id !== id));
                setFile(file?.filter(item => item.id !== id));
                setFiles(files?.filter(item => item.id !== id));
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const keywordSearch = async (e) => {
        let inputVal = e;

        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter((item) => {
                const itemNameMatch = item.name?.toLowerCase().includes(inputVal.toLowerCase());
                const itemDescriptionMatch = item.description?.toLowerCase().includes(inputVal.toLowerCase());
                const attachmentNameMatch = (item.document_attachments || []).some((document) =>
                    document.name?.toLowerCase().includes(inputVal.toLowerCase())
                );
                return itemNameMatch || itemDescriptionMatch || attachmentNameMatch;
            });
            setDocumentList({
                ...documentList,
                data: filteredResults
            });
        } else {
            setDocumentList({
                ...documentList,
                data: searchList?.data
            });
        }
    };

    const handleFileChange = (e) => {
        const maxSizeInBytes = 10 * 1024 * 1024;
        const files = Array.from(e.target.files);
        const totalSize = files.reduce((acc, file) => acc + file.size, 0);
        if (totalSize <= maxSizeInBytes) {
            setFile(files);
            setFiles(files.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file)
                })));

        } else {
            setFile([]);
            setFiles([]);
            ToastMessage("error", "Uplaoad File is Greater than 10 MB");

        }
    }

    const viewAttachment = async (id) => {
        setPasswordVerified(false);
        setOTPVerfied(false);
        setCodeVerified(false);
        validation.resetForm();
        showPasswordValidation.resetForm();
        otpValidation.resetForm();
        codeValidation.resetForm();
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('document/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
                setView("Authentication");
            } else {
                setView("");
            }
            setOpen(true);
        } catch (err) {
            console.error(err.message);
        }
    }

    const [loading, setLoading] = useState(false);

    const getDocument = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`document`, {});
            if (response.data.status === true) {
                setDocumentList(response.data?.data);
                setSearchList(response.data?.data);
                setIsExpanded(response.data?.data?.data?.map(() => false))
            } else {
                setDocumentList([]);
                setSearchList([]);
                ToastMessage("error", response.data?.message);
            }
            setLoading(false);
            setCurrentPage(1);
        } catch (err) {
            console.error(err.message);
            // if (err.response?.status === 401) {
            //     history('/logout');
            // }
        }
    };


    const getDocumentPage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`document?page=${currentPage}&perPage=${rowPerPage}`);
            if (response.data.status === true) {
                setDocumentList(response.data?.data);
                setSearchList(response.data?.data);
                setIsExpanded(response.data?.data?.data?.map(() => false))
            } else {
                setDocumentList([]);
                setSearchList([]);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleToggle = (index) => {
        const newExpandedStates = [...isExpanded];
        newExpandedStates[index] = !newExpandedStates[index];
        setIsExpanded(newExpandedStates);
    };

    useEffect(() => {
        setIsExpanded(documentList?.data?.map(() => false))
    }, [documentList]);


    const viewDocument = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('document/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
                setDocumentView(response.data?.data);
                setAttachmentList(response.data?.attachment);
            } else {
                setDocumentView([]);
                setAttachmentList([]);
            }
            setOpen(true);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getDocument();
        getCategory();
    }, []);

    const goToNextSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide + 1) % modalAttachment?.file?.length);
    };

    const goToPreviousSlide = () => {
        setCurrentSlide((prevSlide) =>
            prevSlide === 0 ? modalAttachment?.file?.length - 1 : prevSlide - 1
        );
    };

    const addDocuments = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            for (const key in values) {
                if (values.hasOwnProperty(key)) {

                    if (key === "encrypt") {
                        formData.append('encrypt', values.encrypt ? 1 : 0);
                    } else {
                        formData.append(key, values[key] || '');
                    }
                }
            }

            Object.keys(file).forEach(key => {
                const files = file[key];
                formData.append('attachment[]', files);
            });

            const response = await api.post('document', formData);
            if (response.data.status === true) {
                getDocument();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                setFile([]);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const editDocuments = async (values, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            formData.append('_method', "PUT");
            for (const key in values) {
                if (values.hasOwnProperty(key)) {
                    if (key === "encrypt") {
                        formData.append('encrypt', values.encrypt ? 1 : 0);
                    } else {
                        formData.append(key, values[key] || '');
                    }
                }
            }

            Object.keys(file).forEach(key => {
                const files = file[key];
                formData.append('attachment[]', files);
            });

            const response = await api.post('document/' + id, formData);

            if (response.data.status === true) {
                getDocument();
                setIsEdit(false);
                validation.resetForm();
                setFile([]);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }
    };

    const deleteDocuments = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('document/' + value?.id, {});
            if (response.data.status === true) {
                getDocument();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)
        } catch (err) {
            console.error(err.message);
        }
    };


    function getExtension(filename) {
        return filename?.split('.').pop()
    }

    const validation = useFormik({
        enableReinitialize: true,

        initialValues: {
            name: '',
            document_category_id: '',
            description: '',
            authentication_type: '',
            attachment: '',
            encrypt: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Enter name"),
            document_category_id: Yup.string().required("Select Category"),
            description: Yup.string().notRequired(),
            encrypt: Yup.string().notRequired(),
            authentication_type: Yup.string().required("Please enter"),
            attachment: !isEdit ? Yup.array().required("Enter name") : Yup.array().notRequired(),
        }),

        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEdit) {
                    await editDocuments(values, validation.values?.id);
                } else {
                    await addDocuments(values);
                }
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const categoryValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please name"),
            description: Yup.string().notRequired(),
        }),
        onSubmit: (values) => {
            addCategory(values);
        },
    });

    const addCategory = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post('documentCategory', values);
            if (response.data.status === true) {
                getCategory();
                setSubmit(false);
                categoryValidation.resetForm();
                setIsEdit(false);
                setView("");
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const otpValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string().required("Please enter"),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await verifyDocumentOtp(values);
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const codeValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: userDetails?.email,
            totp: '',
        },
        validationSchema: Yup.object({
            totp: Yup.string().required("Please enter your email")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await TOTPVerificationID(values, validation.values.id);
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const TOTPVerificationID = async (values, id) => {
        try {
            const response = await api.post(`TOTPVerificationDocument/${id}`, {
                ...values,
                email: userDetails.email,
                password: showPasswordValidation?.values?.password
            });
            if (response.data.status === true) {
                codeValidation.resetForm();                 
                setOpen(false);
                setModalAttachment({ show: true, file: response.data.attachments });
                setCodeVerified(true);
                setPasswordVerified(true);
            } else {
                setCodeVerified(false);
                ToastMessage("error", response.data?.message);
            }
        } catch (error) {
            console.error('Error generating OTP:', error);
        }
    };

    // const requestDocumentOtp = async (id) => {

    //     setLoading(true);
    //     api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    //     api.defaults.headers.common['Content-Type'] = 'application/json'
    //     try {
    //         const response = await api.get('requestDocumentOtp/' + id, {});
    //         if (response.data.status === true) {
    //             // setOpen(true);
    //             //  setID(0);
    //             // ToastMessage("success", response.data?.message);
    //             // setModalAttachment({ ...modalAttachment, show: false, authentication: true, file: response.data.data });
    //         } else {
    //             setOpen(false);
    //             // setModalAttachment({ ...modalAttachment, show: false, authentication: true, file: [] });

    //             ToastMessage("error", response.data?.message);
    //         }
    //         setLoading(false);
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // }

    const verifyDocumentOtp = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const response = await api.post('verifyDocumentOtp/' + validation.values?.id, {
                otp: value.otp
            });
            if (response.data.status === true) {
                setOpen(false);
                setModalAttachment({ show: true, file: response.data.attachments });
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        getDocumentPage(currentPage - 1, rowPerPage);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        getDocumentPage(currentPage + 1, rowPerPage);
    };

    const deleteDocumentsAttachment = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('decryptFileDestroy/' + id, {});
            if (response.data.status === true) {
                // ToastMessage("success", response.data?.message);
            } else {
                // ToastMessage("error", response.data?.message);
            }

            setModalAttachment({
                show: false,
                file: [],
            });

        } catch (err) {
            console.error(err.message);
        }
    };

    const downloadFile = async () => {
        try {
            const response = await axios.get(`http://your-laravel-backend-url/download/${filename}`, {
                responseType: 'blob',
            });
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const showPasswordValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            password: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password")
        }),
        onSubmit: async (values) => {
            try {
                await requestShowDocument(values);
            } catch (err) {
                console.error(err.message);
            }
        }
    });

    const requestShowDocument = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`requestShowDocument/${validation?.values?.id}`, {
                ...values,
                email: userDetails.email
            });
            if (response.data.status === true) {
                setPasswordVerified(true);
                if (validation?.values?.authentication_type === "Single-factor") {
                    setOpen(false);
                    setModalAttachment({ show: true, file: response.data.attachments });
                } else if (validation?.values?.authentication_type === "Two-factor") {
                    setOTPVerfied(false);
                    handleTwoFactor(validation?.values?.id);
                } else {
                }
            } else {
                setPasswordVerified(false);
                ToastMessage("error", "Incorrect password");
            }
        } catch (err) {
            console.error(err.message);
            ToastMessage("error", err.message);
        }
    }

    const handleTwoFactor = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`requestTwoFactorDocument/${id}`, {});
            if (response.data.status === true) {
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={documentView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteDocuments(documentView)}
                onCloseClick={() => setDeleteModal(false)}
            />

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Documents" pageTitle="Pages" />
                    <Row>
                        <Col xs={12} className='d-block d-md-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center  justify-content-between justify-content-md-end gap-2'>
                                <Button type="button" color="primary" outline className="py-1" onClick={() => { handleAddClick(); }} >  ADD  </Button>
                                <div className="search-box">
                                    <Input type="text" className="form-control py-1" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </div>

                            <div className='d-flex gap-1  align-items-center justify-content-between justify-content-md-end mt-2' >
                                <div className='d-flex align-items-center'>
                                    {(documentList?.from || 1) + " - " + (documentList?.to || 1) + " of " + (documentList?.total || 1)}
                                    <div>
                                        <button className='border-0 bg-white pe-0' onClick={handlePrevPage} disabled={documentList?.current_page === 1}>
                                            <MdOutlineKeyboardArrowLeft size={25} />
                                        </button>
                                        <button className='border-0 bg-white ps-0' onClick={handleNextPage} disabled={documentList?.current_page === documentList?.last_page}>
                                            <MdOutlineKeyboardArrowRight size={25} />
                                        </button>
                                    </div>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    Shows
                                    <select className="pagination-prev disabled h-100 px-1 py-1" type="select" name="perPage" id="perPage"
                                        onChange={(e) => {
                                            setRowPerPage(e.target.value);
                                            getDocumentPage(1, e.target.value);
                                        }}
                                        value={rowPerPage} >
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={documentList?.total}>All</option>
                                    </select>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-light border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-7 col-md-3  px-2"  >
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>
                                        <div className="d-flex col-6 ml-0 col-md-2  mt-0 align-items-center  justify-content-start px-1">
                                            <h5 className="fs-15 mb-0">Category</h5>
                                        </div>
                                        <div className="d-flex   col-6 col-md-3  mt-0 align-items-center  justify-content-end  justify-content-md-start px-1">
                                            <h5 className="fs-15 mb-0">Description</h5>
                                        </div>
                                        <div className="d-flex col-6 ml-0 col-md-3  mt-0 align-items-center  justify-content-start px-1">
                                            <h5 className="fs-15 mb-0">Attachment</h5>
                                        </div>
                                        <div className="mb-1 col-12 col-md-1  d-flex gap-2 align-items-center justify-content-end">
                                            <h5 className="fs-15 mb-0">  Actions</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row gy-1 mb-1" id="documentList-list">
                        {loading ?
                            <div className='text-center'>
                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>
                            :
                            documentList?.data?.length !== 0 ?
                                (documentList?.data)?.map((item, index) => (
                                    <Col lg={12} key={index} className='mt-0' >
                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                            <CardBody className="px-2 py-0">
                                                <div className="d-flex align-items-center row px-2">
                                                    <div className="col-6 col-md-3 align-items-center px-1">
                                                        <p className="mb-0">  {item.name}</p>
                                                    </div>

                                                    <div className=" col-6 d-flex  d-md-none  gap-0 align-items-center justify-content-end px-0">
                                                        {item.document_attachments?.length > 0 ?
                                                            <button type="button" className="btn  btn-sm btn-icon "
                                                                onClick={(e) => { viewAttachment(item.id) }}>
                                                                <i className="ri-attachment-2 fs-18 primary"  ></i>
                                                            </button> : " "}
                                                            <button type="button" className="btn  btn-sm btn-icon "
                                                                onClick={(e) => {
                                                                    handleEditClick(item);
                                                                }}>
                                                                <HiOutlinePencilAlt className="fs-18 primary" />
                                                            </button>
                                                            <button type="button" className="btn btn-sm btn-icon "
                                                                onClick={(e) => {
                                                                    onClickData(item); setDocumentView(item);
                                                                }}>
                                                                <HiOutlineTrash className="fs-18 secondary" />
                                                            </button>
                                                    </div>

                                                    <div className="col-6 col-md-2 align-items-center px-1">
                                                        <p className="mb-0">  {item.document_category?.name}</p>
                                                    </div>


                                                    <div className={`col-12 col-md-3 d-flex justify-content-start px-1`}>
                                                        <p className="mb-0" key={index}>
                                                            {isExpanded?.[index] ? item?.description : item?.description?.length > 35 ? item?.description?.slice(0, 35) + "..." : item?.description}
                                                            {item?.description?.length > 35 && (
                                                                <a href="#" onClick={() => handleToggle(index)}>
                                                                    {isExpanded?.[index] ? ' Read Less' : ' Read More'}
                                                                </a>
                                                            )}
                                                        </p>
                                                    </div>

                                                    <div className={`col-12 col-md-3 px-1 d-none d-md-block`}>
                                                        {item.document_attachments?.length > 0 ?
                                                            (item.document_attachments)?.map((document, index) => (
                                                                <p className='mb-0' key={index}> {index + 1}: {document.name} </p>
                                                            )) : ""}
                                                    </div>

                                                    <div className="col-12 col-md-1  d-none  d-md-flex  gap-0 align-items-center justify-content-end px-0">
                                                        {item.document_attachments?.length > 0 ?
                                                            <button type="button" className="btn  btn-sm btn-icon "
                                                                onClick={(e) => { viewAttachment(item.id) }}>
                                                                <i className="ri-attachment-2 fs-18 primary"  ></i>
                                                            </button> : " "}
                                                            <button type="button" className="btn  btn-sm btn-icon "
                                                                onClick={(e) => {
                                                                    handleEditClick(item);
                                                                }}>
                                                                <HiOutlinePencilAlt className="fs-18 primary" />
                                                            </button>
                                                            <button type="button" className="btn btn-sm btn-icon "
                                                                onClick={(e) => {
                                                                    onClickData(item); setDocumentView(item);
                                                                }}>
                                                                <HiOutlineTrash className="fs-18 secondary" />
                                                            </button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                                :
                                <div className="noresult" >
                                    <div className="text-center">
                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                        {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                    </div>
                                </div>
                        }
                    </Row>




                    <div className="modal fade" id="showAttachment" tabIndex="-1" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered">
                            <Modal size="lg" isOpen={modalAttachment.show} toggle={() => {
                                deleteDocumentsAttachment(modalAttachment?.file?.[0]?.document_id);
                            }} centered>
                                <ModalHeader className='border border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0 pb-2' toggle={() => {
                                    deleteDocumentsAttachment(modalAttachment?.file?.[0]?.document_id);
                                }}  >
                                    {"View Attachment"}
                                </ModalHeader>

                                <ModalBody>
                                    {modalAttachment?.show ? <>
                                        <div className='text-center pb-2' >
                                            {imageExtensions.has(getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase()) ?
                                                <img
                                                    src={url + modalAttachment?.file?.[currentSlide]?.path}
                                                    alt={modalAttachment?.file?.[currentSlide]?.name}
                                                    width={isDesktop ? 500 : "100%"}
                                                    height={"100%"}
                                                />
                                                : pdfExtensions.has(getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase()) ?
                                                    <iframe src={url + modalAttachment?.file?.[currentSlide]?.path} className='iframe' />
                                                    : excelExtensions.has(getExtension(modalAttachment?.file?.[currentSlide]?.path)?.toLowerCase()) ?
                                                        <>  {modalAttachment?.file?.[currentSlide]?.name}</>
                                                        : <>  {modalAttachment?.file?.[currentSlide]?.name}</>
                                            }
                                        </div>
                                    </>
                                        : ""}


                                    {modalAttachment?.file?.[0]?.path !== undefined ?
                                        <div className='pb-2 text-center' >
                                            <button className="btn btn-primary shadow-lg">
                                                <a className="text-white" href={url + modalAttachment?.file?.[currentSlide]?.path} target='_blank' download>  Download</a>
                                            </button>
                                        </div>
                                        : ""}

                                    {modalAttachment?.file?.length > 1 ?
                                        <div className="d-flex justify-content-center">
                                            <div className="pagination-wrap hstack gap-2">
                                                <Link className="page-item pagination-prev " onClick={goToPreviousSlide}>
                                                    <FeatherIcon icon="arrow-left" size="20" />
                                                </Link>
                                                <ul className="pagination listjs-pagination mb-0"></ul>
                                                <Link className="page-item pagination-next" onClick={goToNextSlide}>
                                                    <FeatherIcon icon="arrow-right" size="20" />
                                                </Link>
                                            </div>
                                        </div>
                                    : ""}

                                </ModalBody>
                            </Modal>
                        </div>
                    </div>


                    {/* <div className="modal fade" tabIndex="-1" aria-hidden="true" >
                        <div className="modal-dialog modal-dialog-centered"    >
                            <Modal size="md" backdrop={"static"} isOpen={modalConfirm} toggle={() => {
                                setModalConfirm(!modalConfirm)
                            }} centered>

                                <ModalHeader className='bg-info-subtle p-3 bpBottom pb-2' toggle={() => {
                                    setModalConfirm(!modalConfirm)
                                }} >   {"Confirm"}  </ModalHeader>

                                <ModalBody >
                                    <p className='mb-0'> Are you sure you want to view? </p>
                                </ModalBody>

                                <ModalFooter>
                                    <div className="hstack gap-2 justify-content-end">
                                        <Button color="light" onClick={() => {
                                            setModalConfirm(false);
                                        }} > No </Button>
                                        <Button type="submit" color="success" id="add-btn" onClick={(e) => {
                                            requestDocumentOtp(ID)
                                        }} > Yes  </Button>
                                    </div>
                                </ModalFooter>
                            </Modal>
                        </div>
                    </div> */}



                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-light px-3 py-2" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2">{view === "Authentication" ?
                                    "Verify Authentication"
                                    : view === "Category" ?
                                        "Add Category"
                                        : !isEdit ? "Add Document" : "Update Document"}</span>
                                <span>
                                    {view !== "Authentication" && view !== "Category" && <span className="fs-18 px-1" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>
                                        <HiOutlineCheck className='cursor' /></span>}
                                    {view === "Category" && <>
                                        <span className="fs-18 px-1" onClick={() => { setSubmit(true); categoryValidation.handleSubmit(); }}>
                                            <HiOutlineCheck className='cursor' /></span>
                                        <span className="fs-20 cursor" onClick={() => { setView(""); }}>   <IoCloseOutline /> </span></>
                                    }

                                    {view !== "Category" && <span className="fs-18 cursor" onClick={() => { toggleDrawer() }}>   <IoCloseOutline /> </span>}
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="p-4">
                                    {view === "Authentication" ?
                                        <>
                                            {(validation?.values?.authentication_type === "Single-factor" || validation?.values?.authentication_type === "Two-factor" || validation?.values?.authentication_type === "Google") && !passwordVerified &&
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    showPasswordValidation.handleSubmit();
                                                    return false;
                                                }}>

                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="otp" className="form-label">Password</Label>
                                                                <Input type="password" className="form-control px-2 py-1" name='password'
                                                                    onChange={showPasswordValidation.handleChange}
                                                                    onBlur={showPasswordValidation.handleBlur}
                                                                    placeholder="Enter password"
                                                                    invalid={submit && showPasswordValidation.errors.password ? true : false}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col lg={12}>
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <Button color="success" type="submit" disabled={showPasswordValidation.isSubmitting ? true : false}
                                                                    className="btn btn-success px-2 py-1"  >
                                                                    {showPasswordValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                    {"Verify"}</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            }

                                            {validation?.values?.authentication_type === "Two-factor" && passwordVerified && !OTPVerfied &&
                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    otpValidation.handleSubmit();
                                                    return false;
                                                }}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="otp" className="form-label">OTP</Label>
                                                                <Input type="text" className="form-control px-2 py-1" id="otp" name='otp'
                                                                    onChange={otpValidation.handleChange}
                                                                    onBlur={otpValidation.handleBlur}
                                                                    value={otpValidation.values.otp || ""}
                                                                    placeholder="Enter OTP"
                                                                    invalid={otpValidation.touched.otp && otpValidation.errors.otp ? true : false}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg={12}>
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <Button color="success" type="submit" disabled={otpValidation.isSubmitting ? true : false}
                                                                    className="btn btn-success px-2 py-1"  >
                                                                    {otpValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                    {"Verify"}</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            }

                                            {validation?.values?.authentication_type === "Google" && passwordVerified && !codeVerified &&

                                                <Form onSubmit={(e) => {
                                                    e.preventDefault();
                                                    codeValidation.handleSubmit();
                                                    return false;
                                                }}>
                                                    <Row>
                                                        <Col lg={12}>
                                                            <div className="mb-3">
                                                                <Label htmlFor="totp" className="form-label">TOTP</Label>
                                                                <Input type="number" className="form-control px-2 py-1" id="totp" name='totp'
                                                                    onChange={(e) => {
                                                                        let inputValue = e.target.value;
                                                                        if (inputValue.length > 6) {
                                                                            inputValue = inputValue.slice(0, 6);
                                                                        }
                                                                        codeValidation.handleChange({
                                                                            target: {
                                                                                name: 'totp',
                                                                                value: inputValue,
                                                                            },
                                                                        });
                                                                    }}
                                                                    onBlur={codeValidation.handleBlur}
                                                                    value={codeValidation.values.totp || ""}
                                                                    placeholder="Enter TOTP"
                                                                    invalid={codeValidation.touched.totp && codeValidation.errors.totp ? true : false}
                                                                />
                                                            </div>
                                                        </Col>

                                                        <Col lg={12}>
                                                            <div className="hstack gap-2 justify-content-end">
                                                                <Button color="success" type="submit" disabled={codeValidation.isSubmitting ? true : false}
                                                                    className="btn btn-success px-2 py-1"  >
                                                                    {codeValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                                                    {"Verify"}</Button>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </Form>
                                            }
                                        </>


                                        : view === "Category" ?
                                            <FormDocumentCategory validation={categoryValidation} submit={submit} setSubmit={setSubmit} />
                                            :
                                            <Form onSubmit={(e) => {
                                                e.preventDefault();
                                                validation.handleSubmit();
                                                return false;
                                            }}>

                                                <Row>
                                                    {/* <Col xs={12} lg={6}>
                                                        <div className="mb-2">
                                                            <Label htmlFor="name" className="form-label">Name <span className='secondary'>*</span></Label>
                                                            <Input type="text" className="form-control px-2 py-1" id="name" placeholder="Enter name"
                                                                name='name'
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.name || ""}
                                                                invalid={submit && validation.errors.name ? true : false
                                                                }
                                                            />
                                                        </div>
                                                    </Col> */}

                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Row>
                                                                <Col lg={4}>
                                                                    <Label htmlFor="text" className="form-label">Name <span className='secondary'>*</span></Label>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <Input type="text" className="form-control px-2 py-1 rounded-0 border-top-0 border-end-0 border-start-0 border-bottom-1" id="name" name='name'
                                                                        placeholder="Enter name"
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        defaultValue={validation.values.name || ""}
                                                                        invalid={
                                                                            submit && validation.errors.name ? true : false
                                                                        }
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>


                                                    {/* <Col xs={10} lg={5}>
                                                        <div className="mb-2">
                                                            <Label htmlFor="category" className="form-label">Category <span className='secondary'>*</span></Label>
                                                            <Select
                                                                defaultValue={
                                                                    validation.values.document_category?.name ? {
                                                                        "value": validation.values.document_category_id,
                                                                        "label": validation.values.document_category?.name
                                                                    } : ""}

                                                                options={
                                                                    [
                                                                        { value: "", label: "Select" },
                                                                        ...categoryList?.map((item, index) => {
                                                                            return (
                                                                                { value: item.id, label: item.name }
                                                                            );
                                                                        })

                                                                    ]
                                                                }

                                                                placeholder="Select..."
                                                                name='document_category_id'
                                                                onChange={(e) => {
                                                                    validation.setValues({
                                                                        ...validation.values,
                                                                        document_category_id: e?.value
                                                                    });
                                                                }}

                                                                styles={selectStyle('document_category_id')}
                                                            />

                                                        </div>

                                                    </Col> */}


                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Row>
                                                                <Col lg={4}>
                                                                    <Label htmlFor="text" className="form-label">Category <span className='secondary'>*</span></Label>
                                                                </Col>
                                                                <Col xs={10} lg={7}>
                                                                    <Select
                                                                        name='document_category_id'
                                                                        onChange={(e) => {
                                                                            validation.setValues({
                                                                                ...validation.values,
                                                                                document_category_id: e?.value
                                                                            });
                                                                        }}
                                                                        defaultValue={
                                                                            validation.values.document_category?.name ? {
                                                                                "value": validation.values.document_category_id,
                                                                                "label": validation.values.document_category?.name
                                                                            } : ""}
                                                                        options={[
                                                                            { value: "", label: "Select" },
                                                                            ...categoryList?.map((item, index) => {
                                                                                return (
                                                                                    { value: item.id, label: item.name }
                                                                                );
                                                                            })
                                                                        ]}
                                                                        styles={selectStyle('document_category_id')}
                                                                    />
                                                                </Col>

                                                                <Col xs={2} lg={1} className='d-flex align-items-center'>
                                                                    <GoPlusCircle className="cursor primary" size={20} style={{ minWidth: "20px" }} onClick={(e) => {
                                                                        categoryValidation.resetForm();
                                                                        setView("Category");
                                                                    }} />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>


                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Row>
                                                                <Col lg={4}>
                                                                    <Label htmlFor="authentication_type" className="form-label">Authentication Type  <span className='secondary'>*</span> </Label>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <Select
                                                                        name='authentication_type'
                                                                        onChange={(e) => {
                                                                            validation.setValues({
                                                                                ...validation.values,
                                                                                authentication_type: e?.value
                                                                            });
                                                                        }}
                                                                        defaultValue={validation.values.authentication_type ? {
                                                                            value: validation.values.authentication_type,
                                                                            label: validation.values.authentication_type + " Authentication"
                                                                        } : ""}
                                                                        options={[
                                                                            { value: "Single-factor", label: "Single-factor Authentication" },
                                                                            { value: "Two-factor", label: "Two-factor Authentication" },
                                                                            { value: "Google", label: "Google Authentication" }
                                                                        ]}
                                                                        styles={selectStyle('authentication_type')}
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    {/* <Col lg={12}>
                                                        <div className="mb-2">
                                                            <Label htmlFor="description" className="form-label">Description </Label>
                                                            <Input type="textarea" className="form-control px-2 py-1" id="description" placeholder="Enter description"
                                                                name='description'
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.description || ""}
                                                                invalid={submit && validation.errors.description ? true : false}
                                                                maxLength={250}
                                                                //   style={{ height: userDetails?.roles?.[0]?.name === "Admin" ?'165px' : "100px", minHeight: '50px', maxHeight: userDetails?.roles?.[0]?.name === "Admin" ?'165px' : "100px", }} 
                                                                multiline="true"
                                                            />
                                                        </div>
                                                    </Col> */}


                                                    <Col lg={12}>
                                                        <div className="mb-3">
                                                            <Row>
                                                                <Col lg={4}>
                                                                    <Label htmlFor="text" className="form-label">Description</Label>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <Input type="textarea" className="form-control px-2 py-1 rounded-0 border-top-0 border-end-0 border-start-0 border-bottom-1"
                                                                        id="description" name='description'
                                                                        onChange={validation.handleChange}
                                                                        onBlur={validation.handleBlur}
                                                                        value={validation.values.description || ""}
                                                                        invalid={submit && validation.errors.description ? true : false}
                                                                        maxLength={250}
                                                                        //   style={{ height: userDetails?.roles?.[0]?.name === "Admin" ?'165px' : "100px", minHeight: '50px', maxHeight: userDetails?.roles?.[0]?.name === "Admin" ?'165px' : "100px", }} 
                                                                        multiline="true"
                                                                    />
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>

                                                    {!isEdit && <Col lg={12}>
                                                        <div className="mb-2">
                                                            {/* <Label htmlFor="encrypt" className="form-label">Encrypt  </Label>
                                                            <Input type="checkbox" className="form-control px-2 py-1" name='encrypt'
                                                                onChange={(e) => {
                                                                    validation.handleChange({
                                                                        target: {
                                                                            name: 'encrypt',
                                                                            value: e.target.checked,
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={validation.handleBlur}
                                                                checked={validation.values.encrypt}
                                                                value={validation.values.encrypt || ""}
                                                                invalid={submit && validation.errors.encrypt ? true : false}
                                                            /> */}


                                                            {/* <FormGroup check>
                                                                <Input
                                                                    id="encrypt"
                                                                    name="encrypt"
                                                                    type="checkbox"
                                                                    onChange={(e) => {

                                                                        validation.handleChange({
                                                                            target: {
                                                                                name: 'encrypt',
                                                                                value: e.target.checked,
                                                                            },
                                                                        });
                                                                    }}
                                                                    onBlur={validation.handleBlur}
                                                                    checked={validation.values.encrypt || false}
                                                                    //  defaultValue={validation.values.encrypt || ""}
                                                                    invalid={submit && validation.errors.encrypt ? true : false}
                                                                />
                                                                <Label check for="encrypt" >   Encrypt </Label>
                                                            </FormGroup> */}


                                                            <Row>
                                                                <Col lg={4}>
                                                                    <Label htmlFor="text" className="form-label">Encrypt</Label>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <FormGroup check>
                                                                        <Input
                                                                            id="encrypt"
                                                                            name="encrypt"
                                                                            type="checkbox"
                                                                            onChange={(e) => {
                                                                                validation.handleChange({
                                                                                    target: {
                                                                                        name: 'encrypt',
                                                                                        value: e.target.checked,
                                                                                    },
                                                                                });
                                                                            }}
                                                                            onBlur={validation.handleBlur}
                                                                            checked={validation.values.encrypt || false}
                                                                            //  defaultValue={validation.values.encrypt || ""}
                                                                            invalid={submit && validation.errors.encrypt ? true : false}
                                                                        />
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </div>
                                                    </Col>}

                                                    <Col lg={12} className='py-2'>
                                                        <div className="mb-3">
                                                            <Row>
                                                                <Col lg={4}>
                                                                    <Label htmlFor="attachment" className="form-label">Upload Attachment  <span className='secondary'>*</span></Label>
                                                                </Col>
                                                                <Col lg={8}>
                                                                    <Input type="file" className="form-control px-2 py-1" id="attachment"
                                                                        name='attachment'
                                                                        onChange={(e) => {
                                                                            handleFileChange(e);
                                                                            let inputValue = e.target.value;
                                                                            if (inputValue.length > 10) {
                                                                                inputValue = inputValue.slice(0, 10);
                                                                            }
                                                                            validation.handleChange({
                                                                                target: {
                                                                                    name: 'attachment',
                                                                                    value: files,
                                                                                },
                                                                            });
                                                                        }}
                                                                        invalid={submit && validation.errors.attachment ? true : false}
                                                                        multiple />
                                                                </Col>
                                                            </Row>
                                                        </div>

                                                        {/* <div className="mb-2">
                                                            <Label htmlFor="attachment" className="form-label">Upload Attachment  <span className='secondary'>*</span></Label>
                                                            <Input type="file" className="form-control px-2 py-1" id="attachment"
                                                                name='attachment'
                                                                onChange={(e) => {
                                                                    handleFileChange(e);
                                                                    let inputValue = e.target.value;
                                                                    if (inputValue.length > 10) {
                                                                        inputValue = inputValue.slice(0, 10);
                                                                    }

                                                                    validation.handleChange({
                                                                        target: {
                                                                            name: 'attachment',
                                                                            value: files,
                                                                        },
                                                                    });
                                                                }}

                                                                invalid={submit && validation.errors.attachment ? true : false}
                                                                multiple />
                                                        </div> */}

                                                        <Row className='py-2'>
                                                            {(files || [])?.map((item, index) => {
                                                                return [
                                                                    <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >
                                                                        <FaTimesCircle className="close-button" onClick={(e) => {
                                                                            setFiles(
                                                                                files.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                                                            );
                                                                            setFile(
                                                                                file.filter((selectedItem) => selectedItem.preview !== item?.preview.toString())
                                                                            );

                                                                        }} />
                                                                        <div className="selected-image cursor" key={index} onClick={(e) => {
                                                                            setModalFile(true); setFileUrl({ name: item.name, path: item.preview });
                                                                        }}>
                                                                            {imageExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                                                                <img
                                                                                    src={item.preview}
                                                                                    alt={item.name}
                                                                                    width={100}
                                                                                    height={80}
                                                                                />
                                                                                : pdfExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                                                                    <FaFilePdf size={30} />
                                                                                    : excelExtensions.has(getExtension(item.name)?.toLowerCase()) ?
                                                                                        <FaFileExcel size={30} />
                                                                                        : <FaFileAlt size={30} />

                                                                            }
                                                                        </div>
                                                                    </Col>
                                                                ]
                                                            })}

                                                            {validation.values.encrypt === 1 ?
                                                                attachmentList?.map((item, index) => {
                                                                    return [
                                                                        <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >
                                                                            <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                                                                handleDelete(item.id);
                                                                            }} />
                                                                            <div className="selected-image" key={index} onClick={(e) => {
                                                                                // setModalFile(true);
                                                                                // setFileUrl({ name: item.path, path: url + item.path });
                                                                                viewAttachment(validation.values.id);
                                                                            }} >
                                                                                {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                                                    <FaImage size={30} />
                                                                                    : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                                                        <FaFilePdf size={30} />
                                                                                        : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                                                            <FaFileExcel size={30} />
                                                                                            : <FaFileAlt size={30} />
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    ]
                                                                })
                                                                :
                                                                attachmentList?.map((item, index) => {
                                                                    return [

                                                                        <Col xs={6} sm={3} key={index} className='d-flex align-items-center justify-content-center p-2' >

                                                                            <FaTimesCircle className="close-button" size='20' onClick={(e) => {
                                                                                handleDelete(item.id);
                                                                            }} />

                                                                            <div className="selected-image cursor" key={index} onClick={(e) => {
                                                                                setModalFile(true);

                                                                                setFileUrl({ name: item.path, path: url + item.path });
                                                                            }} >

                                                                                {imageExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                                                    <img
                                                                                        src={url + item.path}
                                                                                        alt={item.name}
                                                                                        width={100}
                                                                                        height={80}
                                                                                    />
                                                                                    : pdfExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                                                        <FaFilePdf size={30} />
                                                                                        : excelExtensions.has(getExtension(item.path)?.toLowerCase()) ?
                                                                                            <FaFileExcel size={30} />
                                                                                            : <FaFileAlt size={30} />
                                                                                }
                                                                            </div>
                                                                        </Col>
                                                                    ]
                                                                })
                                                            }
                                                        </Row>
                                                    </Col>

                                                    <div className="modal fade" tabIndex="-1" aria-hidden="true">
                                                        <div className="modal-dialog modal-dialog-centered">
                                                            <Modal size="lg" isOpen={modalFile} toggle={() => {
                                                                setModalFile(false);
                                                            }} centered>
                                                                <ModalHeader className='border border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0 pb-2' toggle={() => { setModalFile(false); }}  >
                                                                    {"View Attachment"}
                                                                </ModalHeader>
                                                                <ModalBody >
                                                                    {modalFile && <>
                                                                        <div className='text-center py-4' >
                                                                            {imageExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                                                                <img
                                                                                    src={fileUrl.path}
                                                                                    alt={fileUrl.name}
                                                                                    width={isDesktop ? 500 : "100%"}
                                                                                    height={"100%"}
                                                                                />
                                                                                : pdfExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                                                                    <iframe src={fileUrl.path} className='iframe' />
                                                                                    : excelExtensions.has(getExtension(fileUrl.name)?.toLowerCase()) ?
                                                                                        <>  {fileUrl.name}</>
                                                                                        : <>  {fileUrl.name}</>
                                                                            }
                                                                        </div>
                                                                    </>
                                                                    }


                                                                    <div className='pt-2 text-center' >
                                                                        <button className="btn btn-primary shadow-lg">
                                                                            <a className="text-white" href={fileUrl.path} target='_blank' download>  Download</a>
                                                                        </button>
                                                                    </div>
                                                                </ModalBody>
                                                            </Modal>
                                                        </div>
                                                    </div>



                                                    <Col lg={12}>
                                                        <div className="hstack gap-2 justify-content-end">
                                                            <button type="submit" className="btn btn-primary" onClick={() => { setSubmit(true); }}>
                                                                {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Save"}</button>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </Form>
                                    }

                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Document;