import React, { useEffect, useState } from 'react';
import withRouter from '../../Components/Common/withRouter';
import { api, url } from "../../helpers/api";
import { ToastMessage } from "../../Components/Hooks/Function";

const ParticlesAuth = ({ children }) => {

    const [settingsList, setSettingsList] = useState([]);
    const getSettings = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('settingsDetails', {});
            if (response.data.status === true) {
                setSettingsList(response.data?.data);
                let link = document.querySelector("link[rel~='icon']");
                if (!link) {
                    link = document.createElement('link');
                    link.rel = 'icon';
                    document.getElementsByTagName('head')[0].appendChild(link);
                }
                link.href = url + response.data?.data?.filter(type => type?.name === "icon")[0]?.value;

            } else {

                setSettingsList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        const changeLogo = () => {
            getSettings();
        };

        getSettings();

        window.addEventListener("changeLogo", changeLogo);
        return () => {
            window.removeEventListener("changeLogo", changeLogo);
        };
    }, []);

    return (
        <React.Fragment>
            <div className="auth-page-wrapper pt-5">
                <div className="auth-one-bg-position auth-one-bg" id="auth-particles">

                    <div className="bg-overlay"></div>

                    <div className="shape">
                        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
                            <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
                        </svg>
                    </div>
                </div>

                {/* pass the children */}
                {children}

                <footer className="footer">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="text-center">
                                    <p className="mb-0 text-muted">
                                    
                                    &copy; {" 2024"}, Design & Develop by {settingsList.filter(type => type?.name === "name")[0]?.value} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </React.Fragment>
    );
};

export default withRouter(ParticlesAuth);