import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import { getLoggedinUser } from "../helpers/api_helper";

const Navdata = () => {
    const history = useNavigate();
    const userDetails = getLoggedinUser()?.user;
    //state data
    const [isDashboard, setIsDashboard] = useState(false);
    const [isPassword, setIsPassword] = useState(false);
    const [isSettings, setIsSettings] = useState(false);
    const [isDocuments, setIsDocuments] = useState(false);
    const [isUsers, setIsUsers] = useState(false);

    const [iscurrentState, setIscurrentState] = useState('Dashboard');

    function updateIconSidebar(e) {
        if (e && e.target && e.target.getAttribute("subitems")) {
            const ul = document.getElementById("two-column-menu");
            const iconItems = ul.querySelectorAll(".nav-icon.active");
            let activeIconItems = [...iconItems];
            activeIconItems.forEach((item) => {
                item.classList.remove("active");
                var id = item.getAttribute("subitems");
                if (document.getElementById(id))
                    document.getElementById(id).classList.remove("show");
            });
        }
    }

    useEffect(() => {
        document.body.classList.remove('twocolumn-panel');
        if (iscurrentState !== 'Dashboard') {
            setIsDashboard(false);
        }
        if (iscurrentState !== 'Passwords') {
            setIsPassword(false);
        }

        if (iscurrentState !== 'Settings') {
            setIsSettings(false);
        }

        if (iscurrentState !== 'Documents') {
            setIsDocuments(false);
        }

        if (iscurrentState !== 'Users') {
            setIsUsers(false);
        }

    }, [
        history,
        iscurrentState,
        isDashboard,
        isUsers,
        isPassword,
        isSettings,
        isDocuments,
    ]);

    const menuItems = [
        {
            id: "dashboard",
            label: "Dashboards",
            icon: <FeatherIcon icon="grid" className="icon-dual" />,
            link: "/#",
            view: true,
            stateVariables: isDashboard,
            click: function (e) {
                e.preventDefault();
                setIsDashboard(!isDashboard);
                setIscurrentState('Dashboard');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "dashboard",
                    label: "Dashboard",
                    link: "/dashboard",
                    view: true,
                    parentId: "dashboard",
                },
            ],
        },
        {
            id: "isPassword",
            label: "Passwords",
            icon: <FeatherIcon icon="key" className="icon-dual" />,
            link: "/#",
            view: true,
            click: function (e) {
                e.preventDefault();
                setIsPassword(!isPassword);
                setIscurrentState('Passwords');
                updateIconSidebar(e);
            },
            stateVariables: isPassword,
            subItems: [
                {
                    id: "password-categories",
                    label: "Categories",
                    link: "/password-categories",
                    view: true,
                    parentId: "isPassword",
                },
                {
                    id: "passwords",
                    label: "Passwords",
                    link: "/passwords",
                    view: true,
                    parentId: "isPassword",
                },
            ],
        },
        {
            id: "isDocuments",
            label: "Documents",
            icon: <FeatherIcon icon="file-text" className="icon-dual" />,
            link: "/#",
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            click: function (e) {
                e.preventDefault();
                setIsDocuments(!isDocuments);
                setIscurrentState('Documents');
                updateIconSidebar(e);
            },
            stateVariables: isDocuments,
            subItems: [
                {
                    id: "documents",
                    label: "Categories",
                    link: "/document-categories",
                    view: true,
                    parentId: "isDocuments",
                },
                {
                    id: "documents",
                    label: "Documents",
                    link: "/documents",
                    view: true,
                    parentId: "isDocuments",
                },
            ],
        },
        
        {
            id: "users",
            label: "Users",
            icon: <FeatherIcon icon="user" className="icon-dual" />,
            link: "/#",
            view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
            stateVariables: isUsers,
            click: function (e) {
                e.preventDefault();
                setIsUsers(!isUsers);
                setIscurrentState('Users');
                updateIconSidebar(e);
            },
            subItems: [
                {
                    id: "users",
                    label: "User",
                    link: "/users",
                    view: true,
                    parentId: "users",
                },
            ],
        },

        {
            id: "settings",
            label: "Settings",
            icon: <FeatherIcon icon="settings" className="icon-dual" />,
            link: "/#",
            view: true,
            click: function (e) {
                e.preventDefault();
                setIsSettings(!isSettings);
                setIscurrentState('Settings');
                updateIconSidebar(e);
            },
            stateVariables: isSettings,
            subItems: [
                {
                    id: "settings",
                    label: `Settings`,
                    link: "/settings",
                    view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
                    parentId: "pages",
                },

                {
                    id: "google-authenticator",
                    label: "Google Authenticator",
                    link: "/google-authenticator",
                    view: true,
                    parentId: "settings",
                },
                {
                    id: "mail-settings",
                    label: "Mail Settings",
                    link: "/mail-settings",
                    view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
                    parentId: "settings",
                },
                {
                    id: "sms-settings",
                    label: "SMS Settings",
                    link: "/sms-settings",
                    view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
                    parentId: "settings",
                },
                {
                    id: "firebase-settings",
                    label: "Firebase Settings",
                    link: "/firebase-settings",
                    view: userDetails?.roles?.[0]?.name === "Admin" ? true : false,
                    parentId: "settings",
                },

            ],
        },
    ];
    return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;