import React, { useState, useEffect, useCallback } from 'react';
import {
    Card, CardBody, Spinner, Col, Form, Input, Label, Row,
} from 'reactstrap';

import { api, url } from "../../helpers/api";
import Select from "react-select";

import { GoPlusCircle } from "react-icons/go";
import { CiCircleMinus } from "react-icons/ci";

import { ToastMessage, DefaultDate, SmallDateFormat, FormatDate, snakeToCamel } from "../../Components/Hooks/Function";

const FormPasswordCategory = ({ validation, submit, setSubmit, customFields, setCustomFields }) => {


    const checkCategoryField = async (id, column_id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('checkCategoryField/' + id + "/" + column_id, {});
            if (response.data.status === true) {
                return response.data.data;
            } else {
                return 0;
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const handleAddInput = () => {
        setCustomFields([...customFields, { name: '', type: 'Text', mandatory: false }]);
    };

    const handleRemoveInput = async (index) => {
        if (customFields[index]?.id) {
            if (await checkCategoryField(customFields[index]?.password_category_id, customFields[index]?.id) === 1) {
                setDeleteFieldModal(true);
            } else {
                await deleteCategoryField(customFields[index]?.id);
                const newCustomFields = [...customFields];
                newCustomFields.splice(index, 1);
                setCustomFields(newCustomFields);
            }
        } else {
            const newCustomFields = [...customFields];
            newCustomFields.splice(index, 1);
            setCustomFields(newCustomFields);
        }
    };

    const deleteCategoryField = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('categoryFieldRemove/' + id, {});
            if (response.data.status === true) {
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };


    const handleCustomFieldsChange = (index, name, value) => {
        const newCustomFields = [...customFields];
        newCustomFields[index][name] = value;
        setCustomFields(newCustomFields);
    };

    const selectStyle = (fieldName) => {
        return {
            control: (baseStyles, state) => ({
                ...baseStyles,
                height: '30px',
                width: '120px',
                minHeight: '30px',
                padding: '0px',
                borderColor: state.isFocused ? 'grey' : submit && validation.errors[fieldName] ? "#f06548" : '#ced4da'
            }),
            option: (provided, state) => ({
                ...provided,
                background: state.isFocused ? ' ' : ' ',
            }),
            groupHeading: (provided) => ({
                fontWeight: 'bold',
                color: '#019aff',
                paddingLeft: '10px',
            }),
            valueContainer: (provided, state) => ({
                ...provided,
                height: '30px',
                padding: '0 6px'
            }),
            input: (provided, state) => ({
                ...provided,
                margin: '0px',
            }),
            indicatorSeparator: state => ({
                display: 'none',
            }),
            indicatorsContainer: (provided, state) => ({
                ...provided,
                height: '30px',
            }),
            indicatorContainer: (provided, state) => ({
                ...provided,
                padding: state.isFocused ? '4px' : '0px',
            }),
            singleValue: (provided, state) => ({
                ...provided,
                marginTop: '-4px',
            }),
        }
    }

    const passwordOption = customFields?.filter(item => item.type === "Password").length === 0 ? [{ value: "Password", label: "Password" }] : [];


    return (

        <Form onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
        }}>
            <Row>
                <Col lg={12}>
                    <div className="mb-3">
                        <Label htmlFor="category_name" className="form-label">Category Name  <span className='secondary'>*</span></Label>
                        <Input type="text" className="form-control px-2 py-1" id="category_name" name='category_name'
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            value={validation.values.category_name || ""}
                            invalid={
                                submit && validation.errors.category_name ? true : false
                            }
                        />
                    </div>
                </Col>


                <Col lg={12} className='py-2'>
                    <div className="mb-2">
                        <Card className="mb-0 d-flex mt-1 px-1 table-light border-0  rounded-0">
                            <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                <div className="row d-none d-md-flex  align-items-center px-0 py-2">
                                    <div className="col-8 col-md-8  px-2"  >
                                        <h5 className="fs-15 mb-0"> Fields</h5>
                                    </div>
                                    <div className="d-flex col-3 col-md-3  mt-0 align-items-center  justify-content-end  justify-content-md-start">
                                        <h5 className="fs-15 mb-0">Mandatory</h5>
                                    </div>
                                    <div className="col-6 col-md-1 d-flex gap-2 align-items-center justify-content-end  ">
                                        <h5 className="fs-15 mb-0">  Actions</h5>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>

                        {customFields.map((item, index) => {
                            return (
                                <Card className="mb-0 d-flex mt-1 px-1 table-light border-0  rounded-0" key={index}>
                                    <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">

                                        {item.type == "Password" ?
                                            <div className="row d-flex align-items-center  px-0 py-2 ">
                                                <div className="col-12 col-md-8  px-2"  >
                                                    <div className='d-flex gap-2'>
                                                        <Input type="text" className="form-control px-2 py-1" placeholder="Enter name" name='name'
                                                            defaultValue={snakeToCamel(item.name)}
                                                            readOnly={true}
                                                            disabled={true}

                                                            onChange={(e) => handleCustomFieldsChange(index, "name", e.target.value)}
                                                        />
                                                        <Select
                                                            name='type'
                                                            isDisabled={true}
                                                            onChange={(e) => handleCustomFieldsChange(index, "type", e?.value)}
                                                            defaultValue={item.type ? { value: item.type, label: item.type } : { value: "Text", label: "Text" }}
                                                            options={[
                                                                { value: "Password", label: "Password" }
                                                            ]}
                                                            styles={selectStyle(item.name)}
                                                        />

                                                        <Input type="checkbox" className="form-control px-2 py-1 d-flex d-md-none" name='mandatory'
                                                            checked={item.mandatory}
                                                            onChange={(e) => handleCustomFieldsChange(index, "mandatory", e.target.checked)}
                                                        />

                                                    </div>
                                                </div>
                                                <div className="d-flex col-9 col-md-2 mt-0 align-items-center d-none d-md-flex  justify-content-end justify-content-md-start">
                                                    <Input type="checkbox" className="form-control px-2 py-1" name='mandatory'
                                                        checked={item.mandatory}
                                                        onChange={(e) => handleCustomFieldsChange(index, "mandatory", e.target.checked)}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-2 pt-2 pt-md-0 d-flex gap-2 align-items-center justify-content-end">
                                                    <GoPlusCircle className="cursor primary" size={20} style={{ minWidth: "20px" }} onClick={handleAddInput} />

                                                </div>
                                            </div>

                                            :
                                            <div className="row d-flex align-items-center  px-0 py-2 ">
                                                <div className="col-12 col-md-8  px-2"  >
                                                    <div className='d-flex gap-2'>
                                                        <Input type="text" className="form-control px-2 py-1" placeholder="Enter name" name='name'
                                                            defaultValue={snakeToCamel(item.name)}
                                                            onChange={(e) => handleCustomFieldsChange(index, "name", e.target.value)}
                                                        />
                                                        <Select
                                                            name='type'
                                                            onChange={(e) => handleCustomFieldsChange(index, "type", e?.value)}
                                                            defaultValue={item.type ? { value: item.type, label: item.type } : { value: "Text", label: "Text" }}
                                                            options={[
                                                                { value: "Text", label: "Text" },
                                                                ...passwordOption,
                                                                { value: "File", label: "File" },
                                                            ]}
                                                            styles={selectStyle(item.name)}
                                                        />

                                                        <Input type="checkbox" className="form-control px-2 py-1 d-flex d-md-none" name='mandatory'
                                                            checked={item.mandatory}
                                                            onChange={(e) => handleCustomFieldsChange(index, "mandatory", e.target.checked)}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="d-flex col-9 col-md-2 mt-0 align-items-center d-none d-md-flex justify-content-end justify-content-md-start">
                                                    <Input type="checkbox" className="form-control px-2 py-1" name='mandatory'
                                                        checked={item.mandatory}
                                                        onChange={(e) => handleCustomFieldsChange(index, "mandatory", e.target.checked)}
                                                    />
                                                </div>

                                                <div className="col-12 col-md-2 pt-2 pt-md-0 d-flex gap-2 align-items-center justify-content-end">
                                                    <GoPlusCircle className="cursor primary" size={20} style={{ minWidth: "20px" }} onClick={handleAddInput} />
                                                    <CiCircleMinus className="cursor secondary" size={20} style={{ minWidth: "20px" }} onClick={() => customFields?.length > 1 ? handleRemoveInput(index) : ""} />
                                                </div>
                                            </div>
                                        }


                                    </CardBody>
                                </Card>
                            );
                        })}
                    </div>
                </Col>

                <Col lg={12}>
                    <div className="hstack gap-2 justify-content-end">
                        <button type="submit" className="btn btn-primary" onClick={() => { setSubmit(true); }}>
                            {validation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Save"}</button>
                    </div>
                </Col>
            </Row>
        </Form>
    );
}

export default FormPasswordCategory;

