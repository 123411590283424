import React, { useState, useEffect, useCallback } from 'react';
import {
    Card, CardBody, Col, Container, FormGroup, Offcanvas, OffcanvasHeader, Spinner, Button,
    OffcanvasBody, Form, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import { useMediaQuery } from 'react-responsive';
import { HiOutlineCheck } from "react-icons/hi2";
import { IoCloseOutline } from "react-icons/io5";
import { HiOutlinePencilAlt } from "react-icons/hi";
import { ToastMessage } from "../../Components/Hooks/Function";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import QRCode from 'qrcode.react';
import { getFirebaseBackend } from "../../helpers/firebase_helper";

const GoogleAuthenticator = (props) => {
    document.title = "Mail Setting";
    const userDetails = getLoggedinUser()?.user;
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [settingList, setSettingList] = useState([]);
    // const [searchList, setSearchList] = useState([]);
    // const [currentPage, setCurrentPage] = useState(1);
    // const [rowPerPage, setRowPerPage] = useState(50);
    const [decryptedValue, setDecryptedValue] = useState([]);
    // const [passwordShow, setPasswordShow] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);
    const [qrCodeData, setQRCodeData] = useState("");
    const [secretData, setSecretData] = useState("");
    const [submit, setSubmit] = useState(false);
    const [Index, setIndex] = useState(null);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleEditClick = useCallback((arg) => {
        setCodeVerified(false);
        setIndex("");
        reGenerateTOTP();
        setIsEdit(true);
    }, []);

    const showPasswordValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            password: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password")
        }),
        onSubmit: async (values) => {
            try {
                await KeyDecrypt(values);
            } catch (err) {
                console.error(err.message);
            }
        }
    });


    const KeyDecrypt = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`KeyDecrypt/${settingList?.[Index]?.id}`, {
                ...values,
                email: userDetails.email
            });
            if (response.data.status === true) {
                const newdecryptedValue = [...decryptedValue];
                newdecryptedValue[Index] = response.data.data;
                setDecryptedValue(newdecryptedValue);
            } else {

                ToastMessage("error", "Incorrect password");
            }
        } catch (err) {
            console.error(err.message);
            ToastMessage("error", err.message);
        }
    }

    const getUserData = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`userDetails`, {});
            if (response.data.status === true) {
                setSettingList([response.data?.data]);
            } else {
                setSettingList([]);

                ToastMessage("error", response.data?.message);
            }
            setLoading(false);

        } catch (err) {
            console.error(err.message);
        }

    };

    // const viewSetting = async (id) => {
    //     api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    //     api.defaults.headers.common['Content-Type'] = 'application/json'
    //     try {
    //         const response = await api.get('mailSetting/' + id, {});
    //         if (response.data.status === true) {
    //             validation.setValues(response.data?.data);
    //         }
    //         setOpen(true);
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // };


    // const editSettings = async (values, id) => {
    //     api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
    //     api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
    //     try {
    //         const formData = new FormData();
    //         formData.append('_method', "PUT");
    //         formData.append('text', values.text);
    //         formData.append('value', values.value);
    //         const response = await api.post('mailSetting/' + id, formData);
    //         if (response.data.status === true) {
    //             getUserData();
    //             validation.resetForm();
    //             setIsEdit(false);
    //             setOpen(false);
    //             ToastMessage("success", response.data?.message);
    //         } else {
    //             ToastMessage("error", response.data?.message);
    //         }
    //     } catch (err) {
    //         console.error(err.message);
    //     }
    // };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            text: '',
            value: '',
        },
        validationSchema: Yup.object({
            text: Yup.string().required("Please Enter"),
            value: Yup.string().notRequired(),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editSettings(values, validation.values?.id);
            }
        },
    });



    useEffect(() => {
        getUserData();
    }, []);

    useEffect(() => {
        setDecryptedValue(settingList?.map(() => ""))
    }, [settingList]);


    const handleAddClick = () => {
        generateTOTP();
        
        setQRCodeData("");
        setCodeVerified(false);
        codeValidation.resetForm();
    };

    const codeValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            secretData: secretData,
            totp: '',
        },
        validationSchema: Yup.object({
            totp: Yup.string().required("Please enter your email")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                // if (isEdit) {
                //     await TOTPVerificationID(values, validation.values.id);
                // } else {
                //     await TOTPVerification(values);
                // }

                await TOTPVerification(values);
                
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });


    const generateTOTP = async () => {
        try {
            const response = await api.post('generateTOTP', {
                website_name: validation.values.website_name
            });

            setQRCodeData(response.data.qrImageUrl);
            setSecretData(response.data.secret);
            toggleDrawer();

        } catch (error) {
            console.error('Error generating OTP:', error);
        }
    };


    const reGenerateTOTP = async () => {
        try {
            const response = await api.post('reGenerateTOTP', {});
            setQRCodeData(response.data.qrImageUrl);
            setSecretData(response.data.secret);
            setOpen(true);
            codeValidation.resetForm();

        } catch (error) {
            console.error('Error generating OTP:', error);
        }
    };



    const TOTPVerification = async (values) => {
        window.dispatchEvent(new Event("changeFirebaseConfig"));
        try {
            const response = await api.post('TOTPVerification', values);
            if (response.data.status === true) {

                await socialLogin("google", values);

                ToastMessage("success", response.data?.message);
            } else {
                setCodeVerified(false);
                ToastMessage("error", response.data?.message);
            }
        } catch (error) {
            console.error('Error generating OTP:', error);
        }
    };


    const TOTPVerificationSave = async (values) => {
        try {
            const response = await api.post('TOTPVerificationSave', values);
            if (response.data.status === true) {
                getUserData();
                setCodeVerified(true);
                codeValidation.resetForm();
                setOpen(false);
                setSubmit(false);
                ToastMessage("success", response.data?.message);
            } else {
                setCodeVerified(false);
                ToastMessage("error", response.data?.message);
            }
        } catch (error) {
            console.error('Error generating OTP:', error);
        }
    };


    // const TOTPVerificationID = async (values) => {
    //     try {
    //         const response = await api.post(`TOTPVerificationID`, values);
    //         if (response.data.status === true) {
    //             await socialLogin("google", values);

    //             ToastMessage("success", response.data?.message);
    //         } else {
    //             setCodeVerified(false);
    //             ToastMessage("error", response.data?.message);
    //         }
    //     } catch (error) {
    //         console.error('Error generating OTP:', error);
    //     }
    // };

    const socialLogin = async (type, values) => {
      
        try {
            let response;

            const fireBaseBackend = getFirebaseBackend();
            response = fireBaseBackend.socialLoginUser(type);

           
            const socialdata = await response;
            if (socialdata) {
                if (socialdata?.email === userDetails?.email) {
                    TOTPVerificationSave({
                        ...values,
                        accessToken: socialdata?.multiFactor?.user?.accessToken
                    });

                   

                } else {
                    ToastMessage("error", "Email verification failed");
                }
            }
        } catch (error) {

        }
    }

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0">Google Authenticator</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className='d-block d-md-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center  justify-content-between justify-content-md-end gap-2'>
                                {(settingList?.[0]?.key === null || settingList?.[0]?.key === undefined || settingList?.[0]?.key === "") && <Button type="button" color="primary" outline className="py-1" onClick={() => { handleAddClick(); }} >  ADD  </Button>}

                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-light border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row align-items-center px-0 py-2 d-none d-md-flex">
                                        <div className="col-7 col-md-4 px-2"  >
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>
                                        <div className="d-flex   col-6 col-md-7 mt-0 align-items-center  justify-content-end  justify-content-md-start">
                                            <h5 className="fs-15 mb-0">Value</h5>
                                        </div>

                                        <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end ">
                                            <h5 className="fs-15 mb-0">  Actions</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row className="row gy-1 mb-1" id="setting-list">
                        {loading ?
                            <div className='text-center'>
                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>

                            :
                            settingList?.length !== 0 ?

                                (settingList)?.filter(key => (key?.key !== null && key?.key !== undefined && key?.key !== ""))?.map((item, index) => {
                                    return (
                                        <Col lg={12} key={index} className='mt-0'>
                                            <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                                <CardBody className="px-2 py-0">
                                                    <div className="d-flex align-items-center row">
                                                        <div className="col-6 col-md-4 align-items-center">
                                                            <p className="mb-0 ">  {"Key"}</p>
                                                        </div>
                                                        <div className=" col-6 col-md-1 d-flex  d-md-none  gap-0 align-items-center justify-content-end ">
                                                            <button type="button" className="btn  btn-sm btn-icon "
                                                                onClick={(e) => { handleEditClick(item); }}>
                                                                <HiOutlinePencilAlt className="fs-18 primary" />
                                                            </button>
                                                        </div>
                                                        <div className="col-12 col-md-7 align-items-center  ">
                                                            <p className="mb-0  d-flex gap-1">
                                                                {index === Index && decryptedValue?.[index] ?
                                                                    decryptedValue?.[index] + " " :
                                                                    item?.key ?
                                                                        (item?.key?.substring(0, 0) + 'x'.repeat(Math.max(item?.key?.length - 5, 0)))?.substring(0, 20)
                                                                        + " " : ""}

                                                                {item?.key && <span className='cursor text-muted' key={item.id} onClick={() => {
                                                                    setDecryptedValue(settingList?.map(() => ""))
                                                                    setSubmit(false); setIndex(Index !== index ? index : !index)
                                                                }}>
                                                                    {decryptedValue?.[index] ?
                                                                        <i className="ri-eye-fill align-middle"></i> :
                                                                        <i className="ri-eye-off-fill align-middle"></i>}
                                                                </span>}
                                                            </p>
                                                            {
                                                                index === Index && !decryptedValue?.[index] &&
                                                                <Form className="d-flex gap-2 col-12 col-md-4"
                                                                    // style={{ width: "300px"}}
                                                                    onSubmit={(e) => {
                                                                        e.preventDefault();
                                                                        showPasswordValidation.handleSubmit();
                                                                        return false;
                                                                    }}>
                                                                    <Input type="password" className="form-control px-2 py-1" name='password'
                                                                        onChange={showPasswordValidation.handleChange}
                                                                        onBlur={showPasswordValidation.handleBlur}
                                                                        placeholder="Enter password"
                                                                        invalid={submit && showPasswordValidation.errors.password ? true : false}
                                                                    />
                                                                    <button type="submit" className="btn btn-primary d-flex py-1" onClick={() => { setSubmit(true); }}>
                                                                        {showPasswordValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}
                                                                    </button>
                                                                </Form>
                                                            }
                                                        </div>

                                                        <div className=" col-12 col-md-1 d-none  d-md-flex  gap-0 align-items-center justify-content-end ">
                                                            <button type="button" className="btn  btn-sm btn-icon "
                                                                onClick={(e) => { handleEditClick(item); }}>
                                                                <HiOutlinePencilAlt className="fs-18 primary" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                })
                                :
                                <div className="noresult" >
                                    <div className="text-center">
                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                    </div>
                                </div>
                        }
                    </Row>

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}>
                        <OffcanvasHeader className="d-flex align-items-center bg-light px-3 py-2" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2">{!isEdit ? "Add Google Authenticator" : "Update Google Authenticator"}</span>
                                <span className="fs-18 px-1 d-flex align-items-center" >
                                    <HiOutlineCheck className='cursor' onClick={() => { codeValidation.handleSubmit(); }} />
                                    <span className="fs-18 cursor" onClick={() => { toggleDrawer() }}>   <IoCloseOutline /> </span>
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="p-4">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        codeValidation.handleSubmit();
                                        return false;
                                    }}>


                                        {/* { <Row className='align-items-center mb-3'>
                    <Col lg={4}>
                        {!isEdit && <button className="btn btn-primary py-1" onClick={(e) => {
                            e.preventDefault(e);
                            generateTOTP();
                        }}>Generate TOTP  </button>}

                        {isEdit && <button className="btn btn-primary py-1" onClick={(e) => {
                            e.preventDefault(e);
                            reGenerateTOTP(); 
                            setCodeVerified(false);
                        }}>ReGenerate TOTP  </button>}

                    </Col>
                    <Col lg={6} >
                       
                    </Col>
                </Row>} */}

                                        {/* {<div dangerouslySetInnerHTML={{ __html: qrCodeData }} className='text-center' />}  */}

                                        <div className='text-center'>
                                            <QRCode value={qrCodeData} />
                                        </div>
                                        <div>
                                            {qrCodeData != "" && !codeVerified &&
                                                <Row className='mt-2'>
                                                    <Col lg={4}> <Label htmlFor="totp" className="form-label">TOTP</Label> </Col>
                                                    <Col lg={8}>
                                                        <div className="mb-3">
                                                            <Input type="number" className="form-control px-2 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" id="totp" name='totp'
                                                                onChange={(e) => {
                                                                    let inputValue = e.target.value;
                                                                    if (inputValue.length > 6) {
                                                                        inputValue = inputValue.slice(0, 6);
                                                                    }
                                                                    codeValidation.handleChange({
                                                                        target: {
                                                                            name: 'totp',
                                                                            value: inputValue,
                                                                        },
                                                                    });
                                                                }}
                                                                onBlur={codeValidation.handleBlur}
                                                                value={codeValidation.values.totp || ""}
                                                                placeholder="Enter totp"
                                                                invalid={codeValidation.touched.totp && codeValidation.errors.totp ? true : false}
                                                            />
                                                        </div>
                                                    </Col>

                                                    {/* <Col lg={2}>
                                <div className="hstack gap-2 justify-content-end d-flex gap-2">
                                    <Button color="success" type="submit" disabled={codeValidation.isSubmitting ? true : false}
                                        onClick={(e) => {
                                            e.preventDefault(e);
                                            codeValidation.handleSubmit();
                                            setSubmit(true);
                                        }}
                                        className="btn btn-success d-flex gap-1 py-1 align-items-center"  >
                                        {codeValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}
                                        {"Verify"}</Button>                                      
                                        
                                        <span className="fs-20 cursor text-danger" onClick={(e) => { e.preventDefault(e); setQRCodeData("");}}>   <IoCloseOutline /> </span>
                                </div>
                            </Col> */}
                                                </Row>}
                                        </div>
                                        <Row>


                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="submit" className="btn btn-primary d-flex gap-1 py-1 align-items-center"  >  {codeValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null}{"Verify"}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>

                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default GoogleAuthenticator;