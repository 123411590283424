import React, { useState, useEffect } from 'react';

//import Scss
import './assets/scss/themes.scss';

//imoprt Route
import Route from './Routes';

// Import Firebase Configuration file
import { initFirebaseBackend } from "./helpers/firebase_helper";

// Fake Backend 
// import fakeBackend from "./helpers/AuthType/fakeBackend";

// Activating fake backend
//fakeBackend();
import { api, url } from "./helpers/api";

const App = () => {
  const [firebaseConfig, setFirebaseConfig] = useState(null);

    
  useEffect(() => {
     
    const fetchFirebaseConfig = async () => {
      try {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        const response = await api.get(`firebaseSetting`);

        if (response.data.status === true) {
          const configData = response.data?.data?.data;
          setFirebaseConfig({
            apiKey: configData?.filter(type => type?.name === "api_key")[0]?.value,
            authDomain: configData?.filter(type => type?.name === "auth_domain")[0]?.value,
            databaseURL: configData?.filter(type => type?.name === "database_url")[0]?.value,
            projectId: configData?.filter(type => type?.name === "project_id")[0]?.value,
            storageBucket: configData?.filter(type => type?.name === "storage_bucket")[0]?.value,
            messagingSenderId: configData?.filter(type => type?.name === "messaging_sender_id")[0]?.value,
            appId: configData?.filter(type => type?.name === "app_id")[0]?.value,
            measurementId: configData?.filter(type => type?.name === "measurement_id")[0]?.value,
          });
        } else {
          setFirebaseConfig(null);
        }
      } catch (error) {
        console.error('Error fetching Firebase configuration:', error);
      }
    };

    const changeFirebaseConfig = () => {
      fetchFirebaseConfig();
    }; 
 
    window.addEventListener("changeFirebaseConfig", changeFirebaseConfig);
    return () => {
        window.removeEventListener("changeFirebaseConfig", changeFirebaseConfig);
    };
}, []);


  // Initialize Firebase backend with fetched config
  useEffect(() => {
    if (firebaseConfig) {
      initFirebaseBackend(firebaseConfig);
    }
  }, [firebaseConfig]);

  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
};

export default App;
