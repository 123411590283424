import React, { useState, useEffect, useCallback } from 'react';
import {
    Card, CardBody, Button, PopoverHeader, PopoverBody, Popover, Offcanvas, OffcanvasHeader, OffcanvasBody, Spinner,
    Modal, ModalBody, ModalFooter, ModalHeader, Col, Container, Form, Input, Label, Row, UncontrolledPopover,
    UncontrolledTooltip, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { useMediaQuery } from 'react-responsive';
import SimpleBar from "simplebar-react";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import * as Yup from "yup";
import { ToastContainer } from 'react-toastify';
import { IoCloseOutline } from "react-icons/io5";
import { AiOutlineHistory } from "react-icons/ai";
import { HiOutlineCheck, HiTrash } from "react-icons/hi2";
import DeleteModal from "../../Components/Common/DeleteModal";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { FaFilePdf, FaFileAlt, FaFileExcel } from "react-icons/fa";
import { Link, useNavigate } from 'react-router-dom';
import { ToastMessage, DefaultDate, SmallDateFormat, FormatDate, snakeToCamel } from "../../Components/Hooks/Function";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";
import FormPasswordCategory from './FormPasswordCategory';
import FormPassword from './FormPassword';


const Passwords = () => {
    document.title = "Passwords";
    var moment = require('moment-timezone');
    const history = useNavigate();
    const imageExtensions = new Set(["png", "jpg", "jpeg", "gif", "bmp"]);
    const pdfExtensions = new Set(["pdf"]);
    const excelExtensions = new Set(["xls", "xlsx"]);
    const [open, setOpen] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [passwordList, setPasswordList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [view, setView] = useState("");
    const [deleteModal, setDeleteModal] = useState(false);
    const [passwordView, setPasswordView] = useState([]);
    const [passwordShow, setPasswordShow] = useState(false);
    const [passwordVerified, setPasswordVerified] = useState(false);
    const [OTPVerfied, setOTPVerfied] = useState(false);
    const [password, setPassword] = useState("");
    const [isPassword, setIsPassword] = useState([]);
    const [decryptedPasswords, setDecryptedPasswords] = useState([]);
    const [codeVerified, setCodeVerified] = useState(false);

    const userDetails = getLoggedinUser()?.user;
    // const isMobile = window.innerWidth <= 768;
    const [searchList, setSearchList] = useState([]);
    const [selectedCountry, setSelectedCountry] = useState(getSettingsDetails("settings")?.filter(type => type?.name === "country")[0]?.value);
    const [deleteFieldModal, setDeleteFieldModal] = useState(false);
    const [Index, setIndex] = useState(0);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [passwordHistoryList, setPasswordHistoryList] = useState([]);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [isEdit, setIsEdit] = useState(false);
    const [categoryCustomFields, setCategoryCustomFields] = useState([]);
    const [customFields, setCustomFields] = useState([]);
    const [loading, setLoading] = useState(false);
    const [qrCodeData, setQRCodeData] = useState("");
    const [fileUrl, setFileUrl] = useState([]);
    const [modalFile, setModalFile] = useState(false);
    const [inviteOpen, setInviteOpen] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(null);
    const [userId, setUserId] = useState(0);
    const [deleteInvite, setDeleteInvite] = useState(false);
    const [status, setStatus] = useState("Revoke");


    function getExtension(filename) {
        return filename?.split('.').pop()
    }

    const toggleDropDownOpen = (itemId) => {
        setInviteOpen(false);
        setDropDownOpen(dropDownOpen === itemId ? null : itemId);

    };

    const toggleInvite = () => {
        setInviteOpen(!inviteOpen);
    };

    const togglePopover = () => {
        setPopoverOpen(!popoverOpen);
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };


    const handleRemoveCustomFields = async (index) => {

        if (customFields[index]?.id) {

            setDeleteFieldModal(true);
            setIndex(index);

            // await deleteCustomField(customFields[index]?.id);

            // const newCustomFields = [...customFields];
            // newCustomFields.splice(index, 1);
            // setCustomFields(newCustomFields); 

        } else {
            const newCustomFields = [...customFields];
            newCustomFields.splice(index, 1);
            setCustomFields(newCustomFields);
        }
    };

    const handleRemoveCustomFieldAfterConfirm = async (index) => {
        await deleteCustomField(customFields[index]?.id);
        const newCustomFields = [...customFields];
        newCustomFields.splice(index, 1);
        setCustomFields(newCustomFields);
        setDeleteFieldModal(false);
    };


    const addPassword = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            for (const key in value) {
                if (value.hasOwnProperty(key)) {
                    if (key === "remainder_date") {
                        formData.append('remainder_date', value?.remainder ? FormatDate(value.remainder_date, selectedCountry) : "");
                    } else if (key === "expire_at") {
                        formData.append('expire_at', value.expire_at ? FormatDate(value.expire_at, selectedCountry) : "");
                    } else if (key === "remainder") {
                        formData.append('remainder', value?.remainder ? 1 : 0 || 0);
                    } else {
                        formData.append(key, value[key] || '');
                    }
                }
            }

            customFields.forEach((item, index) => {
                if (item.name !== "") {
                    formData.append(`customFields[${index}][name]`, item.name);
                    formData.append(`customFields[${index}][type]`, item.type);
                    formData.append(`customFields[${index}][value]`, item.value);
                }
            });

            const response = await api.post('password', formData);

            if (response.data.status === true) {
                setIsEdit(false);
                setOpen(false);
                validation.resetForm();
                getPasswordList();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const editPassword = async (value, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            formData.append('_method', "PUT");
            for (const key in value) {
                if (value.hasOwnProperty(key)) {
                    if (key === "remainder_date") {
                        formData.append('remainder_date', value?.remainder ? FormatDate(value.remainder_date, selectedCountry) : "");
                    } else if (key === "expire_at") {
                        formData.append('expire_at', value.expire_at ? FormatDate(value.expire_at, selectedCountry) : "");
                    } else if (key === "remainder") {
                        formData.append('remainder', value?.remainder ? 1 : 0 || 0);
                    } else {
                        formData.append(key, value[key] || '');
                    }
                }
            }

            customFields.forEach((item, index) => {
                if (item.name !== "") {
                    formData.append(`customFields[${index}][id]`, (item?.id || ""));
                    formData.append(`customFields[${index}][name]`, item?.name);
                    formData.append(`customFields[${index}][type]`, item?.type);
                    formData.append(`customFields[${index}][value]`, item?.value);
                }
            });

            const response = await api.post('password/' + id, formData);
            if (response.data.status === true) {
                getPasswordList();
                setIsEdit(false);
                setDropDownOpen(false);
                validation.resetForm();
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const viewPasswordList = async (id) => {

        if (id) {
            api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
            api.defaults.headers.common['Content-Type'] = 'application/json'
            try {
                const response = await api.get('password/' + id, {});

                if (response.data.status === true) {
                    validation.setValues({
                        ...response.data.data,
                        expire_at: DefaultDate(response.data?.data?.expire_at, selectedCountry) || '',
                        remainder_date: DefaultDate(response.data?.data?.remainder_date, selectedCountry) || '',
                        remainder: response.data?.data?.remainder === 1 ? true : false,
                    });
                    setPasswordHistoryList(response.data.data?.password_history)
                    setCategoryCustomFields([
                        ...response.data.data?.password_category?.password_category_lists.reduce((acc, item, index) => {
                            acc[index] = {
                                ...item,
                                value: response.data.data[item.name],
                            };
                            return acc;
                        }, []),
                    ]);
                    setCustomFields([
                        ...response.data.data?.password_custom_field.reduce((acc, item, index) => {
                            acc[index] = {
                                ...item,
                                value: response.data.data[item.name],
                            };
                            return acc;
                        }, []),
                    ]);
                }
                setOpen(true);

            } catch (err) {
                console.error(err.message);
            }
        }
    };

    const handleAddClick = () => {
        setInviteOpen(false);
        setPopoverOpen(false);
        setOpen(false);
        setDropDownOpen(null);

        setQRCodeData("");
        setOTPVerfied(false);
        setPasswordShow(false);
        setPopoverOpen(false);
        setPassword("");
        setCodeVerified(false);
        toggleDrawer();
        setCategoryCustomFields([]);
        setCustomFields([]);
        setSubmit(false);
        setView("");
        setIsEdit(false);

        validation.resetForm();
        codeValidation.resetForm();
        showPasswordValidation.resetForm();
        validation.setValues({
            remainder: false,
            remainder_date: DefaultDate("", selectedCountry),
            expire_at: DefaultDate("", selectedCountry)
        });
    };

    const handleEditClick = useCallback((arg) => {
        validation.resetForm();
        codeValidation.resetForm();
        showPasswordValidation.resetForm();
        setCodeVerified(false);
        setQRCodeData("");
        // setOpen(false); 
        setPassword("");
        setPasswordShow(false);
        setPasswordVerified(false);
        setOTPVerfied(false);
        setView("");
        setIsEdit(true);
        setPopoverOpen(false);
        setCategoryCustomFields([]);
        setCustomFields([]);
        setSubmit(false);
        viewPasswordList(arg?.id);
    }, []);

    const handleViewClick = (arg) => {
        setInviteOpen(false);
        setPopoverOpen(false);
        setOpen(false);
        setDropDownOpen(null);

        validation.resetForm();
        codeValidation.resetForm();
        showPasswordValidation.resetForm();
        setCodeVerified(false);
        setPassword("");
        setPasswordVerified(false);
        setOTPVerfied(false);
        setPasswordShow(false);
        viewPasswordList(arg?.id);
        setIsEdit(false);
        setView("Password");
    };

    const deletePassword = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('password/' + value?.id, {});
            if (response.data.status === true) {
                getPasswordList()
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)
        } catch (err) {
            console.error(err.message);
        }
    };

    const deleteCustomField = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('customFieldRemove/' + id, {});
            if (response.data.status === true) {

                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const dynamicSchema = categoryCustomFields.reduce((accumulator, item) => {
        return {
            ...accumulator,
            [item.name]:
                (item.type === "Password" && isEdit) ?
                    Yup.string().notRequired() :
                    (item.type === "File") ?
                        item.mandatory ? Yup.mixed().required(`${item.name} is required`) : Yup.mixed().notRequired() :
                        item.mandatory ? Yup.string().required(`${item.name} is required`) : Yup.string().notRequired(),
        };
    }, {});

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            password_category_id: '',
            website_name: '',
            authentication_type: '',
            note: '',
            expire_at: '',
            remainder_date: '',
            remainder: false,
            remainder_in: 0,
        },
        validationSchema: Yup.object({
            password_category_id: isEdit ? Yup.string() : Yup.string().required("Please enter"),
            website_name: Yup.string().required("Please enter"),
            authentication_type: Yup.string().required("Please enter"),
            expire_at: Yup.string().notRequired(),
            note: Yup.string().notRequired(),
            remainder: Yup.string().notRequired(),
            remainder_in: Yup.string().notRequired(),
            remainder_date: Yup.string().when('remainder', {
                is: (remainder) => true,
                then: (schema) => schema.required("Enter"),
                otherwise: (schema) => schema,
            }),
            ...dynamicSchema,

        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                if (isEdit) {
                    await editPassword(values, validation.values?.id);
                } else {
                    await addPassword(values);
                }
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const categoryValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            category_name: '',
        },
        validationSchema: Yup.object({
            category_name: Yup.string().required("Please enter")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await addCategory(values);
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const addCategory = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            for (const key in value) {
                formData.append(key, value[key] || '');
            }
            categoryCustomFields.forEach((input, index) => {
                formData.append(`category[${index}][name]`, input.name);
                formData.append(`category[${index}][type]`, input.type);
                formData.append(`category[${index}][mandatory]`, (input.mandatory === true || input.mandatory === 1) ? 1 : 0);
            });
            const response = await api.post('passwordCategory', formData);
            if (response.data.status === true) {
                categoryValidation.resetForm();
                setSubmit(false);
                getAllCategory();
                setView("");
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const getAllCategory = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('getAllPasswordCategory', {});
            if (response.data.status === true) {
                setCategoryList(response.data?.data);
            } else {
                setCategoryList([]);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const getPasswordList = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`password?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setPasswordList(response.data.data);
                setSearchList(response.data?.data);
            } else {
                setPasswordList([]);
                setSearchList([]);
            }
            setLoading(false);
        } catch (err) {
            console.error(err.message);
            if (err.response?.status === 401) {
                history('/logout');
            }
        }
    };

    useEffect(() => {
        getAllCategory();
        getPasswordList();
    }, []);

    const handleShowPassword = async (index) => {
        try {
            const newExpandedStates = [...isPassword];
            newExpandedStates[index] = !newExpandedStates[index];
            setIsPassword(newExpandedStates);
            const newdecryptedPasswords = [...decryptedPasswords];
            newdecryptedPasswords[index] = await decryptPassword(passwordHistoryList[index].password);
            setDecryptedPasswords(newdecryptedPasswords);
        } catch (error) {
            console.error(error);
        }
    };

    const handleCopyClick = async (password) => {
        try {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(password)
                    .then(() => {
                        ToastMessage("success", "Copied successfully");
                    })
            }
        } catch (error) {
            console.error(error);
        }
    };

    const decryptPassword = (password) => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("Token not found in localStorage");
                return;
            }

            const formData = new FormData();
            formData.append("password", password);
            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "multipart/form-data",
                },
            };

            return api.post('decrypt', formData, config)
                .then((result) => {
                    return result.data.data
                })

        } catch (err) {
            console.error(err.message);
        }
    };

    const [activeRow, setActiveRow] = useState(0);

    const handleRowClick = (index) => {
        setActiveRow(index === activeRow ? 0 : index);
    };

    const getColor = (index) => {
        const colors = ['#4780da', '#65b5c9', '#7ac74f', '#ffa500', '#800080'];
        return colors[index % colors.length];
    };

    useEffect(() => {
        setIsPassword(passwordHistoryList?.map(() => false))
        setDecryptedPasswords(passwordHistoryList?.map(() => ""))
    }, [passwordHistoryList]);

    const onClickData = () => {
        setDeleteModal(true);
    };

    const getPasswordListPage = async (currentPage, rowPerPage) => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.get(`password?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setPasswordList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setPasswordList([]);
                setSearchList([]);
            }
            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        getPasswordListPage(currentPage - 1, rowPerPage);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        getPasswordListPage(currentPage + 1, rowPerPage);
    };

    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.website_name?.toLowerCase().includes(inputVal.toLowerCase())
            );
            setPasswordList({
                ...passwordList,
                data: filteredResults
            });
        } else {
            setPasswordList({
                ...passwordList,
                data: searchList?.data
            });
        }
    };

    const showPasswordValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,

        initialValues: {
            password: '',

        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password")

        }),
        onSubmit: async (values) => {
            try {

                await RequestShowPassword(values);

            } catch (err) {
                console.error(err.message);
            }
        }
    });

    function refreshTimer() {

        const timer = setTimeout(() => {
            setPasswordShow(false);
            setPassword("");
            setCodeVerified(false);
            setPasswordVerified(false);
            setOTPVerfied(false);
            otpValidation.resetForm();
            codeValidation.resetForm();
            showPasswordValidation.resetForm();
        }, 30000);

        return () => {
            clearTimeout(timer);
        };
    }

    const RequestShowPassword = async (values) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`requestShowPassword/${validation?.values?.id}`, {
                ...values,
                email: userDetails.email
            });
            if (response.data.status === true) {
                setPasswordVerified(true);
                if (validation?.values?.authentication_type === "Two-factor") {
                    setOTPVerfied(false);
                    handleTwoFactor(validation?.values?.id);
                } else {
                    setPassword(response.data.data);
                    if (response.data.data) {
                        refreshTimer();
                    }
                }

            } else {
                setPasswordVerified(false);
                setPassword("");
                ToastMessage("error", "Incorrect password");
            }
        } catch (err) {
            console.error(err.message);
            ToastMessage("error", err.message);
        }
    }

    const handleTwoFactor = async (id) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`requestTwoFactor/${id}`, {});

            if (response.data.status === true) {

                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {

            console.error(err.message);
        }
    };

    const otpValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            otp: ''
        },
        validationSchema: Yup.object({
            otp: Yup.string().required("Please enter"),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await verifyOtp(values);
                setSubmitting(false);
            } catch (error) {

                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const verifyOtp = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {

            const response = await api.post('verifyOtp/' + validation?.values?.id, {
                otp: value.otp,
                email: userDetails.email,
                password: showPasswordValidation?.values?.password
            });

            if (response.data.status === true) {
                setOTPVerfied(true);
                setPassword(response.data.data);
                refreshTimer();
            } else {
                setOTPVerfied(false);
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const codeValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            totp: '',
        },
        validationSchema: Yup.object({
            totp: Yup.string().required("Please enter your email")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await TOTPVerificationID(values, validation.values.id);

                setSubmitting(false);

            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });

    const TOTPVerificationID = async (values, id) => {
        try {
            const response = await api.post(`TOTPPasswordVerification/${id}`, {
                ...values,
                email: userDetails.email,
                password: showPasswordValidation?.values?.password
            });

            if (response.data.status === true) {
                codeValidation.resetForm();
                setSubmit(false);
                setPassword(response.data.data);
                setCodeVerified(true);
                setPasswordVerified(true);
                setPasswordShow(true);
                setQRCodeData("");

                refreshTimer();


            } else {
                setCodeVerified(false);
                ToastMessage("error", response.data?.message);
            }

        } catch (error) {
            console.error('Error generating OTP:', error);
        }
    };


    const inviteValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please enter your email")
        }),
        onSubmit: async (values, { setSubmitting }) => {
            try {
                await addInvite(values, passwordView?.id);
                setSubmitting(false);
            } catch (error) {
                console.error("An error occurred:", error);
                setSubmitting(false);
            }
        },
    });


    const addInvite = async (values, id) => {
        try {
            const response = await api.post(`addInvite/${id}`, values);
            if (response.data.status === true) {
                ToastMessage("success", response.data?.message);

                inviteValidation.resetForm();
                setSubmit(false);
                setInviteOpen(false);
                setPopoverOpen(false);
                setOpen(false);
                setDropDownOpen(null);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    const removeInvite = async (password_id, user_id) => {
        try {
            const response = await api.post(`removeInvite/${password_id}/${user_id}`, {});
            if (response.data.status === true) {
                viewPasswordList(password_id);
                setDeleteInvite(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };


    const reInvite = async (password_id, user_id) => {
        try {
            const response = await api.post(`reInvite/${password_id}/${user_id}`, {});
            if (response.data.status === true) {
                viewPasswordList(password_id);
                setDeleteInvite(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={passwordView?.name}
                show={deleteModal}
                onDeleteClick={() => deletePassword(passwordView)}
                onCloseClick={() => setDeleteModal(false)}
            />

            <div className="page-content">

                <Container fluid>
                    <BreadCrumb title="All Passwords" pageTitle="Pages" />
                    <Row>
                        <Col xs={12} className='d-block d-md-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center  justify-content-between justify-content-md-end gap-2'>
                                <Button type="button" color="primary" outline className="py-1" onClick={() => { handleAddClick(); }} >  ADD  </Button>
                                <div className="search-box">
                                    <Input type="text" className="form-control py-1" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </div>
                            <div className='d-flex gap-1 align-items-center  justify-content-between justify-content-md-end mt-2'>
                                <div className='d-flex align-items-center'>
                                    {(passwordList?.from || 1) + " - " + (passwordList?.to || 1) + " of " + (passwordList?.total || 1)}
                                    <div>
                                        <button className='border-0 bg-white pe-0' onClick={handlePrevPage} disabled={currentPage === 1}>
                                            <MdOutlineKeyboardArrowLeft size={25} />
                                        </button>
                                        <button className='border-0 bg-white ps-0' onClick={handleNextPage} disabled={(currentPage * rowPerPage >= passwordList?.total ? passwordList?.total : rowPerPage) === passwordList?.total}>
                                            <MdOutlineKeyboardArrowRight size={25} />
                                        </button>
                                    </div>
                                </div>

                                <div className='d-flex gap-2 align-items-center'>
                                    Shows
                                    <select className="pagination-prev disabled  h-100 px-1 py-1" type="select" name="perPage" id="perPage"
                                        onChange={(e) => {
                                            setRowPerPage(e.target.value);
                                            getPasswordListPage(1, e.target.value);
                                        }}
                                        value={rowPerPage} >
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={passwordList?.total}>All</option>
                                    </select>
                                </div>
                            </div>
                        </Col>

                        {loading ?
                            <div className='text-center'>
                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>
                            :
                            <Col xs={12} className="ps-4">
                                <div className="table-responsive table-card mt-4">
                                    <table className="table table-hover align-middle table-nowrap table-striped-columns ">
                                        <tbody>
                                            {passwordList?.data?.length !== 0 ?
                                                (passwordList?.data)?.map((item, index) => {
                                                    return (
                                                        <tr key={index}
                                                            className={index === activeRow ? 'border-start border-primary bg-light bg-opacity-75' : ''}
                                                            onClick={() => handleRowClick(index)}  >
                                                            <td className='col-11 py-2 cursor' onClick={() => { handleViewClick(item); }}>
                                                                <div className='d-flex align-items-center gap-2'>
                                                                    <div className="avatar-sm" >
                                                                        <div className="avatar-title rounded-circle text-white" style={{ backgroundColor: getColor(index) }}>
                                                                            {item.website_name?.slice(0, 1)}
                                                                        </div>
                                                                    </div>
                                                                    <a href="#!">  {item.website_name} </a> 

                                                                </div>
                                                            </td>

                                                            <td className='col-1'>
                                                                <UncontrolledDropdown direction='start' className="col text-end position-static" isOpen={dropDownOpen === item.id} >
                                                                    {item.password_user?.filter(item => item.created_by === userDetails?.id)[0] &&
                                                                        <DropdownToggle tag="a" onClick={() => { toggleDropDownOpen(item.id) }} role="button"><i className="ri-more-2-line fs-17"></i> </DropdownToggle>}
                                                                    <DropdownMenu>

                                                                        {<DropdownItem className="dropdown-item edit-list" onClick={() => { handleEditClick(item); }}>
                                                                            <i className="ri-pencil-line me-2 align-bottom text-muted"></i> Edit
                                                                        </DropdownItem>}

                                                                        <DropdownItem className="dropdown-item invite-list" id={`invitePopover${item.id}`} onClick={() => { toggleInvite(); setPasswordView(item); }} >
                                                                            <i className="ri-share-fill me-2 align-bottom text-muted"></i>Invite
                                                                        </DropdownItem>

                                                                        <DropdownItem className="dropdown-item remove-list" onClick={() => { onClickData(item); setPasswordView(item); }}>
                                                                            <i className="ri-delete-bin-5-line me-2 align-bottom text-muted"></i>Remove
                                                                        </DropdownItem>
                                                                    </DropdownMenu>
                                                                </UncontrolledDropdown>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                                ) :

                                                <tr>
                                                    <td></td>
                                                    <td>
                                                        <div className="noresult" >
                                                            <div className="text-center">
                                                                <h6 className="mt-2">Sorry! No Result Found</h6>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </Col>
                        }
                    </Row>

                    {inviteOpen &&
                        <UncontrolledPopover
                            placement={isDesktop ? "left" : "bottom"}
                            target={`invitePopover${passwordView?.id}`}
                            isOpen={inviteOpen}>
                            <PopoverHeader> <span className='d-flex justify-content-between'>   <span>Invite </span>
                                <IoCloseOutline className="fs-20 cursor" onClick={() => { toggleInvite() }} />
                            </span>
                            </PopoverHeader>
                            <PopoverBody className='p-2'>
                                <Form onSubmit={(e) => { e.preventDefault(); inviteValidation.handleSubmit(); return false; }}>
                                    <div className="mb-2 d-flex gap-2 align-items-center">
                                        <Input type="text" className="form-control px-2 py-1" placeholder="Enter email"
                                            name='email'
                                            validate={{ required: { value: true } }}
                                            onChange={inviteValidation.handleChange}
                                            onBlur={inviteValidation.handleBlur}
                                            value={inviteValidation.values.email || ""}
                                            invalid={submit && inviteValidation.errors.email ? true : false}
                                        />

                                        <div className="hstack gap-2 justify-content-end ">
                                            <button type="submit" className="btn btn-primary py-1 d-flex align-items-center" onClick={() => { setSubmit(true); }}>
                                                {inviteValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Invite"}</button>
                                        </div>
                                    </div>

                                </Form>


                            </PopoverBody>
                        </UncontrolledPopover>
                    }




                    <Modal isOpen={deleteFieldModal} toggle={() => { setDeleteFieldModal(false) }} centered={true}>
                        <ModalBody className="py-3 px-5">
                            <div className="mt-2 text-center">
                                <lord-icon
                                    src="https://cdn.lordicon.com/gsqxdxog.json"
                                    trigger="loop"
                                    colors="primary:#FFBC0A,secondary:#f06548"
                                    style={{ width: "100px", height: "100px" }}
                                ></lord-icon>

                                <div className="mt-4 pt-2 fs-15">
                                    <h4>Are you sure?</h4>
                                    <p className="text-muted mx-4 mb-0"> Are you sure you want to remove this field?</p>
                                </div>
                            </div>
                            <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                                <button
                                    type="button"
                                    className="btn w-sm btn-light"
                                    data-bs-dismiss="modal"
                                    onClick={() => setDeleteFieldModal(false)}
                                >
                                    Close
                                </button>

                                <button
                                    type="button"
                                    className="btn w-sm btn-danger "
                                    id="delete-record"
                                    onClick={() => handleRemoveCustomFieldAfterConfirm(Index)}
                                >
                                    Yes, Delete It!
                                </button>

                            </div>
                        </ModalBody>
                    </Modal>

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-light px-3 py-2" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2">
                                    {view === "Password" ?
                                        <>
                                            <div className='d-flex align-items-center gap-2'>
                                                <div className="avatar-sm">
                                                    <div className="avatar-title rounded-circle text-white">
                                                        {validation?.values.website_name?.slice(0, 1)}
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className='mb-0 fs-15'> {validation?.values.website_name} </p>
                                                    <p className='mb-0 fs-13 fw-lighter'> Created On {moment(validation?.values.created_at).tz(moment.tz.guess()).format('DD-MM-YYYY')} </p>
                                                </div>
                                            </div>
                                        </>

                                        : view === "Category" ? "Add Category" : !isEdit ? "Add Password" : "Update Password"}</span>
                                <span className='d-flex gap-2 align-items-center '>
                                    {view === "Category" && <>

                                        <HiOutlineCheck className="fs-20 cursor" onClick={() => { setSubmit(true); categoryValidation.handleSubmit(); }} />
                                        <span className="fs-20 cursor" onClick={() => { setView(""); }}>   <IoCloseOutline /> </span>
                                    </>
                                    }
                                    {view === "" && <HiOutlineCheck className="fs-20 cursor" onClick={() => { setSubmit(true); validation.handleSubmit(); }} />}
                                    {/* {view === "Password" && <HiOutlinePencilAlt className="fs-20 cursor" onClick={(e) => { validation.resetForm(); setIsEdit(true); setView(""); }} />} */}
                                    {isEdit &&
                                        <div>
                                            <AiOutlineHistory className="fs-20 cursor" id={"UncontrolledPopover" + validation?.values.id} type="button" onClick={() => { togglePopover() }} />
                                            {popoverOpen &&
                                                <UncontrolledPopover
                                                    placement={"left"}
                                                    target={"UncontrolledPopover" + validation?.values.id}
                                                    isOpen={popoverOpen}  >
                                                    <PopoverHeader>
                                                        <span className='d-flex justify-content-between'>
                                                            <span>  History </span>
                                                            <IoCloseOutline className="fs-20 cursor" onClick={() => { togglePopover() }} />
                                                        </span>
                                                    </PopoverHeader>
                                                    <PopoverBody className='p-2'>

                                                        <table className="table table-hover align-middle table-nowrap table-striped-columns mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Password</th>
                                                                    <th scope="col">Modified Time</th>
                                                                </tr>
                                                            </thead>

                                                            <tbody>
                                                                {passwordHistoryList?.map((item, index) => {
                                                                    return (
                                                                        <tr key={index}>
                                                                            <td scope="col"> <p className="mb-0">
                                                                                {isPassword?.[index] ?
                                                                                    decryptedPasswords?.[index] + " " :
                                                                                    (item.password?.substring(0, 0) + 'x'.repeat(Math.max(item.password?.length - 5, 0)))?.substring(0, 20)
                                                                                    + " "}

                                                                                <span className='cursor text-muted' key={item.id} onClick={() => handleShowPassword(index)}>
                                                                                    {isPassword?.[index] ?
                                                                                        <i className="ri-eye-fill align-middle"></i> :
                                                                                        <i className="ri-eye-off-fill align-middle"></i>}
                                                                                </span>
                                                                            </p></td>
                                                                            <td scope="col">   {moment(item?.created_at).tz(moment.tz.guess()).format('DD-MM-YYYY H:m:s')} </td>
                                                                        </tr>
                                                                    );
                                                                })
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </PopoverBody>
                                                </UncontrolledPopover>
                                            }
                                        </div>

                                    }
                                    {view !== "Category" && <span className="fs-20 cursor" onClick={() => { toggleDrawer(); }}>   <IoCloseOutline /> </span>}
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="p-4">
                                    {view === "Password" ? <>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Category </Label> <br />
                                                    {validation?.values.password_category?.category_name}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Website Name </Label> <br />
                                                    {validation?.values.website_name}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Authentication Type </Label> <br />
                                                    {validation?.values.authentication_type + " Authentication"}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Note </Label> <br />
                                                    {validation?.values.note}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Expire at </Label> <br />
                                                    {validation?.values.expire_at}
                                                </div>
                                            </Col>

                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label className="form-label fw-bold"> Remainder </Label> <br />
                                                    {/* {validation?.values.remainder ? "ON" : "OFF"} - */}
                                                    {validation?.values.remainder ? validation?.values.remainder_date : ""}
                                                </div>
                                            </Col>


                                            {validation?.values?.password_category?.password_category_lists?.map((item, index) => {
                                                return (
                                                    <Col lg={6} key={index}>
                                                        <div className="mb-3">
                                                            <Label className="form-label fw-bold">{snakeToCamel(item.name)}


                                                                {item.type === "Password" ? " (" + validation?.values.authentication_type + " Authentication)" : ""}

                                                            </Label>
                                                            <br />
                                                            {
                                                                item.type === "File" ?
                                                                    <React.Fragment key={index}>

                                                                        {validation?.values[item.name] && <>

                                                                            <Link to="#" className="avatar-group-item" id={`category_file${index}`} onClick={() => {
                                                                                setModalFile(true);

                                                                                setFileUrl({
                                                                                    name: validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[
                                                                                        validation.values?.password_attachments?.length - 1
                                                                                    ]?.file_name, path: url + validation?.values[item.name]
                                                                                });

                                                                            }} >

                                                                                {imageExtensions.has(getExtension(url + validation?.values[item.name])?.toLowerCase()) ?
                                                                                    <img src={url + validation?.values[item.name]} alt={`custom_file${index}`} className="rounded-circle avatar-sm" />
                                                                                    : pdfExtensions.has(getExtension(url + validation?.values[item.name])?.toLowerCase()) ?
                                                                                        <FaFilePdf size={30} />
                                                                                        : excelExtensions.has(getExtension(url + validation?.values[item.name])?.toLowerCase()) ?
                                                                                            <FaFileExcel size={30} />
                                                                                            : <FaFileAlt size={30} />}

                                                                            </Link>
                                                                            <UncontrolledTooltip placement="top" target={`category_file${index}`}> {snakeToCamel(item.name)} </UncontrolledTooltip>
                                                                        </>}


                                                                    </React.Fragment>

                                                                    : item.type === "Password" ?

                                                                        <>
                                                                            <div className='d-flex gap-2 align-items-center'>
                                                                                <p className='mb-0'> {passwordVerified && password ? password :
                                                                                    (validation?.values?.authentication_type?.substring(0, 0) + 'x'.repeat(Math.max(validation?.values?.authentication_type?.substring(0, 0)?.length - 0, 10)))?.substring(0, 10)}  </p>
                                                                                <>
                                                                                    <button className="btn btn-link p-0 text-decoration-none text-muted" onClick={() => {
                                                                                        setPasswordShow(!passwordShow);
                                                                                        setPasswordVerified(false);
                                                                                        setCodeVerified(false);
                                                                                        setPassword("");
                                                                                    }}
                                                                                        type="button">  <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                                                                    </button>

                                                                                    {password && <button type="button" className="btn  btn-sm btn-icon "
                                                                                        onClick={(e) => { handleCopyClick(password); }}> <i className="ri-file-copy-fill fs-18 primary"></i>
                                                                                    </button>}
                                                                                </>

                                                                            </div>

                                                                            {passwordShow && !passwordVerified &&
                                                                                <Form onSubmit={(e) => {
                                                                                    e.preventDefault();
                                                                                    showPasswordValidation.handleSubmit();
                                                                                    return false;
                                                                                }}>
                                                                                    <div className="mb-2 d-flex align-items-center">
                                                                                        <Input type="password" className="form-control px-0 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" name='password'
                                                                                            onChange={showPasswordValidation.handleChange}
                                                                                            onBlur={showPasswordValidation.handleBlur}
                                                                                            placeholder="Enter password"
                                                                                            invalid={submit && showPasswordValidation.errors.password ? true : false}
                                                                                        />
                                                                                        <div className="hstack gap-2 justify-content-end ">
                                                                                            <button type="submit" className="btn btn-primary py-1 d-flex align-items-center" onClick={() => { setSubmit(true); }}>
                                                                                                {showPasswordValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Form>}

                                                                            {validation?.values?.authentication_type === "Two-factor" && passwordVerified && !OTPVerfied &&
                                                                                <Form onSubmit={(e) => {
                                                                                    e.preventDefault();
                                                                                    otpValidation.handleSubmit();
                                                                                    return false;
                                                                                }}>
                                                                                    <div className="mb-2 d-flex align-items-center">
                                                                                        <Input type="text" className="form-control px-0 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" id="otp" name='otp'
                                                                                            onChange={otpValidation.handleChange}
                                                                                            onBlur={otpValidation.handleBlur}
                                                                                            placeholder="Enter otp"
                                                                                            invalid={submit && otpValidation.errors.otp ? true : false}
                                                                                        />
                                                                                        <div className="hstack gap-2 justify-content-end ">
                                                                                            <button type="submit" className="btn btn-primary py-1 d-flex align-items-center" onClick={() => { setSubmit(true); }}>
                                                                                                {otpValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                                                        </div>
                                                                                    </div>

                                                                                </Form>
                                                                            }

                                                                            {validation?.values?.authentication_type === "Google" && passwordVerified && !codeVerified && <>

                                                                                <Form onSubmit={(e) => {
                                                                                    e.preventDefault();
                                                                                    codeValidation.handleSubmit();
                                                                                    return false;
                                                                                }}>
                                                                                    <div className="mb-2 d-flex align-items-center">
                                                                                        <Input type="number" className="form-control px-0 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" id="totp" name='totp'
                                                                                            onChange={(e) => {
                                                                                                let inputValue = e.target.value;

                                                                                                if (inputValue.length > 6) {
                                                                                                    inputValue = inputValue.slice(0, 6);
                                                                                                }
                                                                                                codeValidation.handleChange({
                                                                                                    target: {
                                                                                                        name: 'totp',
                                                                                                        value: inputValue,
                                                                                                    },
                                                                                                });
                                                                                            }}
                                                                                            onBlur={codeValidation.handleBlur}
                                                                                            value={codeValidation.values.totp || ""}
                                                                                            placeholder="Enter totp"
                                                                                            invalid={codeValidation.touched.totp && codeValidation.errors.totp ? true : false}
                                                                                        />

                                                                                        <div className="hstack gap-2 justify-content-end ">
                                                                                            <button type="submit" className="btn btn-primary py-1 d-flex align-items-center" onClick={() => { setSubmit(true); }}>
                                                                                                {codeValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                                                        </div>
                                                                                    </div>
                                                                                </Form>
                                                                            </>
                                                                            }


                                                                        </>
                                                                        : validation?.values[item.name]}
                                                        </div>
                                                    </Col>
                                                )
                                            })}

                                            {validation?.values?.password_custom_field?.map((item, index) => {
                                                return (
                                                    <Col lg={6} key={index}>
                                                        <div className="mb-3">
                                                            <Label className="form-label fw-bold">{snakeToCamel(item.name)} </Label>
                                                            <br />
                                                            {item.type === "File" ?
                                                                <React.Fragment key={index}>
                                                                    {validation?.values[item.name] &&
                                                                        <>
                                                                            <Link to="#" className="avatar-group-item" id={`custom_file${index}`}
                                                                                onClick={() => {
                                                                                    setModalFile(true);

                                                                                    setFileUrl({
                                                                                        name: validation.values?.password_attachments?.filter(name => name?.name === item.name)?.[0]?.file_name, path: url + validation?.values[item.name]
                                                                                    });
                                                                                }}
                                                                            >
                                                                                {imageExtensions.has(getExtension(url + validation?.values[item.name])?.toLowerCase()) ?
                                                                                    <img src={url + validation?.values[item.name]} alt={`custom_file${index}`}
                                                                                        className="rounded-circle avatar-sm" />
                                                                                    : pdfExtensions.has(getExtension(url + validation?.values[item.name])?.toLowerCase()) ?
                                                                                        <FaFilePdf size={30} />
                                                                                        : excelExtensions.has(getExtension(url + validation?.values[item.name])?.toLowerCase()) ?
                                                                                            <FaFileExcel size={30} />
                                                                                            : <FaFileAlt size={30} />}
                                                                            </Link>
                                                                            <UncontrolledTooltip placement="top" target={`custom_file${index}`}> {item.name} </UncontrolledTooltip>
                                                                        </>}

                                                                </React.Fragment>
                                                                : item.type === "Password" ?
                                                                    <>
                                                                        <div className='d-flex gap-2 align-items-center'>
                                                                            <p className='mb-0'> {passwordVerified && password ? password :
                                                                                (validation?.values?.authentication_type?.substring(0, 0) + 'x'.repeat(Math.max(validation?.values?.authentication_type?.substring(0, 0)?.length - 0, 10)))?.substring(0, 10)}  </p>
                                                                            <>
                                                                                <button className="btn btn-link p-0 text-decoration-none text-muted" onClick={() => {
                                                                                    setPasswordShow(!passwordShow);
                                                                                    setPasswordVerified(false);
                                                                                    setPassword("");
                                                                                }}
                                                                                    type="button">  <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                                                                </button>
                                                                                {password && <button type="button" className="btn  btn-sm btn-icon "
                                                                                    onClick={(e) => { handleCopyClick(password); }}> <i className="ri-file-copy-fill fs-18 primary"></i>
                                                                                </button>}
                                                                            </>
                                                                        </div>

                                                                        {passwordShow && !passwordVerified &&
                                                                            <Form onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                showPasswordValidation.handleSubmit();
                                                                                return false;
                                                                            }}>
                                                                                <div className="mb-2 d-flex align-items-center">
                                                                                    <Input type="password" className="form-control px-0 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" name='password'
                                                                                        onChange={showPasswordValidation.handleChange}
                                                                                        onBlur={showPasswordValidation.handleBlur}
                                                                                        placeholder="Enter password"
                                                                                        invalid={submit && showPasswordValidation.errors.password ? true : false}
                                                                                    />
                                                                                    <div className="hstack gap-2 justify-content-end ">
                                                                                        <button type="submit" className="btn btn-primary py-1 d-flex align-items-center" onClick={() => { setSubmit(true); }}>
                                                                                            {showPasswordValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </Form>}

                                                                        {validation?.values?.authentication_type === "Two-factor" && passwordVerified && !OTPVerfied &&
                                                                            <Form onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                otpValidation.handleSubmit();
                                                                                return false;
                                                                            }}>
                                                                                <div className="mb-2 d-flex align-items-center">
                                                                                    <Input type="text" className="form-control px-0 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" id="otp" name='otp'
                                                                                        onChange={otpValidation.handleChange}
                                                                                        onBlur={otpValidation.handleBlur}
                                                                                        placeholder="Enter otp"
                                                                                        invalid={submit && otpValidation.errors.otp ? true : false}
                                                                                    />
                                                                                    <div className="hstack gap-2 justify-content-end ">
                                                                                        <button type="submit" className="btn btn-primary py-1 d-flex align-items-center" onClick={() => { setSubmit(true); }}>
                                                                                            {otpValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </Form>
                                                                        }

                                                                        {validation?.values?.authentication_type === "Google" && passwordVerified && !codeVerified && <>

                                                                            <Form onSubmit={(e) => {
                                                                                e.preventDefault();
                                                                                codeValidation.handleSubmit();
                                                                                return false;
                                                                            }}>
                                                                                <div className="mb-2 d-flex align-items-center">
                                                                                    <Input type="number" className="form-control px-0 py-1 border-bottom-1 rounded-0 border-top-0 border-end-0 border-start-0" id="totp" name='totp'
                                                                                        onChange={(e) => {
                                                                                            let inputValue = e.target.value;
                                                                                            if (inputValue.length > 6) {
                                                                                                inputValue = inputValue.slice(0, 6);
                                                                                            }
                                                                                            codeValidation.handleChange({
                                                                                                target: {
                                                                                                    name: 'totp',
                                                                                                    value: inputValue,
                                                                                                },
                                                                                            });
                                                                                        }}
                                                                                        onBlur={codeValidation.handleBlur}
                                                                                        value={codeValidation.values.totp || ""}
                                                                                        placeholder="Enter totp"
                                                                                        invalid={codeValidation.touched.totp && codeValidation.errors.totp ? true : false}
                                                                                    />

                                                                                    <div className="hstack gap-2 justify-content-end ">
                                                                                        <button type="submit" className="btn btn-primary py-1 d-flex align-items-center" onClick={() => { setSubmit(true); }}>
                                                                                            {codeValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>
                                                                                    </div>
                                                                                </div>
                                                                            </Form>
                                                                        </>
                                                                        }
                                                                    </>
                                                                    : validation?.values[item.name]
                                                            }
                                                        </div>
                                                    </Col>
                                                )
                                            })}


                                            {validation?.values?.password_user?.filter(item => item.created_by === userDetails?.id)[0] &&
                                                validation?.values?.password_user?.filter(item => (item?.user_id !== userDetails?.id))?.length > 0 && <Col lg={12}>

                                                    <div className="mb-3">
                                                        <Label className="form-label fw-bold"> Invite List  </Label> <br />


                                                        <Row className="row  gy-1 mb-1  mt-1  px-2 ">
                                                            <Col lg={12} className='table px-1 mb-0' >
                                                                <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-light border-0  rounded-0">
                                                                    <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                                                        <div className="row align-items-center px-0 py-2 d-none d-md-flex ">
                                                                            <div className="col-12 col-md-11  px-2">
                                                                                <h5 className="fs-15 mb-0 px-1"> User</h5>
                                                                            </div>


                                                                            <div className="col-2 col-md-1 d-flex justify-content-end px-3">
                                                                                <h5 className="fs-15 mb-0">Action</h5>
                                                                            </div>
                                                                        </div>
                                                                    </CardBody>
                                                                </Card>
                                                            </Col>
                                                        </Row>


                                                        <Row className="row gy-1 mb-1" id="setting-list">
                                                            {validation?.values?.password_user?.filter(item => (item?.user_id !== userDetails?.id))?.map((item, index) => {
                                                                return (
                                                                    <Col lg={12} key={index} className='mt-0 '  >
                                                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                                                            <CardBody className="px-2 py-0">
                                                                                <div className="d-lg-flex align-items-center row px-2">
                                                                                    <div className="col-9  px-1 px-md-2">
                                                                                        <p className="mb-0 ">    {item.user?.[0]?.name} </p>
                                                                                    </div>


                                                                                    <div className="col-3 mb-1  mx-auto d-flex gap-2 align-items-center justify-content-end px-1 px-md-2">
                                                                                        {/* <button type="button" className="btn btn-icon " style={{ height: 24, width: 24 }}
                                                                                        onClick={(e) => {
                                                                                            setUserId(item.user?.[0]?.id);
                                                                                            //   removeInvite(validation?.values?.id, item.user?.[0]?.id);
                                                                                            setDeleteInvite(true)

                                                                                        }}>
                                                                                        <i class="ri-delete-bin-line"></i>

                                                                                    </button> */}

                                                                                        <div className="form-check form-switch form-switch-left form-switch-md">

                                                                                            <Input
                                                                                                type="switch"
                                                                                                name="status"
                                                                                                onChange={(e) => {
                                                                                                    setUserId(item.user?.[0]?.id);
                                                                                                    setDeleteInvite(true);
                                                                                                    setStatus(e.target.checked ? "Access" : "Revoke");
                                                                                                }}

                                                                                                defaultValue={item.status}
                                                                                                checked={item.status}
                                                                                            />

                                                                                            {item.status ? "Access" : "Revoke"}

                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </CardBody>
                                                                        </Card>
                                                                    </Col>
                                                                );
                                                            })}

                                                            <Modal isOpen={deleteInvite} toggle={() => { setDeleteInvite(false) }} centered={true}>
                                                                <ModalHeader className='bg-info-subtle p-3 pb-2' toggle={() => { setDeleteInvite(false); }}  >   {"Are you sure?"}  </ModalHeader>

                                                                <ModalBody  >

                                                                    <p className="text-muted mb-0"> {status === "Revoke" ? "Are you sure you want to remove this Invite?" : "Are you sure you want to Reinvite?"} </p>


                                                                </ModalBody>


                                                                <ModalFooter>
                                                                    <div className="hstack gap-2 justify-content-end">

                                                                        <Button color="success" onClick={() => status === "Revoke" ? removeInvite(validation?.values?.id, userId) : reInvite(validation?.values?.id, userId)}> Yes </Button>
                                                                    </div>
                                                                </ModalFooter>
                                                            </Modal>


                                                        </Row>
                                                    </div>
                                                </Col>}


                                        </Row>

                                    </>
                                        : view === "Category" ?
                                            <>
                                                <FormPasswordCategory validation={categoryValidation} submit={submit} setSubmit={setSubmit}
                                                    customFields={categoryCustomFields} setCustomFields={setCategoryCustomFields} />

                                            </> :

                                            <FormPassword validation={validation} otpValidation={otpValidation} showPasswordValidation={showPasswordValidation}
                                                codeValidation={codeValidation}
                                                submit={submit} setSubmit={setSubmit} isEdit={isEdit}
                                                categoryList={categoryList} setQRCodeData={setQRCodeData} qrCodeData={qrCodeData} selectedCountry={selectedCountry}
                                                categoryCustomFields={categoryCustomFields} customFields={customFields}
                                                handleRemoveCustomFields={handleRemoveCustomFields} categoryValidation={categoryValidation}
                                                setCategoryCustomFields={setCategoryCustomFields} setView={setView}
                                                setCustomFields={setCustomFields}

                                                setOpen={setOpen}
                                                passwordShow={passwordShow} password={password}
                                                passwordVerified={passwordVerified} setPasswordVerified={setPasswordVerified}
                                                setPasswordShow={setPasswordShow} OTPVerfied={OTPVerfied}
                                                setOTPVerfied={setOTPVerfied} codeVerified={codeVerified} setCodeVerified={setCodeVerified}

                                            />
                                    }


                                    <div className="modal fade" tabIndex="-1" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <Modal size="lg" isOpen={modalFile} toggle={() => {
                                                setModalFile(false);
                                            }} centered>
                                                <ModalHeader className='bpBottom pb-2' toggle={() => {
                                                    setModalFile(false);
                                                }}  >
                                                    {"View Attachment"}
                                                </ModalHeader>
                                                <ModalBody >
                                                    {modalFile && <>
                                                        <div className='text-center py-4' >
                                                            {imageExtensions.has(getExtension(fileUrl.path)?.toLowerCase()) ?
                                                                <>
                                                                    <h4>{fileUrl.name}</h4>
                                                                    <img
                                                                        src={fileUrl.path}
                                                                        alt={fileUrl.name}
                                                                        width={isDesktop ? 500 : "100%"}
                                                                        height={"100%"}

                                                                    /></>
                                                                : pdfExtensions.has(getExtension(fileUrl.path)?.toLowerCase()) ?
                                                                    <>  <p>{fileUrl.name}</p>
                                                                        <iframe src={fileUrl.path} className='iframe' /> </>
                                                                    : excelExtensions.has(getExtension(fileUrl.path)?.toLowerCase()) ?
                                                                        <>  {fileUrl.name}</>
                                                                        : <>  {fileUrl.name}</>}
                                                        </div>
                                                    </>}

                                                    <div className='pt-2 text-center' >
                                                        <button className="btn btn-primary text-white shadow-lg">
                                                            <a className='btn-primary' href={fileUrl.path} target='_blank' download>  Download</a>
                                                        </button>
                                                    </div>

                                                </ModalBody>
                                            </Modal>
                                        </div>
                                    </div>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Passwords;