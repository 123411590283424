import React, { useState, useEffect, useCallback } from 'react';
import {
    Button, Card, CardBody, Offcanvas, OffcanvasHeader,
    OffcanvasBody, Col, Container, Form, Input, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader
} from 'reactstrap';
import DeleteModal from "../../Components/Common/DeleteModal";
import { ToastContainer, toast } from 'react-toastify';
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import { useSelector, } from 'react-redux';
import * as Yup from "yup";
import { useFormik } from "formik";
import { createSelector } from 'reselect';
import { useMediaQuery } from 'react-responsive';
// import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";
import BreadCrumb from '../../Components/Common/BreadCrumb';
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { HiOutlineCheck } from "react-icons/hi2";
import { IoCloseOutline } from "react-icons/io5";

const User = () => {
    document.title = "User";
    const [modalChange, setModalChange] = useState(false);
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [submit, setSubmit] = useState(false);
    const [searchList, setSearchList] = useState([]);
    const selectuserData = createSelector(
        (state) => state.Team.userData,
        (userData) => userData
    );
    // Inside your component
    const userData = useSelector(selectuserData);
    const [deleteModal, setDeleteModal] = useState(false);
    // const [roleList, setRoleList] = useState([]);
    const [userList, setUserList] = useState([]);
    const [userView, setUserView] = useState([]);
    //Modal  
    const [teamMem, setTeamMem] = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const [passwordShow, setPasswordShow] = useState(false);

    const getUsers = async () => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`user?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setUserList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setUserList([]);
                setSearchList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const getUserPage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`user?page=${currentPage}&perPage=${rowPerPage}`);
            if (response.data.status === true) {
                setSearchList(response.data?.data);
                setUserList(response.data?.data);
            } else {
                setSearchList([]);
                setUserList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const viewUser = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('user/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
                setTeamMem(response.data?.data);
            } else {
                setTeamMem([]);
            }
            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }

    };

    useEffect(() => {
        getUsers();
        // getRole();
    }, [userData]);


    const addUsers = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('user', value);
            if (response.data.status === true) {
                getUsers();
                setOpen(false);
                setIsEdit(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const editUsers = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.put('user/' + value.id, value);

            if (response.data.status === true) {
                getUsers();
                setOpen(false);
                setIsEdit(false);
                validation.resetForm();
                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }

    };

    const deleteUsers = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('user/' + value?.id, {});
            if (response.data.status === true) {
                getUsers();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }

    };


    const handleEditClick = useCallback((arg) => {
        //validation.setValues({}); 
        //setTeamMem(arg);
        setSubmit(false);
        viewUser(arg?.id);
        setIsEdit(true);
    }, []);

    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        getUserPage(currentPage - 1, rowPerPage);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        getUserPage(currentPage + 1, rowPerPage);
    };

    // const handleTeamShow = useCallback((arg) => {
    //     viewUser(arg?.id);
    //     //setTeamMem(arg);
    //     setModalView(true);
    // }, [ ]);

    const handleAddClick = () => {
        setSubmit(false);
        validation.resetForm();
        validation.setValues({});

        setIsEdit(false);
        toggleDrawer();
    };

    // delete
    // const onClickData = () => { 
    //     setDeleteModal(true);
    // };

    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.user?.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.email?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.mobile?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item.roles?.[0]?.name?.toLowerCase().includes(inputVal.toLowerCase())
            );

            setUserList({
                ...userList,
                data: filteredResults
            });

        } else {
            setUserList({
                ...userList,
                data: searchList?.data
            });
        }
    };


    // validation
    const validation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            name: '',
            email: '',
            mobile: '',
            password: '',
            roles: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            name: Yup.string().required("Please Enter Your Username"),
            mobile: Yup.string().notRequired(),
            password: isEdit ? Yup.string() : Yup.string().required("Please enter your password"),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editUsers({
                    id: teamMem?.id,
                    name: values?.name,
                    email: values?.email,
                    mobile: values?.mobile,
                    roles: parseInt(values?.role_id)
                });
            } else {
                addUsers({
                    name: values?.name,
                    email: values?.email,
                    mobile: values?.mobile,
                    password: values?.password,
                    roles: parseInt(values?.role_id),
                });
            }
        },
    });

    const confirmPassword = async (values) => {

        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'application/json';

        try {
            const response = await api.post('changePassword', {
                email: values?.email,
                password: values.password,
            });

            if (response.data.status === true) {
                passwordValidation.resetForm();
                setModalChange(false);
                ToastMessage("success", response.data?.message);


            } else {
                ToastMessage("error", response.data?.message);
            }
            passwordValidation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }
    };

    const passwordValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            email: '',
            password: '',
            confirm: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password"),
            confirm: Yup.string()
                .required("Please Enter Your Confirm Password")
                .oneOf([Yup.ref('password'), null], 'Passwords must match'),
        }),
        onSubmit: (values) => {
            confirmPassword(values);
        },
    });


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={userView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteUsers(userView)}
                onCloseClick={() => setDeleteModal(false)}
            />
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="User" pageTitle="User" />


                    <Row>
                        <Col xs={12} className='d-block d-md-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center  justify-content-between justify-content-md-end gap-2'>
                                <Button type="button" color="primary" outline className="py-1 " onClick={() => { handleAddClick(); }} >  ADD  </Button>
                                <div className="search-box">
                                    <Input type="text" className="form-control py-1" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </div>
                            <div className='d-flex gap-1  align-items-center justify-content-between justify-content-md-end mt-2' >
                                <div className='d-flex align-items-center'>
                                    {(userList?.from || 1) + " - " + (userList?.to || 1) + " of " + (userList?.total || 1)}
                                    <div>
                                        <button className='border-0 bg-white pe-0' onClick={handlePrevPage} disabled={userList?.current_page === 1}>
                                            <MdOutlineKeyboardArrowLeft size={25} />
                                        </button>
                                        <button className='border-0 bg-white ps-0' onClick={handleNextPage} disabled={userList?.current_page === userList?.last_page}>
                                            <MdOutlineKeyboardArrowRight size={25} />
                                        </button>
                                    </div>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    Shows
                                    <select className="pagination-prev disabled  h-100 px-1 py-1" type="select" name="perPage" id="perPage"
                                        onChange={(e) => {
                                            setRowPerPage(e.target.value);
                                            getUserPage(1, e.target.value);
                                        }}
                                        value={rowPerPage} >
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={userList?.total}>All</option>
                                    </select>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-light border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-3 px-1">
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>

                                        <div className="d-flex col-3 mt-0 align-items-center  d-flex justify-content-end  justify-content-md-start px-md-2">

                                            <h5 className="fs-15 mb-0">Email</h5>
                                        </div>

                                        <div className="d-flex  col-3 mt-0 align-items-center d-flex justify-content-end  justify-content-md-start">

                                            <h5 className="fs-15 mb-0">Mobile</h5>

                                        </div>

                                        <div className="  col-12 col-md-2 align-items-center ">

                                            <h5 className="fs-15 mb-0" >Role</h5>

                                        </div>

                                        <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end ">
                                            <h5 className="fs-15 mb-0">  Actions</h5>

                                        </div>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>

                    </Row>


                    <Row className="row gy-1 mb-1" id="user-list">
                        {
                            userList?.data?.length !== 0 ?

                                (userList?.data)?.map((item, index) => (

                                    <Col lg={12} key={index} className='mt-0 '  >

                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1 ">
                                            <CardBody className="px-1 py-0">

                                                <div className="d-lg-flex align-items-center row">

                                                    <div className="col-7 col-md-3">

                                                        {item.name}

                                                    </div>


                                                    <div className="col-12 col-md-3 align-items-center">

                                                        <p className="mb-0">  {item.email}</p>

                                                    </div>

                                                    <div className="col-7 col-md-3 align-items-center">

                                                        <p className="mb-0">  {item.mobile}</p>

                                                    </div>



                                                    <div className=" d-flex col-5 col-md-2 align-items-center d-flex justify-content-end justify-content-md-start">

                                                        <p className="mb-0">  {item.roles?.[0]?.name}</p>
                                                    </div>


                                                    <div className=" col-12 col-md-1 d-flex gap-0 align-items-center justify-content-end">

                                                        <button type="button" className="btn  btn-sm btn-icon btn25"
                                                            onClick={(e) => { handleEditClick(item); }}>
                                                            <i className="ri-pencil-fill fs-18 primary"  ></i>
                                                        </button>

                                                        <button type="button" className="btn  btn-sm btn-icon btn25"
                                                            onClick={(e) => {
                                                                setModalChange(!modalChange); setPasswordShow(false);

                                                                passwordValidation.resetForm();
                                                                passwordValidation.setValues(item);
                                                            }}>
                                                            <i className="mdi mdi-account-key fs-16 primary"></i>
                                                        </button>

                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>


                                ))

                                :
                                <div className="noresult" >
                                    <div className="text-center">

                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                        {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                    </div>
                                </div>
                        }
                    </Row>




                    <div className="modal fade" tabIndex="-1" aria-hidden="true" >
                        <div className="modal-dialog modal-dialog-centered"    >
                            <Modal size="md" backdrop={"static"} isOpen={modalChange} toggle={() => { setModalChange(!modalChange) }} centered>
                                <ModalHeader className='bg-info-subtle p-3 pb-2' toggle={() => { setModalChange(!modalChange) }} > {"Change Password"}  </ModalHeader>
                                <Form onSubmit={(e) => { e.preventDefault(); passwordValidation.handleSubmit(); return false; }} action="#">
                                    <ModalBody>
                                        <Row>
                                            <Col xs={12} >
                                                <div className="mb-3">
                                                    <Label htmlFor="password" className="form-label">Password
                                                        <span className='secondary'>*</span> </Label>
                                                    <Input type={passwordShow ? "text" : "password"} className="form-control px-2 py-1" id="password" placeholder="Enter password" name='password'
                                                        validate={{ required: { value: true } }}
                                                        onChange={passwordValidation.handleChange}
                                                        onBlur={passwordValidation.handleBlur}
                                                        value={passwordValidation.values.password || ""}
                                                        invalid={passwordValidation.touched.password && passwordValidation.errors.password ? true : false}
                                                    />

                                                    <button
                                                        className="btn btn-link position-absolute top-50 end-0 pt-1 px-4 mt-2 translate-middle-y text-decoration-none text-muted"
                                                        onClick={() => setPasswordShow(!passwordShow)} type="button"  >
                                                        <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                                    </button>
                                                </div>
                                            </Col>

                                            <Col xs={12}>
                                                <div className="mb-3">
                                                    <Label htmlFor="confirm" className="form-label">Confirm Password <span className='secondary'>*</span> </Label>
                                                    <Input type={passwordShow ? "text" : "password"} className="form-control px-2 py-1" id="confirm" placeholder="Enter confirm password" name='confirm'
                                                        validate={{ required: { value: true } }}
                                                        onChange={passwordValidation.handleChange}
                                                        onBlur={passwordValidation.handleBlur}
                                                        value={passwordValidation.values.confirm || ""}
                                                        invalid={passwordValidation.touched.confirm && passwordValidation.errors.confirm ? true : false}
                                                    />

                                                    <button
                                                        className="btn btn-link position-absolute top-50 end-0 pt-1 px-4 mt-2 translate-middle-y text-decoration-none text-muted"
                                                        onClick={() => setPasswordShow(!passwordShow)}
                                                        type="button" >
                                                        <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                                    </button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </ModalBody >

                                    <ModalFooter>
                                        <div className="hstack gap-2 justify-content-end">
                                            <Button color="light" onClick={() => { setModalChange(false); }} > Cancel </Button>
                                            <Button type="submit" color="success" > Save  </Button>
                                        </div>
                                    </ModalFooter>
                                </Form>
                            </Modal>
                        </div>
                    </div>




                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-light p-3" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2">{!isEdit ? "Add User" : "Update User"}</span>
                                <span>
                                    <span className="fs-18 px-1" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>
                                        <HiOutlineCheck className='cursor' /></span>
                                    <span className="fs-18 cursor" onClick={() => { toggleDrawer() }}>   <IoCloseOutline /> </span>
                                </span>

                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="px-4 py-3">
                                    <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="teammembersName" className="form-label">Name <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="teammembersName" placeholder="Enter name"
                                                        name='name'
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.name || ""}
                                                        invalid={
                                                            submit && validation.errors.name ? true : false
                                                        }
                                                    />

                                                </div>
                                            </Col>



                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="teammembersEmail" className="form-label">Email <span className='secondary'>*</span></Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="teammembersEmail" placeholder="Enter email"
                                                        name='email'
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.email || ""}
                                                        invalid={
                                                            submit && validation.errors.email ? true : false
                                                        }
                                                    />

                                                </div>

                                            </Col>

                                            {!isEdit &&
                                                // <Col lg={6}>
                                                //     <div className="mb-3">
                                                //         <Label htmlFor="password" className="form-label">Password <span className='secondary'>*</span></Label>
                                                //         <Input type="text" className="form-control px-2 py-1" id="password" placeholder="Enter password" name='password'
                                                //             validate={{
                                                //                 required: { value: true },
                                                //             }}
                                                //             onChange={validation.handleChange}
                                                //             onBlur={validation.handleBlur}
                                                //             value={validation.values.password || ""}
                                                //             invalid={
                                                //                 submit && validation.errors.password ? true : false
                                                //             }
                                                //         />

                                                //     </div>
                                                // </Col>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="password" className="form-label">Password
                                                            <span className='secondary'>*</span> </Label>
                                                        <Input type={passwordShow ? "text" : "password"} className="form-control px-2 py-1" id="password" placeholder="Enter password" name='password'
                                                            validate={{ required: { value: true } }}
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.password || ""}
                                                            invalid={validation.touched.password && validation.errors.password ? true : false}
                                                        />

                                                        <button
                                                            className="btn btn-link position-absolute top-50 end-0 pt-1 px-4 mt-2 translate-middle-y text-decoration-none text-muted"
                                                            onClick={() => setPasswordShow(!passwordShow)} type="button"  >
                                                            <i className={`${passwordShow ? "ri-eye-fill" : "ri-eye-off-fill"} align-middle fs-20`}></i>
                                                        </button>
                                                    </div>
                                                </Col>
                                            }
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="mobile" className="form-label">Mobile</Label>
                                                    <Input type="number" className="form-control px-2 py-1" id="mobile" placeholder="Enter mobile" name='mobile'
                                                        validate={{
                                                            required: { value: true },
                                                        }}
                                                        onFocus={(e) => e.target.addEventListener("wheel", function (e) { e.preventDefault() }, { passive: false })}

                                                        onChange={(e) => {
                                                            let inputValue = e.target.value;
                                                            if (inputValue.length > 10) {
                                                                inputValue = inputValue.slice(0, 10);
                                                            }

                                                            validation.handleChange({
                                                                target: {
                                                                    name: 'mobile',
                                                                    value: inputValue,
                                                                },
                                                            });

                                                        }
                                                        }
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.mobile || ""}
                                                        invalid={
                                                            submit && validation.errors.mobile ? true : false
                                                        }
                                                    />

                                                </div>
                                            </Col>


                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    <button type="submit" className="btn btn-primary shadow-lg" id="addNewMember" onClick={() => { setSubmit(true); }}>{!isEdit ? "Add User" : "Save"}</button>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default User;