import React, { useState, useEffect, useCallback } from 'react';
import {
    Card, CardBody, Col, Container, FormGroup, Offcanvas,
    OffcanvasHeader, Spinner, Button,
    OffcanvasBody, Form, Input, Label, Modal, ModalBody, Row, UncontrolledDropdown, FormFeedback
} from 'reactstrap';
import { ToastContainer, toast } from 'react-toastify';
import * as Yup from "yup";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import SimpleBar from "simplebar-react";
import { useMediaQuery } from 'react-responsive';
import { HiOutlineCheck } from "react-icons/hi2";
import { IoCloseOutline } from "react-icons/io5";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
// import Pagination from "react-js-pagination";
import { ToastMessage } from "../../Components/Hooks/Function";
import { getLoggedinUser, getSettingsDetails } from "../../helpers/api_helper";

const Setting = () => {
    document.title = "SMS Setting";
    const userDetails = getLoggedinUser()?.user;
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [isEdit, setIsEdit] = useState(false);
    const [loading, setLoading] = useState(false);
    const [settingList, setSettingList] = useState([]);
    //  const [searchList, setSearchList] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);
    const [submit, setSubmit] = useState(false);
    const [decryptedValue, setDecryptedValue] = useState([]);
    const [Index, setIndex] = useState(null);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleEditClick = useCallback((arg) => {
        setIndex("");
        viewSetting(arg?.id);
        setIsEdit(true);
    }, []);



    const showPasswordValidation = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
        initialValues: {
            password: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password")
        }),
        onSubmit: async (values) => {
            try {
                await SMSShowDecrypt(values);

            } catch (err) {
                console.error(err.message);
            }
        }
    });


    const SMSShowDecrypt = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post(`SMSShowDecrypt/${settingList?.data?.[Index]?.id}`, {
                ...values,
                email: userDetails.email
            });
            if (response.data.status === true) {
                const newdecryptedValue = [...decryptedValue];
                newdecryptedValue[Index] = response.data.data;
                setDecryptedValue(newdecryptedValue);
            } else {
                ToastMessage("error", "Incorrect password");
            }
        } catch (err) {
            console.error(err.message);
            ToastMessage("error", err.message);
        }
    }

    // const keywordSearch = async (e) => {
    //     let inputVal = e;

    //     if (inputVal !== "") {
    //         const filteredResults = searchList?.data?.filter(item =>
    //             item.text?.toLowerCase().includes(inputVal.toLowerCase()) ||
    //             item.value?.toLowerCase().includes(inputVal.toLowerCase())
    //         );

    //         setSettingList({
    //             ...settingList,
    //             data: filteredResults
    //         });

    //     } else {
    //         setSettingList(searchList);
    //     }
    // };
    // const handlePrevPage = () => {
    //     setCurrentPage(currentPage - 1);
    //     getSettingPage(currentPage - 1, rowPerPage);
    // };

    // const handleNextPage = () => {
    //     setCurrentPage(currentPage + 1);
    //     getSettingPage(currentPage + 1, rowPerPage);
    // };


    useEffect(() => {
        setDecryptedValue(settingList?.data?.map(() => ""))
    }, [settingList]);


    const getSetting = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`smsSetting?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setSettingList(response.data?.data);
                // setSearchList(response.data?.data);
            } else {
                setSettingList([]);
                // setSearchList([]);
                ToastMessage("error", response.data?.message);
            }
            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }

    };

    const getSettingPage = async (currentPage, rowPerPage) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {

            const response = await api.get(`smsSetting?page=${currentPage}&perPage=${rowPerPage}`, {});
            if (response.data.status === true) {
                setSettingList(response.data?.data);
                //     setSearchList(response.data?.data);

            } else {
                setSettingList([]);
                // setSearchList([]);
                ToastMessage("error", response.data?.message);
            }

        } catch (err) {
            console.error(err.message);
        }
    };


    const viewSetting = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('smsSetting/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
            }
            setOpen(true);

        } catch (err) {
            console.error(err.message);
        }

    };

    useEffect(() => {
        getSetting();
    }, []);


    const addSettings = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.post('smsSetting', values);
            if (response.data.status === true) {
                getSetting();
                smsValidation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const editSettings = async (values, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.post['Content-Type'] = 'multipart/form-data';
        try {
            const formData = new FormData();
            formData.append('_method', "PUT");
            formData.append('text', values.text);
            formData.append('value', values.value);
            const response = await api.post('smsSetting/' + id, formData);
            if (response.data.status === true) {
                getSetting();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
        } catch (err) {
            console.error(err.message);
        }
    };

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            text: '',
            value: '',
        },
        validationSchema: Yup.object({
            text: Yup.string().required("Please Enter"),
            value: Yup.string().notRequired(),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editSettings(values, validation.values?.id);
            }
        },
    });

    // const handleClick = (e) => {
    //     setCurrentPage(e);
    //     getSettingPage(e, rowPerPage);
    // };


    const smsValidation = useFormik({
        enableReinitialize: true,
        initialValues: {
            twilio_sid: '',
            twilio_token: '',
            twilio_from: '',
        },
        validationSchema: Yup.object({
            twilio_sid: Yup.string().required('Enter'),
            twilio_token: Yup.string().required('Enter'),
            twilio_from: Yup.string().required('Enter'),
        }),
        onSubmit: (values) => {
            addSettings(values);
        },
    });

    const handleAddClick = () => {
        setSubmit(false);
        smsValidation.resetForm();
        smsValidation.setValues({});
        setIsEdit(false);
        toggleDrawer();
    };


    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <div className="mb-2  page-title-box borderColor d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-0 primary fw-bold px-3 px-md-0">SMS Settings</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} className='d-block d-md-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center  justify-content-between justify-content-md-end gap-2'>
                                {(settingList?.data?.length === 0) && <Button type="button" color="primary" outline className="py-1" onClick={() => { handleAddClick(); }} >  ADD  </Button>}

                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1 px-2"  >
                        <Col lg={12} className='table px-1  mb-0'  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-light border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-7 col-md-4 px-2"  >
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>
                                        <div className="d-flex   col-6 col-md-7  mt-0 align-items-center  justify-content-end  justify-content-md-start">
                                            <h5 className="fs-15 mb-0">Value</h5>
                                        </div>

                                        <div className="mb-1 col-12 col-md-1 d-flex gap-2 align-items-center justify-content-end ">
                                            <h5 className="fs-15 mb-0">  Actions</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row gy-1 mb-1" id="setting-list">
                        {loading ?
                            <div className='text-center'>
                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>
                            :
                            settingList?.data?.length !== 0 ?
                                (settingList?.data)?.map((item, index) => (
                                    <Col lg={12} key={index} className='mt-0' >
                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                            <CardBody className="px-2 py-0">
                                                <div className="d-lg-flex align-items-center row">
                                                    <div className="col-6 col-md-4 align-items-center  ">
                                                        <p className="mb-0 ">  {item.text}</p>
                                                    </div>

                                                    <div className=" col-6 col-lg-1   d-flex  d-md-none  gap-0 align-items-center justify-content-end">
                                                        <button type="button" className="btn  btn-sm btn-icon "
                                                            onClick={(e) => { handleEditClick(item); }}>
                                                            <HiOutlinePencilAlt className="fs-18 primary" />
                                                        </button>
                                                    </div>
                                                    <div className="col-12 col-md-7 align-items-center  ">
                                                        <p className="mb-0">
                                                            {index === Index && decryptedValue?.[index] ?
                                                                decryptedValue?.[index] + " " :
                                                                (item?.value?.substring(0, 0) + 'x'.repeat(Math.max(item?.value?.length - 5, 0)))?.substring(0, 20)
                                                                + " "}
                                                            <span className='cursor text-muted' key={item.id} onClick={() => {
                                                                setDecryptedValue(settingList?.data?.map(() => ""))
                                                                setSubmit(false); setIndex(Index !== index ? index : !index)
                                                            }}>
                                                                {decryptedValue?.[index] ?
                                                                    <i className="ri-eye-fill align-middle"></i> :
                                                                    <i className="ri-eye-off-fill align-middle"></i>}
                                                            </span>
                                                        </p>
                                                        {
                                                            index === Index && !decryptedValue?.[index] &&
                                                            <Form className="d-flex gap-2 col-12 col-md-4"
                                                                //  style={{ width: "300px"}}
                                                                onSubmit={(e) => {
                                                                    e.preventDefault();
                                                                    showPasswordValidation.handleSubmit();
                                                                    return false;
                                                                }}>

                                                                <Input type="password" className="form-control px-2 py-1" name='password'
                                                                    onChange={showPasswordValidation.handleChange}
                                                                    onBlur={showPasswordValidation.handleBlur}
                                                                    placeholder="Enter password"
                                                                    invalid={submit && showPasswordValidation.errors.password ? true : false}
                                                                />

                                                                <button type="submit" className="btn btn-primary d-flex py-1" onClick={() => { setSubmit(true); }}>
                                                                    {showPasswordValidation.isSubmitting ? <Spinner size="sm" className='me-2'> Loading... </Spinner> : null} {"Show"}</button>


                                                            </Form>
                                                        }
                                                    </div>

                                                    <div className=" col-12 col-md-1 col-lg-1   d-none  d-md-flex  gap-0 align-items-center justify-content-end">
                                                        <button type="button" className="btn  btn-sm btn-icon "
                                                            onClick={(e) => { handleEditClick(item); }}>
                                                            <HiOutlinePencilAlt className="fs-18 primary" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                                :
                                <div className="noresult" >
                                    <div className="text-center">

                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                        {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
                            orders for you search.</p> */}
                                    </div>
                                </div>
                        }
                    </Row>



                    {/* <div className=" d-flex justify-content-end  pagination-wrap hstack gap-2">



                        <div className="col-sm-6 d-flex gap-2 justify-content-end">
                            <select className="pagination-prev disabled  h-100 px-1 py-2" type="select" name="perPage" id="perPage"
                                onChange={(e) => {
                                    setRowPerPage(e.target.value);
                                    getSettingPage(1, e.target.value);
                                }}
                                value={rowPerPage}
                            >

                                <option value={50}>50</option>
                                <option value={100}>100</option>
                                <option value={settingList?.total}>All</option>

                            </select>

                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={50}
                                totalItemsCount={settingList?.total || 0}
                                pageRangeDisplayed={10}
                                onChange={handleClick}
                                itemClass="page-item"
                                linkClass="page-link"
                            />
                        </div>
                    </div> */}

                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`} >
                        <OffcanvasHeader className="d-flex align-items-center bg-light px-3 py-2" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2">{!isEdit ? "Add SMS Settings" : "Update SMS Settings"}</span>
                                <span className="fs-18 px-1">
                                    <HiOutlineCheck className='cursor' onClick={() => { validation.handleSubmit(); }} />
                                    <span className="fs-18 cursor" onClick={() => { toggleDrawer() }}>   <IoCloseOutline /> </span>
                                </span>
                            </span>

                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="p-4">

                                    {isEdit ? <Form onSubmit={(e) => {
                                        e.preventDefault();
                                        validation.handleSubmit();
                                        return false;
                                    }}>
                                        <Row>
                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="text" className="form-label">Name</Label>
                                                    <Input type="text" className="form-control px-2 py-1" id="text" name='text'
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.text || ""}
                                                        invalid={
                                                            validation.touched.text && validation.errors.text ? true : false
                                                        }
                                                    />
                                                </div>
                                            </Col>


                                            <Col lg={6}>
                                                <div className="mb-3">
                                                    <Label htmlFor="settingName" className="form-label">Value</Label>
                                                    <Input type="password" className="form-control px-2 py-1" id="settingName" name='value'
                                                        onChange={validation.handleChange}
                                                        onBlur={validation.handleBlur}
                                                        value={validation.values.value || ""}
                                                        invalid={
                                                            validation.touched.value && validation.errors.value ? true : false
                                                        }
                                                    />
                                                </div>
                                            </Col>

                                            <Col lg={12}>
                                                <div className="hstack gap-2 justify-content-end">
                                                    {isEdit ? <button type="submit" className="btn btn-primary"  >{"Save"}</button> : ""}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>

                                        :

                                        <Form onSubmit={(e) => {
                                            e.preventDefault();
                                            smsValidation.handleSubmit();
                                            return false;
                                        }}>
                                            <Row>
                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="twilio_sid" className="form-label">Twilio SID</Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="twilio_sid" name='twilio_sid'
                                                            placeholder='Enter twilio sid'
                                                            onChange={smsValidation.handleChange}
                                                            onBlur={smsValidation.handleBlur}
                                                            value={smsValidation.values.twilio_sid || ""}
                                                            invalid={smsValidation.touched.twilio_sid && smsValidation.errors.twilio_sid ? true : false}
                                                        />
                                                    </div>
                                                </Col>

                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="twilio_token" className="form-label">Twilio Token</Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="twilio_token" name='twilio_token'
                                                            placeholder='Enter twilio token'
                                                            onChange={smsValidation.handleChange}
                                                            onBlur={smsValidation.handleBlur}
                                                            value={smsValidation.values.twilio_token || ""}
                                                            invalid={smsValidation.touched.twilio_token && smsValidation.errors.twilio_token ? true : false}
                                                        />
                                                    </div>
                                                </Col>


                                                <Col lg={6}>
                                                    <div className="mb-3">
                                                        <Label htmlFor="twilio_from" className="form-label">Twilio Phone Number</Label>
                                                        <Input type="text" className="form-control px-2 py-1" id="twilio_phone_number" name='twilio_from'
                                                            placeholder='Enter twilio phone number'
                                                            onChange={smsValidation.handleChange}
                                                            onBlur={smsValidation.handleBlur}
                                                            value={smsValidation.values.twilio_from || ""}
                                                            invalid={smsValidation.touched.twilio_from && smsValidation.errors.twilio_from ? true : false}
                                                        />
                                                    </div>
                                                </Col>


                                                <Col lg={12}>
                                                    <div className="hstack gap-2 justify-content-end">
                                                        <button type="submit" className="btn btn-primary"  >{"Save"}</button>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Form>
                                    }


                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Setting;