import React, { useState, useEffect, useCallback } from 'react';
import {
    Button, Card, CardBody, Nav, FormGroup, NavItem,
    NavLink, Offcanvas, OffcanvasHeader, OffcanvasBody, TabContent, Spinner,
    TabPane, ModalFooter, ModalHeader, Col, Container, Form, Input, Label, Modal, ModalBody, Row,
} from 'reactstrap'; import BreadCrumb from '../../Components/Common/BreadCrumb';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { FaCheck, FaTimesCircle, FaTimes, FaPlusCircle, FaFilePdf, FaFileAlt, FaFileExcel } from "react-icons/fa";
import SimpleBar from "simplebar-react";
import { useFormik } from "formik";
import { api, url } from "../../helpers/api";
import * as Yup from "yup";
import Select from "react-select";
import { ToastContainer, toast } from 'react-toastify';
import DeleteModal from "../../Components/Common/DeleteModal";
import { IoCloseOutline } from "react-icons/io5";
import { HiOutlinePencilAlt, HiOutlineTrash } from "react-icons/hi";
import { HiOutlineCheck } from "react-icons/hi2";
import { MdOutlineKeyboardArrowRight } from "react-icons/md";
import { MdOutlineKeyboardArrowLeft } from "react-icons/md";
 
import { ToastMessage, DefaultDate, SmallDateFormat, FormatDate, snakeToCamel } from "../../Components/Hooks/Function";
 
import FormDocumentCategory from './FormDocumentCategory';

const Category = () => {
    document.title = "Document Categories";
    const isDesktop = useMediaQuery({ minWidth: 768 });
    const placement = isDesktop ? 'end' : 'start';
    const [isExpanded, setIsExpanded] = useState(false);
    const [deleteModal, setDeleteModal] = useState(false);
    const [submit, setSubmit] = useState(false);
    const [categoryView, setCategoryView] = useState([]);
    const [open, setOpen] = useState(false);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const [loading, setLoading] = useState(false);

    const [searchList, setSearchList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowPerPage, setRowPerPage] = useState(50);

    const handleAddClick = (arg) => {
        setSubmit(false);
        validation.resetForm();
        validation.setValues({});
        setIsEdit(false);
        toggleDrawer();
    };

    const handleEditClick = useCallback((arg) => {
        setSubmit(false);
        viewCategory(arg?.id);
        validation.resetForm();
        setIsEdit(true);
    }, []);

    const onClickData = () => {
        setDeleteModal(true);
    };

    const [categoryList, setCategoryList] = useState([]);

    const keywordSearch = async (e) => {
        let inputVal = e;
        if (inputVal !== "") {
            const filteredResults = searchList?.data?.filter(item =>
                item.name?.toLowerCase().includes(inputVal.toLowerCase()) ||
                item?.description?.toLowerCase().includes(inputVal.toLowerCase())
            );

            setCategoryList({
                ...categoryList,
                data: filteredResults
            });

        } else {
            setCategoryList({
                ...categoryList,
                data: searchList?.data
            });
        }
    };

    const getCategory = async () => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`documentCategory`);
            if (response.data.status === true) {
                setCategoryList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
                setCategoryList([]);
                setSearchList([]); 
                ToastMessage("error", response.data?.message);
            }
            setCurrentPage(1);
            setLoading(false);
        } catch (err) {
            console.error(err.message);
            if (err.response?.status === 401) {
                history('/logout');
            }
        }

    };

    const getCategoryPage = async (currentPage, rowPerPage) => {
        setLoading(true);
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get(`documentCategory?page=${currentPage}&perPage=${rowPerPage}`);
            if (response.data.status === true) {
                setCategoryList(response.data?.data);
                setSearchList(response.data?.data);
            } else {
               
                setCategoryList([]);
                setSearchList([]);
                ToastMessage("error", response.data?.message);
            }

            setLoading(false);
        } catch (err) {
            console.error(err.message);
        }
    };


    const viewCategory = async (id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.get('documentCategory/' + id, {});
            if (response.data.status === true) {
                validation.setValues(response.data?.data);
                setCategoryView(response.data?.data);
            } else {
                setCategoryView([]);
            }
            setOpen(true);
        } catch (err) {
            console.error(err.message);
        }
    };

    useEffect(() => {
        getCategory();
    }, []);


    const addCategory = async (values) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.post('documentCategory', values);
            if (response.data.status === true) {
                getCategory();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);

            } else {
                ToastMessage("error", response.data?.message);
            }
            validation.setSubmitting(false);
        } catch (err) {
            console.error(err.message);
        }
    };

    const editCategory = async (values, id) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json';
        try {
            const response = await api.put('documentCategory/' + id, values);
            if (response.data.status === true) {
                getCategory();
                validation.resetForm();
                setIsEdit(false);
                setOpen(false);
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }

            validation.setSubmitting(false);

        } catch (err) {
            console.error(err.message);
        }
    };

    const deleteCategory = async (value) => {
        api.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("token");
        api.defaults.headers.common['Content-Type'] = 'application/json'
        try {
            const response = await api.delete('documentCategory/' + value?.id, {});
            if (response.data.status === true) {
                getCategory();
                ToastMessage("success", response.data?.message);
            } else {
                ToastMessage("error", response.data?.message);
            }
            setDeleteModal(false)

        } catch (err) {
            console.error(err.message);
        }
    };


    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required("Please name"),
            description: Yup.string().notRequired(),
        }),
        onSubmit: (values) => {
            if (isEdit) {
                editCategory(values, validation.values?.id);
            } else {
                addCategory(values);
            }
        },
    });

    const handleClick = (e) => {
        setCurrentPage(e);
        getCategoryPage(e, rowPerPage);
    };

    const handleToggle = (index) => {
        const newExpandedStates = [...isExpanded];
        newExpandedStates[index] = !newExpandedStates[index];
        setIsExpanded(newExpandedStates);
    };

    useEffect(() => {
        setIsExpanded(categoryList?.data?.map(() => false))
    }, [categoryList]);

    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);


    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
        getCategoryPage(currentPage - 1, rowPerPage);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        getCategoryPage(currentPage + 1, rowPerPage);
    };

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                name={categoryView?.name}
                show={deleteModal}
                onDeleteClick={() => deleteCategory(categoryView)}
                onCloseClick={() => setDeleteModal(false)}
            />

            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Document Categories" pageTitle="Pages" />
                    <Row>
                        <Col xs={12} className='d-block d-md-flex justify-content-between align-items-center'>
                            <div className='d-flex align-items-center  justify-content-between justify-content-md-end gap-2'>
                                <Button type="button" color="primary" outline className="py-1" onClick={() => { handleAddClick(); }} >  ADD  </Button>
                                <div className="search-box ">
                                    <Input type="text" className="form-control py-1" placeholder="Search..." onChange={(e) => keywordSearch(e.target.value)} />
                                    <i className="ri-search-line search-icon"></i>
                                </div>
                            </div>

                            <div className='d-flex gap-1  align-items-center justify-content-between justify-content-md-end mt-2' >
                                <div className='d-flex align-items-center'>
                                    {(categoryList?.from || 1) + " - " + (categoryList?.to || 1) + " of " + (categoryList?.total || 1)}
                                    <div>
                                        <button className='border-0 bg-white pe-0' onClick={handlePrevPage} disabled={categoryList?.current_page === 1}>
                                            <MdOutlineKeyboardArrowLeft size={25} />
                                        </button>
                                        <button className='border-0 bg-white ps-0' onClick={handleNextPage} disabled={categoryList?.current_page === categoryList?.last_page}>
                                            <MdOutlineKeyboardArrowRight size={25} />
                                        </button>
                                    </div>
                                </div>
                                <div className='d-flex gap-2 align-items-center'>
                                    Shows
                                    <select className="pagination-prev disabled  h-100 px-1 py-1" type="select" name="perPage" id="perPage"
                                        onChange={(e) => {
                                            setRowPerPage(e.target.value);
                                            getCategoryPage(1, e.target.value);
                                        }}
                                        value={rowPerPage} >
                                        <option value={50}>50</option>
                                        <option value={100}>100</option>
                                        <option value={categoryList?.total}>All</option>
                                    </select>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row className="row  gy-1 mb-1  mt-1  px-2 "  >
                        <Col lg={12} className='table px-1  mb-0 '  >
                            <Card className="mb-0 d-none d-md-flex mt-1 px-1 table-light border-0  rounded-0">
                                <CardBody className="px-1 py-0 px-md-2 py-md-0 border-0">
                                    <div className="row d-lg-flex align-items-center   px-0 py-2 d-none d-md-none d-lg-block">
                                        <div className="col-7 col-md-3  px-2"  >
                                            <h5 className="fs-15 mb-0"> Name</h5>
                                        </div>
                                        <div className="d-flex   col-6 col-md-8  mt-0 align-items-center  justify-content-end  justify-content-md-start">
                                            <h5 className="fs-15 mb-0">Description</h5>
                                        </div>
                                        <div className="mb-1 col-12 col-md-1 col-lg-1  d-flex gap-2 align-items-center justify-content-end  ">
                                            <h5 className="fs-15 mb-0">  Action</h5>
                                        </div>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>

                    <Row className="row gy-1 mb-1" id="passwordCategories-list">
                        {loading ?
                            <div className='text-center'>
                                <Spinner color="primary" className="m-5" style={{ width: 70, height: 70 }}>
                                    Loading...
                                </Spinner>
                            </div>
                            :
                            categoryList?.data?.length !== 0 ?

                                (categoryList?.data)?.map((item, index) => (

                                    <Col lg={12} key={index} className='mt-0' >
                                        <Card className="mb-0 mt-0 border-0 border-bottom rounded-0 py-1">
                                            <CardBody className="px-2 py-0">
                                                <div className="d-lg-flex align-items-center row  px-2">
                                                    <div className="col-6 col-md-3 align-items-center  px-1 ">
                                                        <p className="mb-0 ">  {item.name}</p>
                                                    </div>
                                                    <div className={`${isExpanded?.[index] ? "d-block" : "d-flex"} col-10 col-md-8 gap-4 px-1 px-md-2 align-items-center`}>
                                                        <p className="mb-0">
                                                            {isExpanded?.[index] ? item?.description : item?.description?.length > (isMobile ? 35 : 80) ? item?.description?.slice(0, (isMobile ? 35 : 80)) + "..." : item?.description}
                                                            {item?.description?.length > (isMobile ? 35 : 80) && (
                                                                <a href="#!" key={index} onClick={() => handleToggle(index)}>
                                                                    {isExpanded?.[index] ? ' Read Less' : ' Read More'}
                                                                </a>
                                                            )}
                                                        </p>
                                                    </div>
                                                    <div className=" col-2 col-md-1 col-lg-1   d-flex  gap-0 align-items-center justify-content-end px-0">
                                                        <button type="button" className="btn  btn-sm btn-icon "
                                                            onClick={(e) => {
                                                                handleEditClick(item);
                                                            }}>
                                                            <HiOutlinePencilAlt className="fs-18 primary" />
                                                        </button>

                                                        <button type="button" className="btn btn-sm btn-icon "
                                                            onClick={(e) => {
                                                                onClickData(item); setCategoryView(item);
                                                            }}>
                                                            <HiOutlineTrash className="fs-18 secondary" />
                                                        </button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                                :
                                <div className="noresult" >
                                    <div className="text-center">
                                        <h6 className="mt-2">Sorry! No Result Found</h6>
                                        {/* <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any
        orders for you search.</p> */}
                                    </div>
                                </div>
                        }
                    </Row>


                    <Offcanvas isOpen={open} toggle={function noRefCheck() { }} direction={placement} className={`offcanvas-${placement} border-0 offcanvas-600`}     >
                        <OffcanvasHeader className="d-flex align-items-center bg-light px-3 py-2" toggle={function noRefCheck() { }}   >
                            <span className="d-flex justify-content-between" >
                                <span className="m-0 me-2">{!isEdit ? "Add Category" : "Update Category"}</span>
                                <span>
                                    <span className="fs-18 px-1" onClick={() => { setSubmit(true); validation.handleSubmit(); }}>
                                        <HiOutlineCheck className='cursor' /></span>
                                    <span className="fs-18 cursor" onClick={() => { toggleDrawer() }}>   <IoCloseOutline /> </span>
                                </span>
                            </span>
                        </OffcanvasHeader>
                        <OffcanvasBody className="p-0">
                            <SimpleBar className="h-100">
                                <div className="p-4">
                                <FormDocumentCategory  validation={validation} submit={submit} setSubmit={setSubmit} />
                                </div>
                            </SimpleBar>
                        </OffcanvasBody>
                    </Offcanvas>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default Category;